.announcement-link-gap {
    gap: 5% !important;
}

.logo-url-container {
    display: flex;
    flex-direction: row;
    gap: 5%;
    width: 65%;
    // height: 100%;
    height: 7vh;
    justify-items: center;
    align-items: center;
    overflow: hidden !important;

    img {
        height: 3vh;
        width: auto;
        object-fit: contain;
    }
}

.edit-delete-container {
    display: flex;
    flex-direction: row;
    gap: 30%;
    width: 15%;
    justify-content: flex-end;
    justify-items: center;

    img {
        cursor: pointer;
    }
}

.add-announcement-button {
    position: absolute;
    top: 11%;
    right: 5%;
    width: -moz-fit-content;
    width: fit-content;

    button {
        width: 10vw;
        height: 5vh;
        background: #D8E3FF;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }

    @media(max-width: 1025px) {
        top: 10%;

        button {
            width: 13vw;
            height: 5vh;
            background: #D8E3FF;
            border-radius: 5px;
            display: block;

        }

        @media(max-width: 769px) {
            position: relative;
            top: 0;
            right: 3%;
            z-index: 5;
            margin: 0 0 0 auto;

            button {
                width: 130px;
                height: 5vh;
                background: #D8E3FF;
                border-radius: 5px;
                display: block;

            }
        }
    }
}

.form-link-container {
    width: 100%;

    .form {
        width: 100%;
        // height: 20vh;
        display: flex;
        flex-direction: column;

        gap: 5%;
        border-radius: 0px 5px 5px 0px;
        border-left: 5px solid var(--button-right-nav-bar, #1570EF);
        background: var(--bg-inbox-selection, #EEF4FF);
        padding: 4% 3% 6% 3%;

        .input-group {

            width: 100%;
            position: relative;

            .ant-row {
                width: 100%;
                height: 100%;
            }

            margin: 0;

            input {
                width: 100%;
                height: 100%;
                padding: 2% 0;
                border: none;
                border-bottom: 0.5px solid var(--icon-focus, #344054);
                background: transparent;
                outline: none;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }
            }

            .image-label {
                position: absolute;
                top: 20%;
                right: 1%;
                cursor: pointer;
                color: #17173A;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    .form-button-container {
        margin-top: 4%;
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5%;

        button {
            width: fit-content;
            padding: 0 5%;
            height: 5vh;
            background: #D8E3FF;
            border: none;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #17173A;
            font-size: 0.9rem;
            font-weight: 400;

            @media (max-width: 1400px) {
                font-size: 0.75rem;

                @media (max-width: 769px) {
                    font-size: 0.9rem;
                }
            }
        }
    }
}
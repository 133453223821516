.review-summary {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
    border-bottom: 0.6px solid #C7CDD6;

    .review-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 7px;

        .profile-pic {
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }

        .user-details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 9px;

            .username {
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.01em;

                color: #17173A;
            }

            .contact {
                // margin-top: 9px;
                font-size: 13px;
                font-weight: normal;
                line-height: 13px;
                letter-spacing: 0.01em;
                /* Text/Timestamp */
                color: #667085;
            }
        }

        .provider {
            width: 22px;
            height: auto;
        }
    }

    .review-stats {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stat {
            text-align: center;

            .label,
            .value {
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.01em;

                color: #272C35;
            }

            .label {
                margin-top: 5px;
            }
        }
    }
}

.review-table {
    display: table;
    padding: 15px 20px;
    // border-top: 0.6px solid #C7CDD6;
    width: 100%;
    // border-spacing: 0 10px;
    border-spacing: 0 18px; // Horizontal gap: 0, Vertical gap: 18px

    .table-row {
        display: table-row;
        // margin-bottom: 18px;

        .label,
        .value {
            display: table-cell;
            vertical-align: center;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .label {
            // width: 110px;
            padding-right: 10px; // This adds a gap between the label and value
        }

        .value {
            display: table-cell;
            vertical-align: center;

            .value-details,
            input {
                cursor: pointer;
                padding: 0 10px;
                outline: none;
                background: #F0F2F4;
                border-radius: 3px;
                overflow: hidden;
                border: 0.6px solid #C7CDD6;
                width: 196px;
                height: 34px;
                display: flex;
                flex-direction: row;
                // justify-content: center;
                align-items: center;
                gap: 5px;

                img {
                    width: 14px;
                    height: 14px;
                    vertical-align: middle;
                }

            }

        }
    }
}

.attachment-summary {
    border-top: 0.6px solid #C7CDD6;
    width: 100%;
    padding: 15px 10px;

    .attachment-container {
        width: 100%;
        border-radius: 5px;
        border: 0.6px solid #C7CDD6;
        background: #FFFFFF;

        .attachment-header {
            width: 100%;
            height: 33px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: normal;
            line-height: 13px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .attachment-contents {
            border-top: 0.3px solid #C7CDD6;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
            padding: 10px 15px;

            font-size: 13px;
            font-weight: normal;
            line-height: 13px;
            letter-spacing: 0.01em;
            color: #272C35;

            .attachment-image {
                width: auto;
                height: 24px;
                border-radius: 50%;
            }

            .attachment-details {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 9px;

                .attachment-name {
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    color: #272C35;
                }

                .size {
                    // margin-top: 9px;
                    font-size: 13px;
                    font-weight: normal;
                    line-height: 13px;
                    letter-spacing: 0.01em;
                    /* Text/Timestamp */
                    color: #667085;
                }
            }

            .download {
                cursor: pointer;
                width: 24px;
                height: auto;
            }
        }
    }

    .summary {
        margin-top: 15px;
        width: 100%;
        border-radius: 5px;
        border: 0.6px solid #C7CDD6;
        background: #FFFFFF;

        .summary-header {
            width: 100%;
            height: 33px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            font-size: 13px;
            font-weight: normal;
            line-height: 13px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .summary-body {
            border-top: 0.3px solid #C7CDD6;
            width: 100%;
            padding: 10px 15px;
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0.01em;
            /* Text / Body */
            color: #101828;
        }
    }
}
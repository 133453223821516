// ===== Add Team ====
.team-setting-form-container {
    width: 476px !important;

    .team-setting-form {
        width: 100%;
        padding: 30px 40px;

        h3 {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin-bottom: 20px;
        }

        .settings-form-item {
            margin-bottom: 20px;

            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;

                color: #4E5869;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }

            .color-picker-input-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }

        .action-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #0F43BD;
                }
            }
        }

    }
}

// ===== Add user ====
.add-user-setting-table-container {
    width: 560px !important;

    .add-user-setting-table-form {
        width: 100%;
        padding: 30px 0;

        h3 {
            padding: 0 40px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin: 0 40px;
            padding-bottom: 15px;
        }

        .settings-form-item {
            margin-bottom: 20px;
            padding: 0 40px;

            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;

                color: #4E5869;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }

        }

        .action-buttons {
            margin-top: 20px;
            padding: 0 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #0F43BD;
                }
            }
        }

        .user-member-table {
            padding: 0 14px 0 40px;
            margin-right: 20px;
            min-height: 173px;
            max-height: 175px;
            overflow-y: auto;
            margin-bottom: 10px;

            .table-responsive {
                table {
                    width: 100%;
                    border-collapse: collapse;

                    thead {
                        display: none;
                    }

                    tbody {
                        tr {
                            border-bottom: 0.5px solid #C7CDD6; // Add a bottom border to each row

                            &:first-child {
                                border-top: 0.5px solid #C7CDD6;
                            }

                            td {
                                height: 42px; // Set the height of each row
                                padding: 0;
                                vertical-align: middle; // Center align the content vertically
                                text-align: left;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 14px;
                                letter-spacing: 0.01em;

                                color: #272C35;

                                &:first-child {
                                    text-align: left;
                                    width: 40%;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    img {
                                        border-radius: 50%;
                                        height: 18px;
                                        width: 18px;
                                    }
                                }

                                &:nth-last-child(2) {
                                    width: 50%;
                                }

                                // border-right: 1px solid red;

                                &:last-child {
                                    text-align: right; // Right align the last column (delete icon)
                                }
                            }

                            img {
                                cursor: pointer;
                                // transition: transform 0.3s ease-in-out;

                                // &:hover {
                                //     transform: scale(1.1); // Scale up the delete icon on hover
                                // }
                            }
                        }
                    }
                }
            }


            &::-webkit-scrollbar-thumb {
                background: #A3A3A3 !important;
                // height: 10px !important;
                border-radius: 10px;
                cursor: pointer;
                min-height: 30px;
                /* Set your desired fixed height */
                max-height: 30px;
                /* Ensure it doesn't exceed the desired fixed height */
                background-clip: padding-box;
                /* Adjusts the background to stay within the thumb bounds */
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }
        }

    }
}

// ===== Add Channel ====
.add-channel-setting-table-container {
    width: 560px !important;

    .add-channel-setting-table-form {
        width: 100%;
        padding: 30px 0;

        h3 {
            padding: 0 40px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin-bottom: 10px;
            margin: 0 40px;
            padding-bottom: 15px;
        }

        .settings-form-item {
            margin-bottom: 20px;
            padding: 0 40px;

            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;

                color: #4E5869;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }

        }

        .action-buttons {
            margin-top: 20px;
            padding: 0 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #0F43BD;
                }
            }
        }

        .channel-table {
            padding: 0 14px 0 40px;
            margin-right: 20px;
            min-height: 173px;
            max-height: 175px;
            overflow-y: auto;
            margin-bottom: 10px;

            .table-responsive {
                table {
                    width: 100%;
                    border-collapse: collapse;

                    thead {
                        display: none;
                    }

                    tbody {
                        tr {
                            border-bottom: 0.5px solid #C7CDD6; // Add a bottom border to each row

                            &:first-child {
                                border-top: 0.5px solid #C7CDD6;
                            }

                            td {
                                height: 42px; // Set the height of each row
                                padding: 0;
                                vertical-align: middle; // Center align the content vertically
                                text-align: left;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 14px;
                                letter-spacing: 0.01em;

                                color: #272C35;

                                &:first-child {
                                    text-align: left;
                                    width: 60%;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    img {
                                        height: 18px;
                                        width: 18px;
                                    }
                                }

                                // &:nth-last-child(2) {
                                //     width: 50%;
                                // }

                                // border-right: 1px solid red;

                                &:last-child {
                                    text-align: right; // Right align the last column (delete icon)
                                }
                            }

                            img {
                                cursor: pointer;
                                // transition: transform 0.3s ease-in-out;

                                // &:hover {
                                //     transform: scale(1.1); // Scale up the delete icon on hover
                                // }
                            }
                        }
                    }
                }
            }


            &::-webkit-scrollbar-thumb {
                background: #A3A3A3 !important;
                // height: 10px !important;
                border-radius: 10px;
                cursor: pointer;
                min-height: 30px;
                /* Set your desired fixed height */
                max-height: 30px;
                /* Ensure it doesn't exceed the desired fixed height */
                background-clip: padding-box;
                /* Adjusts the background to stay within the thumb bounds */
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }
        }

    }
}

// ====== Team Member ======
.team-member-table {
    .table-responsive {
        table {
            width: 100%;
            border-collapse: collapse;

            thead {
                display: none;
            }

            tbody {
                tr {
                    height: 56px; // Set the height of each row
                    border-bottom: 0.5px solid #C7CDD6; // Add a bottom border to each row

                    td {
                        padding: 0;
                        vertical-align: middle; // Center align the content vertically
                        text-align: center;
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: normal;
                        letter-spacing: 0.01em;

                        @media(max-width: 576px) {
                            font-size: 13px;
                            line-height: 13px;
                        }

                        color: #272C35;

                        &:first-child {
                            text-align: left;
                            width: 50%;
                        }

                        &:nth-last-child(2) {
                            width: 25%;

                            @media(max-width: 576px) {
                                width: 30%;
                            }
                        }

                        // border-right: 1px solid red;

                        &:last-child {
                            padding-right: 20px;

                            @media(max-width: 576px) {
                                padding-right: 0;
                            }

                            text-align: right; // Right align the last column (delete icon)
                        }
                    }

                    img {
                        cursor: pointer;
                        transition: transform 0.3s ease-in-out;

                        &:hover {
                            transform: scale(1.1); // Scale up the delete icon on hover
                        }
                    }
                }
            }
        }
    }
}

// ======= Channel ========
.channel-list-table {
    .table-responsive {
        width: 100%;
        overflow-x: auto;
    }

    .table {
        width: 100%;
        border-collapse: separate;
        // margin-top: 10px;
        border-spacing: 0 20px; // Adds spacing between rows (acts like `margin-bottom`)

        thead {
            th {
                padding: 0;
            }

            // display: none;
            .table-header-row-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 28px;
                height: 36px;
                border-bottom: 0.5px solid #C7CDD6;

                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.01em;

                color: #4E5869;

                @media(max-width: 576px) {
                    padding: 0 18px;
                }

                .channel-header-title-container {
                    min-width: 25%;
                    max-width: 25%;
                }
            }
        }

        .inbox-channel {
            @media(max-width: 576px) {
                display: none;
            }
        }

        tbody {
            tr {
                td {
                    padding: 0;
                    border: none; // Remove any default borders from cells
                    cursor: pointer;

                    .table-row-content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 30px;

                        @media(max-width: 1200px) {
                            padding: 0 15px;

                            @media (max-width: 576px) {}
                        }

                        border: 0.5px solid #C7CDD6;
                        border-radius: 6px;
                        height: 86px;

                        .channel-title-container {
                            min-width: 25%;
                            max-width: 25%;
                            display: flex;
                            gap: 12px;
                            align-items: center;

                            .channel-icon {
                                // border-radius: 50%;
                                height: 30px;
                                width: 30px;
                                // border: 0.2px solid #AAB2C0;
                                display: flex;
                                justify-content: center;
                                overflow: hidden;

                                img {
                                    height: auto;
                                    width: 100%;
                                }
                            }

                            .channel-title {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                .channel-provider {
                                    font-size: 12px;
                                    color: #4E5869;
                                }
                            }
                        }

                        font-size: 14px;

                        @media(max-width: 1024px) {
                            font-size: 13px;

                            @media (max-width: 576px) {}
                        }

                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }
                }
            }
        }
    }

    .delete-icon {
        width: 16px;
        height: auto;
    }
}

// ====== Team Settings ======
.dialog {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    /* Full width */
    width: 100vw;
    /* Full height */
    height: 100vh;
    overflow: hidden;
    background-color: transparent;

    .dialog-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .success-dialog,
        .error-dialog {
            height: 60px;
            min-width: 212px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            gap: 10px;
            bottom: 6%;
            border-radius: 6px;
            background: #07225F;
            // background: "#D8E3FF",
            // borderLeft: "4px solid #1570EF",
            cursor: pointer;
            position: relative;
            padding: 0 20px;

            img {
                width: 20px;
                height: auto;
            }

            .success-message,
            .error-message {
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0em;
            }

            .success-message,
            .error-message {
                color: #FFFFFF;
            }

        }

        // .error-dialog {
        //     background: #FFE9D9;
        //     .error-message {
        //         color: #BC4C2E;
        //     }
        // }
    }
}
.review-summary {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
    border-bottom: 0.6px solid #C7CDD6;

    .review-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 7px;

        .profile-pic {
            width: 35px;
            height: 35px;
            border-radius: 50%;
        }

        .user-details {
            flex-grow: 1;

            .username {
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.01em;

                color: #17173A;
            }

            .stars {
                margin-top: 9px;
                display: flex;
                gap: 4px;

                img {
                    height: 11px;
                    width: auto;
                }
            }
        }

        .provider {
            width: 22px;
            height: auto;
        }
    }

    .review-stats {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stat {
            text-align: center;

            .label,
            .value {
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.01em;

                color: #272C35;
            }

            .label {
                margin-top: 5px;
            }
        }
    }
}

.review-table {
    display: table;
    padding: 20px;
    // border-top: 0.6px solid #C7CDD6;
    width: 100%;
    // border-spacing: 0 10px;
    border-spacing: 0 18px; // Horizontal gap: 0, Vertical gap: 18px

    .table-row {
        display: table-row;
        // margin-bottom: 18px;

        .label,
        .value {
            display: table-cell;
            vertical-align: center;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .label {
            // width: 110px;
            padding-right: 10px; // This adds a gap between the label and value
        }

        .value {
            display: table-cell;
            vertical-align: center;

            .value-details {
                padding: 0 10px;
                background: #F0F2F4;
                border-radius: 3px;
                border: 0.6px solid #C7CDD6;
                width: 196px;
                height: 34px;
                display: flex;
                flex-direction: row;
                // justify-content: center;
                align-items: center;
                gap: 5px;

                img {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }
        }
    }
}
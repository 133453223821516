.wall-conversations-container {
    // margin: 5px auto;
    // display: flex;
    // flex-wrap: column wrap;
    padding: 0 20px;

    @media(max-width: 769px) {
        padding: 0 6px 0 0;
    }

    .wall-video {
        width: 502px !important;

        @media(max-width: 769px) {
            width: 95% !important;
            margin: 10px 0 10px 10px !important;
            padding: 0;
        }
    }

    .wall-section-container {
        // min-width: 270px;
        // max-width: 510px;
        // height: auto;
        // min-height: 220px;
        // max-height: 278px;
        // padding: 10px 8px;
        // float: right;
        background: #FFFFFF;
        box-shadow: 0.5px 0.5px 10px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-family: 'Inter';
        font-style: normal;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: #17173A;
        // width: 28%;

        @media(max-width: 1440px) {
            // width: 31%;
        }

        // margin: 1% 0;

        @media(max-width: 769px) {
            // width: 95%;
            margin: 10px 0 10px 9px !important;
            padding: 0;
        }

        // margin: 10px 20px 10px 38px;

        position: relative;

        .wall {
            // position: relative;
            width: 100%;
            // border-bottom: 1.5px solid #D3E0FF;
            // height: 61px;
            // display: flex;
            // flex-direction: row;
            // justify-content: space-between;
            // align-items: flex-start;
            padding: 4% 3% 0 3%;
            // padding: 15px 25px 0 15px;

            .wall-section-header {
                display: flex;
                flex-direction: row;
                align-items: center;

                .user-avatar {
                    // height: 1.8rem;
                    // width: 2rem;
                    height: 32px;
                    // width: auto;
                    width: auto;
                    border-radius: 50%;
                    // padding: 1%;
                }

                .user-name-avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 28px;
                    width: 32px;
                    border-radius: 50%;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                    background: #14B0BF;
                }

                .user-name {
                    // border-radius: 50%;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 12px;
                    /* identical to box height, or 100% */

                    letter-spacing: 0.01em;

                    /* Text / Title */

                    color: #17173A;
                }

                p {
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 12px;
                    /* identical to box height, or 100% */

                    letter-spacing: 0.01em;

                    /* Text / Title */

                    color: #17173A;

                    a {
                        font-weight: normal;
                        font-size: 10px;
                        line-height: 12px;
                        // color: #667085;
                        color: #17173A;

                    }

                    img {
                        width: 10px;
                        height: 10px;
                        // margin: 0 5px;
                    }
                }
            }

            .social-link {
                position: absolute;
                top: 15px;
                right: 10px;
            }

            .wall-section-body {
                width: 100%;
                display: flex;
                flex-direction: column;

                @media(max-width: 769px) {
                    flex-direction: column;
                }

                justify-content: space-between;
                align-items: flex-start;

                .description,
                .video-description {
                    width: 100%;
                    font-weight: 400;
                    font-size: 0.8rem;
                    // font-size: 11px;
                    line-height: 1.2rem;
                    color: #101828;
                    text-align: justify !important;
                    padding: 1% 2% 3% 0;
                    // margin-bottom: 6%;

                    span {
                        cursor: pointer;
                        font-size: 0.7rem;
                        font-weight: 600;
                        font-style: italic;
                    }
                }

                .video-description {
                    margin-bottom: 0;
                }

                .video-container {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    border-radius: 8px;
                    margin-bottom: 2rem;
                    padding: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        // height: 200px;
                        // margin-bottom: 35px;
                        // color: #56e756;
                    }
                }
            }

            .wall-section-footer {
                // position: absolute;
                // bottom: 8%;
                // right: 10px;
                // width: 94%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 2%;

                p {
                    font-weight: 400;
                    font-size: 0.6rem;
                    // line-height: 10px;                
                    letter-spacing: 0.01em;
                    color: #667085;
                }

                .verify-delete {
                    // position: absolute;
                    // right: 0;
                    display: flex;
                    align-items: flex-start;

                    p {
                        font-weight: 400;
                        font-size: 10px;
                        // line-height: 10px;
                        color: #667085;
                    }

                    .delete-icon {
                        display: block;
                        margin-left: 8px;
                        height: 10px;
                        cursor: pointer;
                    }
                }
            }
        }

        // .scrolling{
        //     max-height: 460px;
        //     display: flex;
        //     flex-direction: column-reverse;
        //     // overflow-y: auto;
        // }

    }
}
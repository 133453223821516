/* Modal zoom-out and fade-in animation */
@keyframes zoomInModal {
    from {
        opacity: 0;
        transform: scale(0); // Start zoomed in (110%)
    }

    to {
        opacity: 1;
        transform: scale(1); // Normal size
    }
}

.custom-modal-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1000;
    opacity: 1;
    /* Start with invisible */
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.2);
    // animation: zoomInModal 0.5s ease-in-out forwards;

    .custom-continue-modal {
        // background: rgba(0, 0, 0, 0.3);
        animation: zoomInModal 0.5s ease-in-out forwards;
        text-align: left;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 30px;
        padding-bottom: 5.5%;

        .modal-container {
            overflow: hidden;
            border-radius: 10px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            width: 600px;
            height: 148px;
            padding: 20px 15px;

            @media(max-width: 576px) {
                width: 95%;
                height: fit-content;
            }

            .modal-body {
                height: 100%;
                width: 100%;
                display: flex;
                gap: 10px;

                .left-image {
                    flex-grow: 1;
                    width: 40px;
                    border-radius: 50%;

                    img {
                        width: 40px;
                        height: auto;

                    }
                }

                .right-body {
                    flex-grow: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0em;

                        color: #272C35;
                    }

                    .description {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }

                    .button-container {
                        margin-top: 15px;
                        display: flex;
                        gap: 20px;

                        .continue {
                            width: fit-content;
                            background: transparent;
                            outline: none;
                            border: none;
                            box-shadow: none;
                            width: fit-content;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0em;

                            color: #F79009;
                            transition: all 0.3s ease-in-out;
                            padding: 0;

                            &:hover {
                                opacity: 0.8;
                            }
                        }

                        .next,
                        .back,
                        .finish {
                            background: transparent;
                            outline: none;
                            border: none;
                            box-shadow: none;
                            height: fit-content;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0em;

                            color: #272C35;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                color: #0F43BD;
                            }
                        }
                    }
                }

            }
        }
    }

    .custom-modal {
        // background: rgba(0, 0, 0, 0.3);
        animation: zoomInModal 0.5s ease-in-out forwards;
        text-align: left;
        width: 100%;
        // max-width: calc(100vw - 32px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-container {
            overflow: hidden;
            border-radius: 10px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            width: 530px;

            @media(max-width: 576px) {
                width: 90%;
            }

            .container-div {
                height: 360px;
                width: 450px;
                padding: 30px 0;

                @media(max-width: 576px) {
                    width: 100%;
                    height: fit-content;
                    padding: 20px 15px;
                }

                margin: auto;
                overflow: hidden;

                // margin-top: 10px;
                .header {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0em;

                    color: #272C35;
                    padding-bottom: 14px;

                    width: 100%;
                    height: 28px;
                    border-bottom: 0.6px solid #AAB2C0;
                }

                .sub-title {
                    margin-top: 20px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;

                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0em;

                    color: #272C35;

                    .number {
                        height: 100%;
                        width: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        /* Bento/Fill */
                        background: #E1ECFF;
                    }
                }

                .description {
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    color: #272C35;
                }

                .input-container {
                    width: 100% !important;
                    height: 44px;
                    margin: 20px 0;

                    input {
                        width: 100% !important;
                        height: 100%;
                        padding: 15px;
                        outline: none;
                        background: #FFFFFF;
                        font-size: 12px;
                        border: 0.5px solid #AAB2C0;
                        border-radius: 4px;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 14px;
                        letter-spacing: 0em;
                        color: #000000;

                        &::placeholder {
                            color: #98A2B3;
                        }

                        transition: all 0.3s ease-in-out;

                        // focus input
                        &:focus {
                            outline: none;
                            border: 0.5px solid #1570EF;
                            box-shadow: 0px 0px 0px 2px rgba(21, 112, 239, 0.2);
                        }
                    }

                }

                .setting-up-account-table {
                    margin: 20px 0;
                    width: 100%;
                    // margin-right: 20px;
                    // min-height: 32px;
                    // max-height: 175px;
                    // overflow-y: auto;
                    // margin-bottom: 10px;

                    .table-responsive {
                        width: 100%;

                        table {
                            width: 100%;
                            border-collapse: collapse;

                            thead {
                                display: none;
                            }

                            tbody {
                                tr {
                                    border-bottom: 0.5px solid #C7CDD6; // Add a bottom border to each row

                                    &:first-child {
                                        border-top: 0.5px solid #C7CDD6;
                                    }

                                    td {
                                        height: 42px; // Set the height of each row
                                        padding: 0;
                                        vertical-align: middle; // Center align the content vertically
                                        text-align: left;
                                        font-size: 14px;
                                        font-weight: normal;
                                        line-height: 14px;
                                        letter-spacing: 0.01em;

                                        color: #272C35;

                                        &:first-child {
                                            text-align: left;
                                            // width: 60%;
                                            display: flex;
                                            gap: 10px;
                                            align-items: center;
                                        }

                                        &:last-child {
                                            text-align: right; // Right align the last column (delete icon)
                                        }
                                    }
                                }
                            }
                        }
                    }


                    &::-webkit-scrollbar-thumb {
                        background: #A3A3A3 !important;
                        // height: 10px !important;
                        border-radius: 10px;
                        cursor: pointer;
                        min-height: 30px;
                        /* Set your desired fixed height */
                        max-height: 30px;
                        /* Ensure it doesn't exceed the desired fixed height */
                        background-clip: padding-box;
                        /* Adjusts the background to stay within the thumb bounds */
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px #fff;
                        background-color: #fff !important;
                        margin: 0 !important;
                        // background-color: transparent ;
                        // border-radius: 10px;
                    }
                }

                .button-container {
                    display: flex;
                    justify-content: flex-end;
                    gap: 20px;

                    .next,
                    .back,
                    .finish {
                        background: transparent;
                        outline: none;
                        border: none;
                        box-shadow: none;
                        height: fit-content;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0em;

                        color: #272C35;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            color: #0F43BD;
                        }
                    }
                }


            }
        }
    }

}
.view-details-tab-header {
    position: sticky;
    top: 0;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: #FFFFFF;
    border-bottom: 0.5px solid var(--Border-Normal-Secondary, #AAB2C0);

    .tab {
        padding: 0 2px;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 1.714 */


        color: #000000;
        transition: all 0.3s ease-in-out;
        // border-bottom: 2px solid transparent;

        &:hover {
            // border-bottom: 2px solid #0F43BD;
            color: #0F43BD;
        }
    }

    .tab-active {
        // border-bottom: 2px solid #0F43BD;
        color: #0F43BD;
    }

}

.view-keywords-collaboration-container {
    padding: 0 20px;
    padding-bottom: 10px;

    .keywords-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .keywords-title {
            color: var(--Content-Secondary, #272C35);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 1.714 */


        }

        .keywords-textarea {
            resize: none;
            outline: none;
            padding: 20px 6px 20px 10px;
            background: var(--Background-White, #FFFFFF);
            border: 0.5px solid var(--Border-Normal, #C7CDD6);
            border-radius: 3px;
            max-height: 98px;
            overflow-y: scroll;
        }
    }

    .collaborator-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
            color: var(--Content-Secondary, #272C35);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            /* 1 */
            letter-spacing: 0.14px;

        }

        .collaborator-body {
            display: flex;
            flex-wrap: wrap;

            img {
                margin-right: -5px;
                border-radius: 50%;
                height: auto;
                width: auto;
            }
        }
    }
}

.view-details-table {
    display: table;
    padding: 0 20px;
    // border-top: 0.6px solid #C7CDD6;
    width: 100%;
    // border-spacing: 0 10px;
    border-spacing: 0 30px; // Horizontal gap: 0, Vertical gap: 18px

    .table-row {
        display: table-row;
        // margin-bottom: 18px;

        .label,
        .value {
            display: table-cell;
            vertical-align: center;
            color: var(--Content-Secondary, #272C35);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 1.538 */
        }

        .label {
            // width: 110px;
            padding-right: 10px; // This adds a gap between the label and value
        }

        .value {
            display: table-cell;
            vertical-align: center;

            .value-details,
            .input-value-details {
                padding: 0 10px;
                white-space: nowrap;
                /* Prevents the text from wrapping */
                overflow: hidden;
                /* Hides overflowing content */
                text-overflow: ellipsis;
                /* Adds ellipsis (...) for overflowing text */
                display: inline-block;
                background: var(--Background-Disabled, #F0F2F4);
                border: 0.6px solid var(--Border-Normal-Secondary, #AAB2C0);
                border-radius: 3px;

                width: 195px;
                height: 36px;
                display: flex;
                flex-direction: row;
                // justify-content: center;
                align-items: center;
                gap: 5px;

                img {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }

            .input-value-details {
                background: #FFFFFF;

                input {
                    outline: none;
                    border: none;
                    padding: 0;
                    background: transparent;
                }
            }

        }
    }
}
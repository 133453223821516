@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";

body {
  margin: 0;
  font-family: 'Inter';
  font-size: 100%;
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body:hover {
  -webkit-mask-position: left top !important;
} */

/* @font-face {
  font-family: 'Graphik';
  src: local('Graphik'),
    url('../src/assets/fonts/Graphik-Regular.ttf') format('truetype'),
    url('../src/assets/fonts/Graphik-Regular.eot') format('embedded-opentype'),
    url('../src/assets/fonts/Graphik-Regular.woff2') format('woff2'),
    url('../src/assets/fonts/Graphik-Regular.woff') format('woff')
} */

/* @font-face {
  font-family: 'Graphik';
  src: local("Graphik"),
    /* url('../src/assets/fonts/Graphik-Black.woff') format('woff'),
    url('../src/assets/fonts/Graphik-Light.woff') format('woff'),
    url('../src/assets/fonts/Graphik-Medium.woff') format('woff'),
    url('../src/assets/fonts/Graphik-Semibold.woff') format('woff'),
    url('../src/assets/fonts/Graphik-Bold.woff') format('woff'),
    url('../src/assets/fonts/Graphik-Thin.woff') format('woff'),
    url('../src/assets/fonts/Graphik-Super.woff') format('wooff'), *
    url('../src/assets/fonts/Graphik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal
} */
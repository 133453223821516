.ant-switch-small {
    background: #ABB9CC !important;

    // width: 2vw !important;
    // height: 2.5vh !important;
    // line-height: 0.25rem;

    .ant-switch-handle:before {
        left: 6%;
        top: 1.85%;
        // width: 0.85vw !important;
        width: 12px !important;
        height: 10.5px !important;
        margin: auto 0;
        // height: 1.7vh !important;
    }
}

.ant-switch-checked {
    background: #1890ff !important;
}

.mobile-content-buttons,
.mobile-content-navbar {
    display: none;

    @media(max-width: 576px) {
        margin: 10px 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .file-view-back-path {
        // font-family: 'Inter';
        // font-style: normal;
        // cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // flex-direction: row;
        // gap: 5%;
        // gap: 5px;

        .content-path-back {
            height: 20px;
            width: auto;
        }

        // .gallery-workspace {
        //     height: 36px;
        //     width: auto;
        // }
    }

    .content-btn {
        cursor: pointer;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 0.875rem;
        padding: 10px 25px;

        // @media(max-width: 1600px) {
        //     font-size: 0.75rem;
        //     line-height: 0.75rem;
        // }

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5%;

        p {
            margin-right: 5px;
        }

        // letter-spacing: 0.01em;
        background: #D8E3FF;
        white-space: nowrap;
        color: #17173A;
        border: 1px solid transparent;
        transition: all 0.5s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            background: #EEF4FF;
            /* Button / Active Button Outline */

            border: 1px solid #175CD3;
            // border-radius: 13px;
        }

        @media (max-width: 769px) {
            // height: 5vh;
            // padding: 2% 5%;
            border-radius: 4px;
            font-weight: 600;
            font-size: 0.650rem;
            line-height: 0.650rem;
            letter-spacing: 0.01em;

            color: #000000;
        }
    }

    .content-select {
        height: 30px;
        // box-sizing: border-box;
    }

    .ant-select-selector {
        opacity: 1;
        border-radius: 5px;
        background: transparent;
        border: 0.4px solid #ABB9CC;
    }

    .create-folder-btn {
        cursor: pointer;
        padding: 0 15px;
        width: fit-content;
        height: 36px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.01em;
        color: #17173A // @media (max-width: 1025px) {
        //     width: fit-content;
        //     color: #000000;
        // }
    }
}

.mobile-content-navbar {
    background: #FFFFFF;
    margin: 7px 0;
    padding: 0 5px;
    position: sticky;
    top: 0;
    z-index: 99;
}

.gallery-section {
    height: 100%;
    width: 96.9%;
    background: #F4F3FF;
    // background: red;
    // background: #EEF4FF;
    // background: #F5FAFF;
    border-radius: 5px 5px 0px 0px;
    // min-height: 60vh;
    display: flex;
    flex-direction: column;
    // padding: 0.5% 0 0 0;
    // padding: 10px 0 0 0;
    // padding: 10px 0 0 0 !important;

    padding: 0;
    position: relative;
    margin-top: 0.945%;
    // margin-top: 0.75rem;

    @media(max-width: 1024px) {
        width: 100%;
    }

    @media(max-width: 769px) {
        width: 100%;
        margin-top: 0;
        border-radius: 0;
        padding-top: 10px;
        padding: 0 10px;

        @media(max-width: 576px) {
            padding: 40px 20px 20px 20px;
        }
    }

    .content-section {
        background: #F4F3FF;
        // background: #EEF4FF;
        // background: #F5FAFF;
        border-radius: 5px 5px 0px 0px;
        // min-height: 60vh;
        display: flex;
        flex-direction: column;
        // padding: 0.5% 0 0 0;
        // padding: 10px 0 0 0;
        // padding: 10px 0 0 0 !important;

        // padding: 1% 0;
        position: relative;
        // margin-top: 0.945%;
        // margin-top: 0.75rem;
        margin-top: 15px;

        @media(max-width: 769px) {
            margin-top: 0;
        }

        .content-search-section {
            // border: 1px solid red;
            display: flex;
            flex-direction: row;
            // justify-content: space-around;
            justify-content: flex-end;
            align-items: center;
            // gap: 12%;
            width: 1100px;
            gap: 104px;
            margin: 0 auto;
            padding: 0;

            @media(max-width: 1400px) {
                width: 1000px;
                gap: 124px;

                @media(max-width: 1200px) {
                    width: 880px;

                    @media(max-width: 992px) {
                        // padding: 2%;
                        width: 700px;
                        gap: 32px;

                        @media(max-width: 769px) {
                            margin-top: 30px;
                            width: 526px;

                            @media(max-width: 576px) {
                                display: none;
                            }
                        }
                    }
                }
            }

            // @media(max-width: 769px) {
            //     flex-direction: column;
            // }

            .search-wrap {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                // gap: 5%;
                // width: 500px;
                // width: 50%;

                // @media(max-width: 769px) {
                //     width: 100%;
                // }

                // @media(max-width: 1025px) {
                //     width: 90%;

                //     @media(max-width: 699px) {
                //         display: none;
                //     }
                // }

                .search-container {
                    width: 553px;
                    height: 42px;

                    @media(max-width: 1400px) {
                        width: 470px;

                        @media(max-width: 1200px) {
                            height: 36px;
                            width: 354px;

                            @media(max-width: 1024px) {
                                height: 42px;
                                width: 364px;

                                @media(max-width: 992px) {
                                    height: 42px;
                                    width: 354px;
                                }
                            }
                        }
                    }

                    position: relative;
                    z-index: 1;

                    .search-icon {
                        position: absolute;
                        left: 10px;
                        top: 27%;
                        height: auto;
                        // width: 10px;
                        width: 19px;
                        height: auto;
                        cursor: pointer;
                    }

                    input {
                        width: 100%;
                        // height: 5vh;
                        // height: 44px;
                        height: 100%;
                        background: #FFFFFF;
                        border: none;
                        // border: 1.3px solid #D3E0FF;
                        border-radius: 5px;
                        box-sizing: border-box;
                        // padding: 0 0 0 4.5%;
                        padding: 0 0 0 35px;
                        outline: none;


                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 0.875rem;
                        letter-spacing: 0.01em;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;
                            line-height: 0.75rem;

                            @media(max-width: 1200px) {}
                        }

                        color: #98A2B3;
                    }

                    input::placeholder {
                        color: #98A2B3;
                    }
                }


            }

            .content-buttons {
                // width: fit-content;

                @media(max-width: 576px) {
                    // margin-top: 5%;
                    width: 100%;
                    // display: flex;
                    // flex-direction: row;
                    // justify-content: space-around;
                    // align-items: center;
                }

                .content-button-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 15px;
                }
            }

            .bulk-delete-icon {
                height: auto;
                width: auto;
                cursor: pointer;

                @media(max-width: 769px) {
                    height: auto;
                }

            }

            .content-btn {
                cursor: pointer;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 0.875rem;
                padding: 10px 25px;

                // @media(max-width: 1600px) {
                //     font-size: 0.75rem;
                //     line-height: 0.75rem;
                // }

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5%;

                p {
                    margin-right: 5px;
                }

                // letter-spacing: 0.01em;
                background: #D8E3FF;
                white-space: nowrap;
                color: #17173A;
                border: 1px solid transparent;
                transition: all 0.5s ease-in-out;

                &:hover,
                &:focus,
                &:active {
                    background: #EEF4FF;
                    /* Button / Active Button Outline */

                    border: 1px solid #175CD3;
                    // border-radius: 13px;
                }

                @media (max-width: 769px) {
                    // height: 5vh;
                    // padding: 2% 5%;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 0.650rem;
                    line-height: 0.650rem;
                    letter-spacing: 0.01em;

                    color: #000000;
                }
            }

            // .secondary,
            .create-folder-btn {
                // height: 100%;
                cursor: pointer;
                padding: 0 25px;
                // font-size: 0.875rem;
                width: fit-content;
                height: 42px;

                @media(max-width: 1400px) {
                    @media(max-width: 1200px) {
                        height: 36px;

                        @media(max-width: 1024px) {
                            height: 42px;

                            @media(max-width: 769px) {
                                padding: 0 30px;
                            }
                        }
                    }
                }

                // @media (max-width: 1025px) {
                //     width: fit-content;
                //     color: #000000;
                // }
            }

            // .create-folder-btn {
            //     padding: 2% 20%;

            //     @media(max-width: 769px) {
            //         padding: 2% 5%;
            //     }
            // }
        }

        .mobile-search-container {
            display: none;

            @media(max-width: 576px) {
                width: 100%;
                display: block;
            }

            .file-back-path {
                margin-top: 10px;
                // font-family: 'Inter';
                // font-style: normal;
                // cursor: pointer;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // flex-direction: row;
                // gap: 5%;
                // gap: 5px;

                .content-path-back {
                    height: 20px;
                    width: auto;
                }

                .content-path-icon {
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    height: 1.5vh;
                    width: auto;
                }

                .content-path-text {

                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 0.875rem;

                    @media(max-width: 1600px) {
                        font-size: 0.75rem;
                        line-height: 0.75rem;
                    }

                    letter-spacing: 0.01em;
                    color: #000000;
                    cursor: pointer;
                }
            }
        }

        .full-file-name {
            text-align: start;
            margin: 10px 0;
        }
    }

    .content-wall,
    .mobile-content-wall {
        // height: 80vh;
        // padding-top: 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // justify-content: center;
        align-items: center;
        // width: 100%;
        // border: 1px solid red;
        // height: 70vh;

        height: fit-content;
        // height: 60vh;
        overflow-x: hidden;
        width: 1100px;
        margin: 0 auto;

        @media(max-width: 1400px) {
            width: 1000px;
            // width: 950px;

            @media(max-width: 1200px) {
                width: 880px;

                @media(max-width: 992px) {
                    width: 700px;

                    @media(max-width: 769px) {
                        width: 526px;

                        @media(max-width: 576px) {
                            padding-top: 0;
                            width: 100%;
                            // padding: 0 20px;
                        }
                    }
                }
            }
        }

        .content-path,
        .preview-content-path {
            // margin: 5px 0;
            margin: 40px 0;
            font-family: 'Inter';
            font-style: normal;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            // gap: 5%;
            gap: 5px;

            @media(max-width: 576px) {
                display: none;
            }

            .content-path-back {
                height: 20px;
                width: 50px;
                cursor: pointer;
            }

            .content-path-icon {
                font-size: 0.875rem;
                line-height: 0.875rem;
                height: 1.5vh;
                width: auto;
            }

            .content-path-text {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 0.875rem;

                @media(max-width: 1600px) {
                    font-size: 0.75rem;
                    line-height: 0.75rem;
                }

                letter-spacing: 0.01em;
                color: #000000;
                cursor: pointer;
            }
        }

        .preview-content-path {
            margin: 10px 0;
        }

        .form-check-input[type=checkbox] {
            cursor: pointer;
        }

        .table-responsive {
            width: 100%;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;

            @media(max-width: 1025px) {
                font-size: 12px;

                @media(max-width: 769px) {
                    // width: 580px;
                    // width: 210%;
                    font-size: 14px;
                }
            }

        }

        .sticky-top {
            position: sticky;
            top: 0;
            z-index: 99;
        }

        .infinity-scroll {

            // height: 70vh !important;
            overflow: scroll;

            &:hover {
                ::-webkit-scrollbar-thumb {
                    // -webkit-transition: background-color 0.5s ease-in-out !important;
                    // -moz-transition: background-color 0.5s ease-in-out !important;
                    // -o-transition: background-color 0.5s ease-in-out !important;
                    // transition: background-color 0.5s ease-in-out !important;
                    background: #ddd !important;
                    height: 10px !important;
                }
            }

            ::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                cursor: pointer;
            }

            ::-webkit-scrollbar-track {
                height: 5px !important;
                box-shadow: inset 0 0 5px transparent;
                background-color: transparent !important;
                margin: 0 !important;
            }

            ::-webkit-scrollbar-thumb {
                background-color: transparent !important;
                border-radius: 10px !important;
            }

        }

        .folder-table {
            // background-color: #FFFFFF;
            background: transparent;

            .folder-thead {
                // position: sticky;
                // top: 0;

                @media(max-width: 769px) {
                    top: -3px;
                }

                background: #F4F3FF;
                // z-index: 1;

                tr {
                    color: #667085;


                    th {
                        font-weight: normal;
                        // padding: 1% 0 3% 0;
                        padding: 1px 0 15px 0;
                        text-align: center;
                        background-color: transparent;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 0.875rem;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;
                            line-height: 0.75rem;

                            @media(max-width: 1200px) {}
                        }

                        letter-spacing: 0.01em;

                    }

                    th:first-child {
                        // padding: 1% 0 3% 2%;
                        padding: 10px 0 15px 25px;
                        text-align: start;

                        @media(max-width: 576px) {
                            padding: 10px 0 15px 15px;
                            // width: 45% !important;
                        }
                    }

                    th:last-child {
                        text-align: end;
                        padding: 10px 25px 15px 0;

                        @media(max-width:576px) {
                            padding: 10px 15px 15px 0;
                            // width: auto !important;
                        }
                    }
                }

                tr:first-child {
                    text-align: start;
                    // border-bottom: 1px solid #D3E0FF;
                }

                tr:last-child {
                    border-bottom: 1px solid #D3E0FF;
                    position: absolute;
                    bottom: 20%;
                    width: 100%;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #D3E0FF;
                    // height: 56px;

                    td {
                        height: 56px;
                        // padding: 2% 0;
                        padding: 0 0;
                        text-align: center;

                        .table-image-container {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            .content-img {
                                height: 42px;
                                width: auto;
                            }

                            .title-container {
                                height: 34px;
                                margin-top: 5px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: flex-start;
                                // gap: 5px;

                                .content-name {
                                    font-size: 14px;
                                    font-weight: normal;
                                    line-height: 12px;
                                    letter-spacing: 0.01em;
                                    color: #101828;
                                }

                                .content-date {
                                    font-size: 12px;
                                    font-weight: normal;
                                    line-height: 12px;
                                    letter-spacing: 0.01em;
                                    color: #475467;
                                }
                            }

                        }

                        font-weight: 400;
                        font-size: 0.75rem;
                        line-height: 0.75rem;

                        @media(max-width: 769px) {
                            width: 25% !important;
                        }

                        letter-spacing: 0.01em;

                        // .table-switch {
                        //     width: 30%;
                        //     height: auto;
                        // }
                    }

                    td:first-child {
                        // padding: 2%;
                        padding: 0 25px;
                        text-align: start;

                        @media(max-width: 769px) {
                            padding: 0 25px;
                            width: 45% !important;
                        }

                        @media(max-width: 576px) {
                            width: 100% !important;
                            text-align: start;
                            padding: 0 15px;
                        }
                    }

                    td:last-child {
                        text-align: end;
                        padding: 0 25px;

                        @media(max-width: 769px) {
                            width: 5% !important;
                        }

                        @media(max-width:576px) {
                            padding: 0 15px;
                        }
                    }
                }
            }
        }

        .content-table {
            // background-color: #FFFFFF;
            background: transparent;

            .content-thead {
                // position: sticky;
                // top: 0;

                // @media(max-width: 769px) {
                //     top: -3px;
                // }

                background: #F4F3FF;
                // z-index: 1;

                tr {
                    color: #667085;


                    th {
                        font-weight: normal;
                        // padding: 1% 0 3% 0;
                        padding: 1px 0 15px 0;
                        text-align: center;
                        background-color: transparent;

                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 0.875rem;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;
                            line-height: 0.75rem;

                            @media(max-width: 1200px) {}
                        }

                        letter-spacing: 0.01em;
                    }

                    th:first-child {
                        // padding: 1% 0 3% 2%;
                        padding: 10px 0 15px 15px;
                        text-align: start;
                    }

                    th {
                        .form-check-input[type=checkbox] {
                            cursor: pointer;
                        }

                        @media(max-width: 769px) {
                            .form-check-input[type=checkbox] {
                                cursor: pointer;
                                width: 35% !important;
                            }
                        }
                    }
                }

                tr:first-child {
                    text-align: start;
                    border-bottom: 1px solid #D3E0FF;
                }

                tr:last-child {
                    th {
                        border-bottom: 1px solid #D3E0FF;
                    }

                    position: absolute;
                    bottom: 20%;
                    width: 100%;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #D3E0FF;
                    // height: 56px;

                    td {
                        height: 56px;
                        // padding: 2% 0;
                        padding: 0 0;
                        text-align: center;

                        .table-image-container {
                            @media(max-width: 576px) {
                                padding-top: 8px;
                            }

                            display: flex;
                            align-items: center;
                            gap: 5px;

                            .content-img {
                                height: 40px;
                                width: auto;
                            }

                            .title-container {
                                height: 34px;
                                margin-top: 5px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: flex-start;
                                // gap: 5px;

                                .content-name {
                                    font-size: 14px;
                                    font-weight: normal;
                                    line-height: 12px;
                                    letter-spacing: 0.01em;
                                    color: #101828;
                                }

                                .content-date {
                                    font-size: 12px;
                                    font-weight: normal;
                                    line-height: 12px;
                                    letter-spacing: 0.01em;
                                    color: #475467;
                                }
                            }

                        }


                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 0.875rem;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;
                            line-height: 0.75rem;

                            @media(max-width: 1200px) {}
                        }

                        letter-spacing: 0.01em;
                        // .table-switch {
                        //     width: 30%;
                        //     height: auto;
                        // }
                    }

                    td:first-child {
                        // padding: 2%;
                        padding: 0 15px;
                        text-align: start;

                        @media(max-width:576px) {
                            width: 100%;
                            text-align: start;
                            padding: 0 15px;
                        }
                    }

                    td:last-child {
                        @media(max-width: 576px) {
                            text-align: end;
                            padding: 0 15px;
                        }
                    }

                    .more-action {
                        @media(max-width: 576px) {
                            text-align: end;
                            width: 10%;
                            padding: 0 15px;
                        }

                    }
                }

                @media(max-width: 769px) {
                    .form-check-input[type=checkbox] {
                        width: 35% !important;
                    }
                }
            }
        }

        // .content-table {
        //     // background-color: #FFFFFF;
        //     height: 100% !important;
        //     background-color: transparent;

        //     .content-thead {
        //         background: transparent;
        //         // z-index: 1;

        //         tr {
        //             color: #667085;


        //             th {
        //                 font-weight: normal;
        //                 // padding: 1% 0 3% 0;
        //                 padding: 1px 0 15px 0;
        //                 text-align: center;
        //             }

        //             th:first-child {
        //                 // padding: 1% 0 3% 2%;
        //                 padding: 10px 0 15px 20px;
        //                 text-align: start;
        //             }

        //             th {
        //                 background-color: transparent;
        //             }
        //         }

        //         tr:first-child {
        //             text-align: start;
        //             // border-bottom: 1px solid #D3E0FF;
        //         }

        //         tr:last-child {
        //             border-bottom: 1px solid #D3E0FF;
        //             position: absolute;
        //             bottom: 20%;
        //             width: 100%;
        //         }

        //         @media(max-width: 769px) {
        //             .form-check-input[type=checkbox] {
        //                 width: 35% !important;
        //             }
        //         }
        //     }

        //     // tbody {
        //     //     tr {
        //     //         border-bottom: 1px solid #D3E0FF;

        //     //         td {
        //     //             padding: 2% 0;
        //     //             text-align: center;

        //     //             .table-image-container {
        //     //                 height: 2.8vh;
        //     //                 display: flex;
        //     //                 flex-direction: row;
        //     //                 align-items: center;
        //     //                 gap: 5%;

        //     //                 img,
        //     //                 span {
        //     //                     cursor: pointer;
        //     //                     width: auto;
        //     //                     // margin: -4% 0;
        //     //                 }

        //     //                 span {
        //     //                     margin-left: 2%;
        //     //                 }

        //     //                 // @media(max-width: 769px) {
        //     //                 //     align-items: flex-start;
        //     //                 //     flex-direction: column;

        //     //                 //     span {
        //     //                 //         margin-left: 0;
        //     //                 //         margin-top: 1%;
        //     //                 //     }
        //     //                 // }
        //     //             }


        //     //             .table-switch {
        //     //                 cursor: pointer;
        //     //                 width: 30%;
        //     //                 height: auto;
        //     //             }
        //     //         }

        //     //         td:first-child {
        //     //             padding: 2%;
        //     //             text-align: start;
        //     //         }
        //     //     }
        //     // }


        //     @media(max-width: 769px) {
        //         .form-check-input[type=checkbox] {
        //             width: 35% !important;
        //         }
        //     }

        // }
    }

    .mobile-content-wall {

        @media(max-width: 1400px) {
            width: 1000px;
            // width: 950px;

            @media(max-width: 1200px) {
                width: 880px;

                @media(max-width: 992px) {
                    width: 700px;

                    @media(max-width: 769px) {
                        width: 526px;

                        @media(max-width: 576px) {
                            padding-top: 0;
                            width: 100%;
                            // padding: 0 20px;
                        }
                    }
                }
            }
        }

        @media(max-width: 769px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .preview-section {
        width: 1200px !important;

        @media(max-width: 1400px) {
            width: 1100px !important;
            // width: 950px;

            @media(max-width: 1200px) {
                width: 900px !important;

                @media(max-width: 992px) {
                    width: 686px !important;

                    @media(max-width: 769px) {
                        width: 526px !important;

                        @media(max-width: 576px) {
                            padding-top: 0;
                            width: 100% !important;
                            // padding: 0 20px;
                        }
                    }
                }
            }
        }

        @media(max-width: 769px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}

.loader-container {
    margin: 2.5% auto;

    .loader-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem !important;
        // line-height: 1.5rem;
        letter-spacing: 0.01em;
        color: #000000;

        @media(max-width: 769px) {
            font-size: 1.25rem !important;
            line-height: 1.25rem;
        }
    }

    .loader-body {
        background: #D8E3FF;
        padding: 6% 2% 4% 2%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        margin: 5% auto 6% auto;
        border-left: 5px solid #1570EF;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.content-dropdown {
    background: #FFFFFF;
    box-shadow: 0.6px 0.6px 12px 4px rgba(23, 23, 58, 0.1);
    border-radius: 5px;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4.5%;
    width: 160px;
    z-index: 999;

    p,
    a,
    label {
        padding: 4%;
        margin: 0;
        width: 100%;
        text-decoration: none;
        color: #101828;
        font-size: 0.75rem;
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: #2c3039;
            background: #D8E3FF;
        }
    }
}
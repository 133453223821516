// ===== Modal form of uploading ======
.uploading-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.24);

    .uploading-continue-modal {
        // background: rgba(0, 0, 0, 0.3);
        // animation: zoomInModal 1s ease-in-out forwards;
        text-align: left;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 30px;
        padding-bottom: 5.5%;

        .uploading-modal-container {
            overflow: hidden;
            border-radius: 10px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            width: 600px;
            height: 128px;
            padding: 16px 18px;

            @media(max-width: 769px) {
                width: 95%;
                height: fit-content;
            }

            .uploading-modal-body {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;

                .left-image {
                    flex-grow: 1;
                    width: 40px;
                    height: 40px;
                    position: relative;

                    img {
                        width: 40px;
                        height: auto;
                        // animation: blink-opacity 1s infinite ease-in-out; // Add the blinking animation
                    }

                    // Define the blinking animation
                    @keyframes blink-opacity {

                        0%,
                        100% {
                            opacity: 1; // Fully visible
                        }

                        50% {
                            opacity: 0.3; // Dimmed
                        }
                    }
                }

                .right-body {
                    flex-grow: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .title-container {
                        display: flex;
                        justify-content: space-between;

                        .title {
                            color: #17173A;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                        }

                        img {
                            height: auto;
                            width: auto;
                        }
                    }

                    .loading-container {
                        width: 100%;
                        height: 10px;
                        background: #f3f3f3; // Light gray background
                        border-radius: 10px;
                        position: relative;
                        overflow: hidden;

                        .progress-bar {
                            height: 100%;
                            // background: linear-gradient(to right, #4caf50, #81c784); // Gradient for a polished look
                            border-radius: 10px;
                            transition: width 0.3s ease-in-out;
                        }
                    }

                    .footer-container {
                        display: flex;
                        justify-content: space-between;
                        color: var(--Content-Primary, #000000);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }

            }
        }
    }

}
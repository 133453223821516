@keyframes zoomInModal {
    from {
        opacity: 0;
        transform: scale(0); // Start zoomed in (110%)
    }

    to {
        opacity: 1;
        transform: scale(1); // Normal size
    }
}

@keyframes zoomOutModal {
    0% {
        opacity: 1;
        transform: scale(1); // Start zoomed out (10%)
    }

    100% {
        opacity: 0;
        display: none;
        transform: scale(0); // Normal size
    }
}

@keyframes moveRightToLeft {
    from {
        transform: translateX(100%); // Start outside the screen (right)
        // opacity: 0; // Initially hidden
    }

    to {
        transform: translateX(0); // Fully visible
        // opacity: 1;
    }
}

@keyframes moveLeftToRight {
    from {
        transform: translateX(0); // Start at visible
        // opacity: 1;
    }

    to {
        transform: translateX(100%); // Slide out to the right
        // opacity: 0;
    }
}

.closing-time-drawer,
.drawer-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-end;

    // Main drawer container
    .drawer-container {
        background: #FFFFFF;
        height: 100%;
        width: 380px;
        transform: translateX(100%); // Initially off-screen
        animation: moveRightToLeft 0.5s ease-in-out forwards;
    }
}

// When closing the drawer
.closing-time-drawer {
    .drawer-container {
        animation: moveLeftToRight 0.5s ease-in-out forwards;
    }
}



.warning-close-modal,
.warning-modal-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1000;
    opacity: 1;
    /* Start with invisible */
    // backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.2);

    // animation: zoomInModal 0.5s ease-in-out forwards;


    .custom-continue-modal {
        // background: rgba(0, 0, 0, 0.3);
        animation: zoomInModal 1s ease-in-out forwards;
        text-align: left;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 30px;
        padding-bottom: 5.5%;

        .modal-container {
            overflow: hidden;
            border-radius: 10px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            width: 600px;
            height: 148px;
            padding: 20px 18px;

            @media(max-width: 769px) {
                width: 95%;
                height: fit-content;
            }

            .modal-body {
                height: 100%;
                width: 100%;
                display: flex;
                gap: 10px;

                .left-image {
                    flex-grow: 1;
                    width: 40px;
                    border-radius: 50%;

                    img {
                        width: 40px;
                        height: auto;

                    }
                }

                .right-body {
                    flex-grow: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0em;

                        color: #272C35;
                    }

                    .description {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }

                    .footer-button-container {
                        margin-top: 15px;
                        display: flex;
                        gap: 20px;

                        .continue {
                            cursor: pointer;
                            width: fit-content;
                            background: transparent;
                            outline: none;
                            border: none;
                            box-shadow: none;
                            width: fit-content;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0em;
                            color: #272C35;
                            transition: all 0.3s ease-in-out;
                            padding: 0;

                            &:hover {
                                font-weight: 600;
                                opacity: 0.8;
                                color: #F79009;
                            }
                        }

                        .next,
                        .back,
                        .finish {
                            cursor: pointer;
                            background: transparent;
                            outline: none;
                            border: none;
                            box-shadow: none;
                            height: fit-content;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0em;
                            padding: 0;

                            color: #272C35;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                font-weight: 600;
                                color: #0F43BD;
                                opacity: 0.8;
                            }
                        }
                    }
                }

            }
        }
    }

}

// .move-modal-form,
// .share-modal-form {
//     background: rgba(0, 0, 0, 0.25);
// }

.warning-close-modal {
    // animation: zoomOutModal 1s ease-in-out forwards;

    .custom-continue-modal {
        animation: zoomOutModal 0.5s ease-in-out forwards;
    }
}

.no-data {
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



// ===== Move Modal Form ====
.move-modal-form-wrap {
    width: 560px !important;

    @media(max-width: 576px) {
        width: 95% !important;
    }

    .move-table-form {
        width: 100%;
        padding: 30px 0;

        h3 {
            padding: 0 40px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            color: #272C35;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin: 0 40px;
            padding-bottom: 15px;
            // border-bottom: 0.5px solid #C7CDD6;
        }

        .form-item {
            margin-bottom: 20px;
            padding: 0 40px;

            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;

                color: #4E5869;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }

        }

        .action-buttons {
            margin-top: 20px;
            padding: 0 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #F79009;
                }
            }
        }

        .team-table {
            padding: 0 14px 0 40px;
            margin-right: 20px;
            min-height: 173px;
            max-height: 175px;
            overflow-y: auto;
            margin-bottom: 10px;

            .table-responsive {
                table {
                    width: 100%;
                    border-collapse: collapse;

                    thead {
                        display: none;
                    }

                    tbody {
                        tr {
                            border-top: 0.5px solid #C7CDD6; // Add a bottom border to each row

                            &:last-child {
                                border-bottom: 0.5px solid #C7CDD6;
                            }

                            td {
                                min-height: 42px; // Set the height of each row
                                padding: 0;
                                vertical-align: middle; // Center align the content vertically
                                text-align: left;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 14px;
                                letter-spacing: 0.01em;

                                color: #272C35;

                                &:first-child {
                                    text-align: left;
                                    // width: 40%;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    img {
                                        border-radius: 50%;
                                        height: 18px;
                                        width: 18px;
                                    }
                                }

                                // &:nth-last-child(2) {
                                //     width: 50%;
                                // }

                                // border-right: 1px solid red;

                                &:last-child {
                                    text-align: right; // Right align the last column (delete icon)
                                }
                            }

                            img {
                                cursor: pointer;
                                // transition: transform 0.3s ease-in-out;

                                // &:hover {
                                //     transform: scale(1.1); // Scale up the delete icon on hover
                                // }
                            }
                        }
                    }
                }
            }


            &::-webkit-scrollbar-thumb {
                background: #A3A3A3 !important;
                // height: 10px !important;
                border-radius: 10px;
                cursor: pointer;
                min-height: 30px;
                /* Set your desired fixed height */
                max-height: 30px;
                /* Ensure it doesn't exceed the desired fixed height */
                background-clip: padding-box;
                /* Adjusts the background to stay within the thumb bounds */
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }
        }

    }
}
.team-management-settings-container {
    min-height: 100%;
    width: 100%;
    // padding: 0 50px;

    // // width: 850px;

    // @media(max-width: 10240px) {
    //     // width: 750px;
    //     padding: 0 30px;

    //     @media(max-width: 769px) {
    //         @media (max-width: 576px) {}
    //     }
    // }

    .extra {
        height: 30px;
    }

    .settings-header-title {
        height: 46px;
        width: 100%;
        /* Smart layout */
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .back-path-container {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            h3 {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #272C35;
            }

            .back-arrow {
                height: 16px;
                width: auto;
            }

        }

    }

    .settings-sub-header-title {
        margin-top: 20px;
        height: 44px;
        width: 100%;
        /* Smart layout */
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        h3 {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #272C35;
            // color: #0F43BD;
        }

        .back-path-container {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            h3 {
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #0F43BD;
            }

            .back-arrow {
                height: 16px;
                width: auto;
            }

        }

    }

    .settings-header-tab {
        margin-top: 20px;
        height: 44px;
        width: 100%;
        /* Smart layout */
        // padding: 15px 0 15px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;

        @media(max-width: 1400px) {

            @media(max-width: 769px) {
                @media (max-width: 576px) {
                    gap: 18px;
                }
            }
        }

        .tab,
        .active {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            transition: all 0.3s ease-in-out;

            &:hover {
                font-weight: 500;
            }
        }

        .tab {
            font-weight: 500;
            color: #272C35;
        }

        .active {
            color: #0F43BD;
        }
    }

    .notification-settings-form-item {
        min-height: 94px;
        padding: 30px 0px;
        width: 100%;
        /* Smart layout */
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        // @media(max-width: 1200px) {
        //     flex-direction: column;
        //     gap: 0;
        //     align-items: flex-start;

        //     @media(max-width: 769px) {
        //         @media (max-width: 576px) {}
        //     }
        // }

        .title-description-container {
            flex-grow: 1;
            width: 360px;
            // border-left: 1px solid red;
            display: flex;
            flex-direction: column;
            gap: 9px;

            // padding-right: 80px;
            @media(max-width: 769px) {
                width: 100%;

                @media (max-width: 576px) {}
            }

            .title {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #272C35;
            }

            .description {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #4E5869;

                min-width: 323px;

                @media(max-width: 769px) {
                    @media (max-width: 576px) {
                        min-width: 100%;
                    }
                }

                max-width: 357px;
            }
        }


        .action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            width: 360px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;

            .switch-icon {
                width: 24px;
                height: auto;
                cursor: pointer;
            }

            @media(max-width: 1200px) {
                margin-top: 15px;

                @media(max-width: 769px) {
                    width: 100%;

                    @media (max-width: 576px) {}
                }
            }

            // .tik {
            //     z-index: 999;
            //     position: absolute;
            //     right: 115px;
            //     cursor: pointer;
            // }

            .label-input-container {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 5px;

                @media(max-width: 769px) {
                    @media (max-width: 576px) {
                        width: 100%;
                    }
                }

                label {
                    font-size: 14px;
                    font-weight: 400;
                    color: #758299;
                }

                input {
                    resize: none;
                    width: 320px;

                    @media(max-width: 769px) {
                        @media (max-width: 576px) {
                            width: 100%;
                        }
                    }

                    height: 42px;
                    border-radius: 6px;
                    padding: 11px 15px;
                    box-sizing: border-box;
                    border: 0.6px solid #C7CDD6;
                    outline: none;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 20px;
                    letter-spacing: 0.01em;

                    color: #272C35;

                    &::placeholder {
                        color: #B9BFCB;
                    }

                }

                .non-label-tik {
                    z-index: 999;
                    position: absolute;
                    right: 15px;
                    top: 13px;
                    cursor: pointer;
                }

                .tik {
                    z-index: 999;
                    position: absolute;
                    right: 15px;
                    top: 38px;
                    cursor: pointer;
                }
            }

            .custom-select {
                .ant-select-selector {
                    width: 320px !important;

                    @media(max-width: 769px) {

                        @media (max-width: 576px) {
                            width: 100% !important;

                        }
                    }

                    height: 42px !important;
                    border-radius: 6px !important;
                    display: flex;
                    align-items: center;
                    border: 0.6px solid #C7CDD6 !important;
                    outline: none !important;
                }

                .ant-select-selection-item {
                    line-height: 42px !important; // Aligns text vertically
                }

                &:hover,
                &:focus {
                    .ant-select-selector {
                        border: 0.6px solid #C7CDD6 !important;
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }
            }

            input,
            .disable-input,
            .logo-container {
                resize: none;
                width: 320px;
                height: 42px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

            }

            .disable-input {
                background: #F0F2F4;
            }

            .logo-container {
                resize: none;
                width: 320px;
                height: 42px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

                padding: 0 15px 0 0;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                }

                .action-container {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    div,
                    label {
                        cursor: pointer;
                    }
                }
            }
        }

        .color-picker-action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            // width: 50%;
            width: 360px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media(max-width: 1400px) {
                margin-top: 20px;

                @media(max-width: 769px) {
                    width: 100%;

                    @media (max-width: 576px) {}
                }
            }

            .color-picker-input-container {
                min-height: 94px;
                width: 320px;

                @media(max-width: 769px) {
                    width: 100%;

                    @media (max-width: 576px) {}
                }
            }

        }

    }

}

.team-list-settings-container {
    min-height: 100%;
    width: 100%;
    // padding: 0 50px;

    // // width: 850px;

    // @media(max-width: 10240px) {
    //     // width: 750px;
    //     padding: 0 30px;

    //     @media(max-width: 769px) {
    //         @media (max-width: 576px) {}
    //     }
    // }

    .extra {
        height: 30px;
    }

    .settings-header-title {
        height: 46px;
        width: 100%;
        /* Smart layout */
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .back-path-container {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            h3 {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #272C35;
            }

            .back-arrow {
                height: 16px;
                width: auto;
            }

        }

    }

    .settings-header-tab {
        margin-top: 20px;
        height: 44px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        // padding: 15px 0 15px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        .tab,
        .active {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            transition: all 0.3s ease-in-out;

            &:hover {
                font-weight: 500;
                // color: #0F43BD;
            }
        }

        .tab {
            font-weight: 500;
            color: #272C35;
        }

        .active {
            color: #0F43BD;
        }
    }


    .team-list-table {
        .table-responsive {
            width: 100%;
            overflow-x: auto;
        }

        .table {
            width: 100%;
            border-collapse: separate;
            margin-top: 10px;
            border-spacing: 0 20px; // Adds spacing between rows (acts like `margin-bottom`)

            thead {
                display: none;
            }

            tbody {
                tr {
                    td {
                        padding: 0;
                        border: none; // Remove any default borders from cells
                        cursor: pointer;

                        .table-row-content {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 30px;

                            @media(max-width: 769px) {
                                padding: 0 15px;

                                @media (max-width: 576px) {}
                            }

                            border: 0.5px solid #C7CDD6;
                            border-radius: 6px;
                            height: 56px;

                            // .borders {
                            //     height: 56px;
                            //     cursor: pointer;
                            //     border: 1px solid red;
                            // }

                            .team-title-container {
                                min-width: 25%;
                                max-width: 35%;
                                display: flex;
                                gap: 12px;

                                @media(max-width: 576px) {
                                    max-width: 25%;
                                    overflow: hidden;
                                    gap: 8px;
                                }

                                align-items: center;
                            }

                            font-size: 14px;
                            line-height: 14px;

                            @media(max-width: 576px) {
                                font-size: 13px;
                                line-height: 13px;
                            }

                            font-weight: 400;
                            letter-spacing: 0.01em;

                            color: #272C35;
                        }
                    }
                }
            }
        }

        .team-icon {
            flex-shrink: 0;
            height: 16px;
            width: 16px;
            border-radius: 50%;
        }

        .team-name {
            font-weight: 400;
        }

        .delete-icon {
            width: 16px;

            @media(max-width: 576px) {
                width: 12px;
            }

            height: auto;
        }
    }


}
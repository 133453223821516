.announcement-link-gap {
    gap: 5% !important;
}

.logo-url-container {
    display: flex;
    flex-direction: row;
    gap: 5%;
    width: 65%;
    // height: 100%;
    height: 7vh;
    justify-items: center;
    align-items: center;
    overflow: hidden !important;

    img {
        height: 3vh;
        width: auto;
        object-fit: contain;
    }
}

.edit-delete-container {
    display: flex;
    flex-direction: row;
    gap: 30%;
    width: 15%;
    justify-content: flex-end;
    justify-items: center;

    img {
        cursor: pointer;
    }
}

.add-announcement-button {
    position: absolute;
    top: 11%;
    right: 5%;
    width: -moz-fit-content;
    width: fit-content;

    button {
        width: 10vw;
        height: 5vh;
        background: #D8E3FF;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }

    @media(max-width: 1025px) {
        top: 10%;

        button {
            width: 13vw;
            height: 5vh;
            background: #D8E3FF;
            border-radius: 5px;
            display: block;

        }

        @media(max-width: 769px) {
            position: relative;
            top: 0;
            right: 3%;
            z-index: 5;
            margin: 0 0 0 auto;

            button {
                width: 130px;
                height: 5vh;
                background: #D8E3FF;
                border-radius: 5px;
                display: block;

            }
        }
    }
}

.form-campaign-container {
    width: 100%;
    max-height: 80vh;
    overflow: hidden;

    .form-input-container {
        max-height: 75vh;
        overflow: auto;
    }

    .form-textarea {
        margin-top: 3.5%;
        width: 100%;
        resize: none;
        padding: 1.5%;
        border-radius: 3px;
        border: 1px solid var(--icon-general, #ABB9CC);

        font-size: 0.9rem;
        font-weight: 400;

        @media(max-width: 1400px) {
            font-size: 0.75rem;

            @media(min-width: 768px) {
                font-size: 0.9rem;
            }
        }

        &::placeholder {
            color: var(--text-placeholder, #98A2B3);
            font-size: 0.9rem;

            @media(max-width: 1400px) {
                font-size: 0.75rem;

                @media(min-width: 768px) {
                    font-size: 0.9rem;
                }
            }
        }
    }

    .date-time-container {
        display: flex;
        flex-direction: row;
        gap: 5%;
        margin-top: 3.5%;
        width: 100%;
        text-align: center;

        font-size: 0.9rem;
        font-weight: 400;

        @media(max-width: 1400px) {
            font-size: 0.75rem;

            @media(max-width: 769px) {
                font-size: 0.9rem;
                flex-direction: column;
                gap: 1rem;
            }
        }

        .unknown-text {
            @media(max-width: 769px) {
                display: none;
            }
        }

        .from-date-time,
        .time-zone {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .date-time {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 3%;

                .date-picker {
                    width: 65%;

                    &::placeholder {
                        color: var(--text-placeholder, #98A2B3);
                        font-size: 0.9rem;

                        @media(max-width: 1400px) {
                            font-size: 0.75rem;

                            @media(min-width: 768px) {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }

            }
        }

        .time-zone {
            width: 50%;
        }
    }

    .back-container {
        width: 100%;
        padding: 2% 3% 2% 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        // gap: 5%;
        // margin-bottom: 2%;

        .search-input {
            width: 80%;
            margin: 0 auto;
            height: 8.5%;
            // padding-left: 2%;
            border-radius: 3px;
            border: 1px solid var(--border-big-button, #A4BCFD);
            background: var(--bg-white, #FFF);
            color: var(--text-placeholder, #98A2B3);

            .search-icon {
                margin-right: 5px;
            }

            input {
                font-size: 0.9rem;
                font-weight: 400;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        // width: 100% !important;
                        font-size: 0.9rem;
                    }
                }
            }

        }

        img {
            cursor: pointer;
        }
    }

    .header-title {
        font-size: 1.2rem;
        font-weight: 500;
        color: #17173A;
        padding-bottom: 0.5rem;
    }

    .form {
        width: 100%;
        // height: 20vh;
        display: flex;
        flex-direction: column;

        gap: 1.5rem;
        border-radius: 0px 5px 5px 0px;
        border-left: 5px solid var(--button-right-nav-bar, #1570EF);
        background: var(--bg-inbox-selection, #EEF4FF);
        padding: 3.5% 3% 4.5% 3%;

        .input-group {

            width: 100%;
            position: relative;

            .ant-row {
                width: 100%;
                height: 100%;
            }

            margin: 0;

            input {
                width: 100%;
                height: 100%;
                padding: 1% 0;
                border: none;
                border-bottom: 0.5px solid var(--icon-focus, #344054);
                background: transparent;
                outline: none;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                        width: 100%;
                    }
                }
            }

            .image-label {
                position: absolute;
                top: 20%;
                right: 1%;
                cursor: pointer;
                color: #17173A;
                font-size: 0.9rem;
                font-weight: 400;

                @media (max-width: 1400px) {
                    font-size: 0.75rem;

                    @media (max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    .variable-input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        width: 100%;
        position: relative;
        margin: 0.5rem 0;

        label {
            font-size: 0.75rem;
            color: #667085;
        }

        .variable-input {
            border-radius: 4px;
            padding: 1.6% 3%;
        }
    }

    .form-button-container {
        margin-top: 4%;
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5%;

        button {
            width: fit-content;
            padding: 0 5%;
            height: 5vh;
            background: #D8E3FF;
            border: none;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #17173A;
            font-size: 0.9rem;
            font-weight: 400;

            @media (max-width: 1400px) {
                font-size: 0.75rem;

                @media (max-width: 769px) {
                    font-size: 0.9rem;
                }
            }
        }
    }

    .create-campaign-table,
    .select-contact-table {
        height: 30vh;
        padding-right: 7px;
        overflow: auto;

        thead {
            tr {
                background: #FFFFFF;

                th {
                    font-weight: 500;
                    font-size: 0.9rem;
                    line-height: 0.9rem;
                    text-align: start;

                    &:last-child {
                        text-align: center;
                    }
                }

                &:first-child {
                    border-bottom: 2px solid var(--border-inbox, #D3E0FF);
                }
            }

        }

        tbody {
            tr {
                td {
                    background: #EEF4FF;

                    .user-template {
                        display: flex;
                        flex-direction: row;
                        gap: 0.5rem;
                        align-items: center;
                    }

                    text-align: start;

                    &:last-child {
                        text-align: center;
                    }
                }
            }
        }

        // &:hover {
        //     ::-webkit-scrollbar-thumb {
        //         background: #ddd !important;
        //         height: 10px !important;
        //     }
        // }

        &::-webkit-scrollbar-thumb {
            background: #ddd !important;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
            width: 7px;
            height: 5px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-track {
            height: 5px;
            box-shadow: inset 0 0 5px transparent;
            background-color: transparent;
            margin: 0;
        }
    }

    .select-contact-table {
        height: 37vh;
    }

}
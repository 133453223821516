.inbox-title {
    position: sticky;
    top: 0;
    background: #FFFFFF;
    width: 190px;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.01em;

    color: #272C35;
}

.tab-container,
.tab-container-inbox-message-email {
    text-align: left;
    margin-top: 10px;

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 10px;

    .tab-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.01em;

        color: #272C35;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            cursor: pointer;
        }
    }

    .tab-body {
        width: 100%;
        display: flex;
        flex-direction: column;

        // gap: 2px;

        .body-container,
        .body-container-active {
            cursor: pointer;
            padding: 10px;
            border-radius: 3px;
            height: 36px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;

            img,
            .bg-image {
                height: auto;
                width: auto;
            }

            .title {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;
            }

            &:hover {
                background: #E0EAFF;
            }

            transition: all 0.3s ease-in-out;
        }


        .body-container-active {
            background: #E0EAFF;
        }
    }
}

.tab-container-inbox-message-email {
    margin-top: 0;

    // .tab-body {

    //     // gap: 5px;
    //     .body-container {
    //         height: 36px;
    //         transition: all 0.3s ease-in-out;

    //         &:hover {
    //             background: #E0EAFF;
    //         }
    //     }
    // }
}
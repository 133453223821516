.mobile-drawer-header {
    font-family: 'Inter';
    font-style: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .logo-title {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            width: 20px;
            height: 20px;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: #17173A;
        }
    }

    .mobile-cross-icon {
        cursor: pointer;
        width: 20px;
        height: 20;
        transition: all 0.5s ease-in-out;

        &:hover {
            opacity: 0.5;
        }
    }
}

.mobile-drawer-body {
    font-family: 'Inter';
    font-style: normal;
    // padding: 10px 0;

    .mobile-page-active {
        // active    
        background: #D8E3FF;
        border: 1px solid #E5E5E5;
    }

    ul {
        // list style none;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            margin: 15px 0;
            height: 40px;
            width: 80%;
            padding: 12px 0 15px 25px;
            border-radius: 0px 20px 20px 0px;

            img {
                width: 20px;
                height: 20px;
            }

            p {
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #17173A;
            }
        }
    }
}

.mobile-drawer-footer {
    font-family: 'Inter';
    font-style: normal;
    position: absolute;
    height: 48px;
    width: 80%;
    left: 0;
    bottom: 3%;

    // border: 1px solid #E5E5E5;
    .mobile-drawer-footer-container {
        height: 100%;
        padding: 5px 0 5px 25px;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .mobile-drawer-footer-active {
        // active
        background: #D8E3FF;
        border: 1px solid #E5E5E5;
    }

    img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    p {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #17173A;
    }
}

.nav-wrapper {
    font-family: 'Inter';
    font-style: normal;
    // background: linear-gradient( 90deg, rgb(0,66,124) 0%, rgb(12,45,91) 13%, rgb(23,23,58) 100%);
    // background: -moz-linear-gradient( 90deg, rgb(0,66,124) 0%, rgb(12,45,91) 13%, rgb(23,23,58) 100%);
    // background: -ms-linear-gradient( 90deg, rgb(0,66,124) 0%, rgb(12,45,91) 13%, rgb(23,23,58) 100%);
    background: -webkit-linear-gradient(90deg, rgb(0, 66, 124) 0%, rgb(12, 45, 91) 13%, rgb(23, 23, 58) 100%);
    opacity: 1;
    background: linear-gradient(180deg, #020818 -3%, #07225F 75%, #0B328E 100%);
    // background: #17173A;
    width: 70px;

    @media(max-width: 769px) {
        width: 70px;
    }

    min-height: 100vh;
    position: fixed;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // overflow: auto;
    // top: 5%;
    nav {
        // border: 2px solid red;
        height: fit-content;

        .nav-logo-icon {
            cursor: pointer;
            width: 30px;
            height: auto;
            display: inline-block;
            margin: 15px 0 0 0;
            // &:hover,&.active{
            //     filter: sepia(100%);
            // }
        }

        // @media (min-width: 1440px) {
        //     .logo-icon {
        //         width: 40%;
        //         height: auto;
        //     }
        // }

        ul {
            // padding: 30px 0;
            padding: 30px 0;

            &:nth-child(1) {
                padding: 0;
            }

            margin: 0;

            li {

                a,
                button {
                    padding: 14px 0;
                    // cursor: pointer;
                    margin-bottom: 20px;
                    width: 100%;
                    height: fit-content;
                    // height: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    background-color: transparent;

                    img {
                        height: 18px;
                        width: auto;
                    }

                    &:hover,
                    &.active {
                        background: #07225F;
                    }

                    transition: all 0.3s ease-in-out;

                    // border: 1px solid white;
                    // &:hover,&.active{
                    //     filter: sepia(100%);
                    // }
                    // @media (min-width: 1440px) {
                    //     width: 32px !important;
                    //     height: 32px !important;
                    // }
                    // &:hover, &.active {
                    //     background-color: #233960;
                    //     background-size: auto 38%;
                    //     background-repeat: no-repeat;
                    //     background-position: center;
                    // }
                }

            }

            // .li-7 {

            //     a,
            //     button {
            //         // background: url('../../../assets/img/twitter-normal.svg');
            //         // background-size: auto 38%;
            //         // background-repeat: no-repeat;
            //         // background-position: center;

            //         &:hover,
            //         &.active {
            //             background-color: #233960;
            //             // background: url('../../../assets/img/twitter-normal.svg');
            //             // background-size: auto 38%;
            //             // background-repeat: no-repeat;
            //             // background-position: center;
            //         }

            //         transition: all 0.3s ease-in-out;
            //     }
            // }

        }
    }
}
.mobile-navbar {
    display: block;
    // height: 9vh;
    height: 8vh;
    font-family: 'Inter';
    font-style: normal;
    border-width: 1px 0px 1.7px 0px;
    border-style: solid;
    border-color: #D3E0FF;
    background-color: #FFFFFF;
    // background-color: #F4F3FF;

    @media (max-width: 576px) {
        background-color: #F4F3FF;
    }

    @media (min-width: 769px) {
        display: none !important;
    }

    .nav-mobile-container {
        z-index: 102 !important;
        padding: 15px 20px;

        .nav-mobile-item {
            .page-title {
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 0.01em;
                color: #17173A;
            }

            .workspace-name {
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #17173A;
            }

            .workspace-logo {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }
    }
}

.navbar {
    padding: 0 !important;
}

.lg-navbar {
    height: 100%;
    width: 100%;

    @media (max-width: 769px) {
        display: none !important;
    }

    .nav-gap {
        gap: 30px;

        @media(max-width: 1200px) {
            gap: 20px;

            @media(max-width: 1024px) {
                gap: 10px;
            }
        }
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.navbar-nav .dropdown-menu {
    display: relative;
    left: -300%;
    margin-top: 5px !important;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 2 0 0 var(--bs-navbar-toggler-focus-width) !important;
    /* box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); */
}

// .header{
//     width: 90%;
//     margin: 0 15%;
//     padding: auto 20px;
// }
.logo-icon {
    cursor: pointer;
    width: 28px;
    height: 28px;
    display: inline-block;
    // &:hover,&.active{
    //     filter: sepia(100%);
    // }
}

@media (min-width: 1440px) {
    .logo-icon {
        width: 25px !important;
        height: 25px !important;
    }
}

@media(max-width: 1024px) {
    .profile-button {
        margin-right: 10px;
        // box shadow for all sides same height as border radius with light border
        // box-shadow: 0px 0px 10px #00000026;
    }
}

header {
    font-family: 'Inter';
    font-style: normal;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    padding: 20px 20px;
    display: flex !important;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    .logo {
        font-family: 'Inter-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #45433B;

        a {
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        span {
            padding: 0 8px;
            color: #45433B;

        }
    }

}

.profile {
    // margin: auto 25px !important;
    width: fit-content;
}

.notification-container {
    width: fit-content;

    .icon-top-bg {
        background: red;
        position: absolute;
        border-radius: 50%;
        height: 6px;
        width: 6px;
        top: 1px;
        right: -5px;
    }
}



.notification-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 69px;
    width: 148px;
    box-shadow: 0px 0px 10px #00000026;
    background-color: #FFFFFF !important;
    border-radius: 10px;

    p {
        background: #D8E3FF;
        width: 100%;
        height: 28px;
        padding-top: 1px;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
    }
}

.notifications {
    ::-webkit-scrollbar-thumb {
        background: #ffffff !important;
        height: 0 !important;
    }

    font-family: 'Inter';
    font-style: normal;
    width: 420px;
    /* BG / White */
    background: #FFFFFF;
    box-shadow: 0.6px 0.6px 10px 1px rgba(23, 23, 58, 0.15);
    border-radius: 5px;

    img {
        margin-top: 4px;
    }

    .header {
        border-bottom: 1.5px solid #D3E0FF;
        ;
        height: 40px;

        .notification-length {
            margin-left: 10px;
            padding: 2px 5px;
            min-width: 20px;
            left: 99px;
            top: 12px;
            background: #1849A9;
            border-radius: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
        }
    }

    .notification {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background: #F8F9FC;
        /* BG/Active Text BG */

        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #D8E3FF;

        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        /* or 150% */

        letter-spacing: 0.03em;

        /* Text / Text */

        color: #667085;

        .notification-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}

@media (max-width: 769px) {
    .profile {
        margin: 0 11px !important;
    }

    .notification-container {
        margin-top: 12px !important;
        margin-bottom: 5px !important;
    }

    input {
        width: 60%;
        margin: 0 auto;
    }
}
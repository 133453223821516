@keyframes zoomInModal {
    from {
        opacity: 0;
        transform: scale(0); // Start zoomed in (110%)
    }

    to {
        opacity: 1;
        transform: scale(1); // Normal size
    }
}

@keyframes zoomOUtModal {
    from {
        opacity: 1 !important;
        transform: scale(1) !important; // Start zoomed in (110%)
    }

    to {
        opacity: 0 !important;
        transform: scale(0) !important; // Normal size
    }
}



.close-modal,
.close-modal-modal-form-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 1000;
    opacity: 1;
    /* Start with invisible */
    // backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0);

    animation: zoomInModal 0.5s ease-in-out forwards;


    .custom-continue-modal {
        // background: rgba(0, 0, 0, 0.3);
        animation: zoomInModal 1s ease-in-out forwards;
        text-align: left;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 30px;
        padding-bottom: 5.5%;

        .modal-container {
            overflow: hidden;
            border-radius: 10px;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            width: 600px;
            height: 148px;
            padding: 20px 15px;

            @media(max-width: 576px) {
                width: 95%;
                height: fit-content;
            }

            .modal-body {
                height: 100%;
                width: 100%;
                display: flex;
                gap: 10px;

                .left-image {
                    flex-grow: 1;
                    width: 40px;
                    border-radius: 50%;

                    img {
                        width: 40px;
                        height: auto;

                    }
                }

                .right-body {
                    flex-grow: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0em;

                        color: #272C35;
                    }

                    .description {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }

                    .button-container {
                        margin-top: 15px;
                        display: flex;
                        gap: 20px;

                        .continue {
                            width: fit-content;
                            background: transparent;
                            outline: none;
                            border: none;
                            box-shadow: none;
                            width: fit-content;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0em;

                            color: #F79009;
                            transition: all 0.3s ease-in-out;
                            padding: 0;

                            &:hover {
                                opacity: 0.8;
                            }
                        }

                        .next,
                        .back,
                        .finish {
                            background: transparent;
                            outline: none;
                            border: none;
                            box-shadow: none;
                            height: fit-content;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0em;

                            color: #272C35;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                color: #0F43BD;
                            }
                        }
                    }
                }

            }
        }
    }

}

// .move-modal-form,
// .share-modal-form {
//     background: rgba(0, 0, 0, 0.25);
// }

.close-modal {
    z-index: 0;
    opacity: 0;
    transform: scale(0);
    animation: zoomOutModal 1s ease-in-out forwards;
    animation-play-state: paused;
}

.no-data {
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

// ===== Share Modal Form ====
.share-modal-form-wrap {
    width: 560px !important;

    @media(max-width: 576px) {
        width: 95% !important;
    }

    .people-teams-table-form {
        width: 100%;
        padding: 30px 0;

        h3 {
            padding: 0 40px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            color: #272C35;
            margin-bottom: 20px;
        }

        .share-radio-actions {
            padding: 0 40px;
            display: flex;
            align-items: center;
            gap: 15px;

            .radio-action {
                cursor: pointer;
                display: flex;
                gap: 10px;
                align-items: center;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0em;
                color: #272C35;
                // border-bottom: 0.5px solid #C7CDD6;

            }

            padding-bottom: 15px;
        }

        .form-item {
            margin-bottom: 20px;
            padding: 0 40px;

            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;

                color: #4E5869;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }

        }

        .share-tabs {
            padding: 0 40px;
            display: flex;
            align-items: center;
            gap: 25px;

            .share-tab-container,
            .share-tab-container-active {
                cursor: pointer;
                display: flex;
                gap: 15px;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #0F43BD;
                }

                // border-bottom: 0.5px solid #C7CDD6;
            }

            .share-tab-container-active {
                color: #0F43BD;

            }

            padding-bottom: 15px;
        }

        .action-buttons {
            margin-top: 20px;
            padding: 0 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #F79009;
                }
            }
        }

        .people-teams-table {
            padding: 0 14px 0 40px;
            margin-right: 20px;
            min-height: 135px;
            max-height: 135px;
            overflow-y: auto;
            margin-bottom: 10px;

            .table-responsive {
                table {
                    width: 100%;
                    border-collapse: collapse;

                    thead {
                        display: none;
                    }

                    tbody {
                        tr {
                            border-top: 0.5px solid #C7CDD6; // Add a bottom border to each row

                            &:last-child {
                                border-bottom: 0.5px solid #C7CDD6;
                            }

                            td {
                                min-height: 42px; // Set the height of each row
                                padding: 0;
                                vertical-align: middle; // Center align the content vertically
                                text-align: left;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 14px;
                                letter-spacing: 0.01em;

                                color: #272C35;

                                &:first-child {
                                    text-align: left;
                                    width: 55%;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    img {
                                        border-radius: 50%;
                                        height: 18px;
                                        width: 18px;
                                    }
                                }

                                &:nth-last-child(2) {
                                    width: 40%;
                                }

                                // border-right: 1px solid red;

                                &:last-child {
                                    text-align: right; // Right align the last column (delete icon)
                                }
                            }

                            img {
                                cursor: pointer;
                                // transition: transform 0.3s ease-in-out;

                                // &:hover {
                                //     transform: scale(1.1); // Scale up the delete icon on hover
                                // }
                            }
                        }
                    }
                }
            }


            &::-webkit-scrollbar-thumb {
                background: #A3A3A3 !important;
                // height: 10px !important;
                border-radius: 10px;
                cursor: pointer;
                min-height: 30px;
                /* Set your desired fixed height */
                max-height: 30px;
                /* Ensure it doesn't exceed the desired fixed height */
                background-clip: padding-box;
                /* Adjusts the background to stay within the thumb bounds */
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }
        }

    }
}

// ===== Modal Form ====
.modal-form-wrap {
    width: 480px !important;

    @media(max-width: 576px) {
        width: 95% !important;
    }

    .modal-form {
        width: 100%;
        padding: 30px 0;

        .modal-title {
            margin: 0 40px;
            padding-bottom: 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            color: #272C35;
            border-bottom: 0.8px solid var(--Normal, #D6DAE1);
            margin-bottom: 20px;
        }

        .modal-search {
            margin-bottom: 20px;
            padding: 0 40px;

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }
        }

        .color-picker-form-item {
            margin-bottom: 20px;
            padding: 0 40px;

            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;

                color: #4E5869;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                text-align: center;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }

            .color-picker-input-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }

        .action-buttons {
            margin-top: 20px;
            padding: 0 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #F79009;
                }
            }
        }

        .team-table {
            padding: 0 14px 0 40px;
            margin-right: 20px;
            min-height: 173px;
            max-height: 175px;
            overflow-y: auto;
            margin-bottom: 10px;

            .table-responsive {
                table {
                    width: 100%;
                    border-collapse: collapse;

                    thead {
                        display: none;
                    }

                    tbody {
                        tr {
                            border-top: 0.5px solid #C7CDD6; // Add a bottom border to each row

                            &:last-child {
                                border-bottom: 0.5px solid #C7CDD6;
                            }

                            td {
                                min-height: 42px; // Set the height of each row
                                padding: 0;
                                vertical-align: middle; // Center align the content vertically
                                text-align: left;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 14px;
                                letter-spacing: 0.01em;

                                color: #272C35;

                                &:first-child {
                                    text-align: left;
                                    // width: 40%;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    img {
                                        border-radius: 50%;
                                        height: 18px;
                                        width: 18px;
                                    }
                                }

                                // &:nth-last-child(2) {
                                //     width: 50%;
                                // }

                                // border-right: 1px solid red;

                                &:last-child {
                                    text-align: right; // Right align the last column (delete icon)
                                }
                            }

                            img {
                                cursor: pointer;
                                // transition: transform 0.3s ease-in-out;

                                // &:hover {
                                //     transform: scale(1.1); // Scale up the delete icon on hover
                                // }
                            }
                        }
                    }
                }
            }


            &::-webkit-scrollbar-thumb {
                background: #A3A3A3 !important;
                // height: 10px !important;
                border-radius: 10px;
                cursor: pointer;
                min-height: 30px;
                /* Set your desired fixed height */
                max-height: 30px;
                /* Ensure it doesn't exceed the desired fixed height */
                background-clip: padding-box;
                /* Adjusts the background to stay within the thumb bounds */
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }
        }

    }
}
@keyframes zoomInAnimation {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1),
    }
}

@keyframes zoomOutAnimation {
    from {
        opacity: 1;
        transform: scale(1); // Start at visible
    }

    to {
        opacity: 0;
        transform: scale(0); // Slide out to the right
    }
}

.closing-time-global-modal,
.global-modal-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    // Main modal container
    .global-modal-container {
        background: #FFFFFF;
        border: 0.8px solid var(--Normal, #D6DAE1);
        border-radius: 10px;
        min-height: 320px;
        max-height: 580px;
        overflow: auto;
        min-width: 360px;
        transform: translateX(100%); // Initially off-screen
        animation: zoomInAnimation 0.5s ease-in-out forwards;
    }
}

// When closing the modal
.closing-time-global-modal {
    .global-modal-container {
        animation: zoomOutAnimation 0.5s ease-in-out forwards;
    }
}
.profile-info {
    h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        text-transform: capitalize;
        color: #17173A;
        text-align: left;
        margin: 0 0 20px;
    }
}

.billing-wrapper {
    margin: 40px 0 0;


}


.info-wrapper {
    background: #FFFFFF;
    border: 1px solid #E1F2FF;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 0 20px;
    padding: 0 0 22px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.setHeight {
        min-height: 282px;
    }

    &.hightlight {
        &:hover {
            background: #E9F5FF;
            border: 1px solid #FFCA00;
            box-shadow: inset 0px -6px 0 0 #05AC74
        }
    }

    h4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        padding: 13px 20px;
        margin: 0;
        color: #17173A;
        text-align: left;
        border-bottom: 1px solid #ECECEC;
    }

    .info-section {
        margin: auto;
        width: 100%;
        word-break: break-all;

        .info-details {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0 20px;

            .profile-info {
                .profile-pic {
                    width: 80px;
                    height: 80px;
                    border: 5px solid #FFF;
                    border-radius: 50px;
                    margin: 0 auto 20px;
                    box-shadow: 0px 0 0px 2px #ffca00;
                    overflow: hidden;

                    i {
                        width: 20px;
                        height: 20px;
                        border-radius: 50px;
                        background: #000;
                    }
                }

                label {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                    text-transform: capitalize;
                    color: #172B70;
                }
            }

            .mail-info {
                span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    color: #555555;
                }
            }

            .pic-holder {
                max-width: 100px;
                min-height: 50px;
            }

            span {
                padding: 0 0 10px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                text-transform: capitalize;
                color: #17173A;
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #555555;

            }

            .search-holder {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 20px 20px;

                input {
                    background: #F3F3F3;
                    border: 1px solid #C7CFEA;
                    box-sizing: border-box;
                    border-radius: 3px;
                    outline: none;
                    padding: 4px 10px;
                    width: calc(100% - 160px);
                }
            }
        }
    }

    span {
        width: 80%;
    }

    .my-facebook-button {
        background-color: #FFF;
        color: #172B70;
        border: 1px solid #172B70;
        border-radius: 4px;
        height: 40px;
        width: 100%;
        margin: 0px 0px 20px;
        font-size: 12px;
        font-weight: 600;
        visibility: hidden;
        display: none;
    }

}
.ant-modal-header {
    padding: 0 1% !important;
}

.ant-modal-body {
    padding: 0 !important;
}

.cross-icon-container {

    .cross-icon-btn {
        cursor: pointer;
        background: #ABB9CC;
        border-radius: 50%;
        height: auto;
        width: fit-content;
        padding: 1% 1.05% 1% 1%;

        .cross-icon {
            display: block;
            color: #fff;
            height: auto;
            width: 0.5vw;
        }
    }
}

.feed-scrolling {
    height: 80vh !important;

    @media (max-height: 620px) {
        height: 72vh !important;
    }

}

.feed-card-header-conversation {
    padding: 2% 4.5% 4% 3% !important;
    // padding: 2% 4.5% 1% 3%;
}

.wall-box-modal {
    font-family: "Inter";
    // padding: 0 5%;

    .feed-card-header {
        display: flex;
        // align-items: center;
        margin-top: 10px;
        padding: 0 10px;
        position: relative;

        .profile-pic {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            margin-right: 10px;
        }

        .user-details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 7px;

            .username-stars-rating-container {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .username {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0.01em;

                    color: #17173A;
                }

                .stars-rating-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .stars {
                        display: flex;
                        gap: 5px;

                        img {
                            height: 11px;
                            width: 11px;
                        }
                    }

                    .rating {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 12px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }
                }

            }

            .tag-text {
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0.01em;
                color: #667085;

                .recommend-title {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

        }

        .provider-logo {
            height: 14px;
            width: auto;
        }

    }

    .feed-card-body {
        padding: 0 10px;
        margin: 12px 0 15px 0;
        font-size: 13px;
        font-weight: 400;
        // line-height: 13px;
        letter-spacing: 0.01em;

        color: #4E5869;
    }

    .feed-card-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        /* Padding for the footer */
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.01em;

        color: #4E5869;
        border-top: 0.5px solid #AAB2C0;

        .date {
            flex-grow: 1;
            text-align: right;
        }

        .prev-next-add-wall-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .prev-next-container {
                font-style: normal;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background: #F5F8FF;
                border-radius: 13px;
                border: 1px solid #175CD3;
                padding: 5px;
                width: 80px;

                .prev-next {
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;

                    // border: 1px solid blue;

                    .prev,
                    .next {
                        width: 10px;
                        height: auto;
                        margin: auto;
                        // border: 1px solid blue;
                    }
                }
            }

            .add-to-wall-btn {
                // display: block;
                font-style: normal;
                padding: 5px 8px;
                // width: 5.8vw;

                background: #F5F8FF;
                border-radius: 13px;
                font-weight: 500;
                font-size: 0.7rem;
                // line-height: 0.625rem;
                letter-spacing: 0.01em;
                color: #17173A;
            }
        }


        .add-to-wall-btn-hover {
            &:hover {
                background: #F5F8FF;
                // border: 1px solid #18e4e4;
                border-radius: 13px;
            }
        }

        .add-to-wall-btn {
            padding: 5px 8px;
            // width: 5.8vw;

            // height: 2.7vh;
            background: #f8fbff;
            border-radius: 13px;
            font-weight: 500;
            font-style: normal;
            color: #17173A;
            transition: all 0.3s ease-in-out;
            border: 1px solid #1570ef;

            // &:hover {
            //     background: #EEF4FF;
            //     /* Button / Active Button Outline */

            //     border: 1px solid #175CD3;
            //     border-radius: 13px;
            // }
        }

    }
}

.request-review-form {
    font-family: "Inter";

    padding: 0 8%;

    @media(max-width: 769px) {
        .request-review-title {
            h4 {
                font-size: 18px;
            }

            p {
                font-size: 13px;
            }
        }
    }

}

.feeds-row-mobile,
.feeds-conversations-details {
    font-family: "Inter";

    display: block;

    // padding: 1% 4% 1% 2%;
    // padding: 2.1% 4% 0% 2%;
    // padding: 0 10px;
    @media (min-width: 769px) {
        display: none !important;
        width: 100% !important;
    }

    .mobile-feeds {
        height: 90vh;
    }

    // .mobile-conversations {
    //     height: 1000vh;
    // }
}

.feeds-row-lg {
    width: 100%;
    display: block;
    position: relative;

    @media (max-width: 769px) {
        display: none;
    }

}

.ant-tooltip-inner {
    color: #000 !important;
}

.lg-description {
    display: none;

    @media(min-width: 1601px) {
        display: block;
    }
}

.normal-description {
    display: none;

    @media(max-width: 1600px) {
        display: block;
    }
}


.feed-card-active {
    // background: #EEF4FF;
    // background: #EEF4FF;
    background: #F5F8FF !important;
}

.feed-card-hover {
    height: fit-content !important;

    &:hover {
        background: #F5F8FF;
    }
}

// main feed container
.feeds-container {
    height: 100%;
    flex-grow: 1;
    padding: 0;
    padding-left: 10px;

    @media(max-width: 769px) {
        padding: 10px;

        @media(max-width: 576px) {
            padding: 40px 20px 0 20px;

        }
    }

    // height: 100vh;
    // overflow-y: auto;
    ::-webkit-scrollbar-thumb {
        background: transparent !important;
        height: 0 !important;
    }

    .conversation-wall-mobile {
        display: none;
        border-top: 1px solid #1570EF;
        border-bottom: 1px solid #1570EF;
        height: 70px;
        // height: 70px;
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
        width: 100%;

        @media (max-width: 769px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
        }

        .coder-wall-btn,
        .gallery-btn,
        .inbox-btn {
            border: 2px solid #1570EF;
        }

        .inbox-btn,
        .inbox-btn-active {
            box-sizing: border-box;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: url('../../assets/icons/material-message-active.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            transition: all 0.3s ease-in-out;
        }

        .inbox-btn:hover,
        .inbox-btn-active {
            background-color: #1570EF;
            background-image: url('../../assets/icons/material-message.svg');
        }

        .gallery-btn,
        .gallery-btn-active {
            box-sizing: border-box;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            // background: url('../../assets/icons/gallery-icon-active.svg');
            background: url('../../assets/icons/gallery-icon-active.svg');
            background-repeat: no-repeat;
            background-position: center;
            // background-color: #1570EF;
            background-size: 50%;
            transition: all 0.3s ease-in-out;
        }

        .gallery-btn:hover,
        .gallery-btn-active {
            background-color: #1570EF;
            background-image: url('../../assets/icons/gallery-icon.svg');
        }

        .coder-wall-btn,
        .coder-wall-btn-active {
            box-sizing: border-box;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: url('../../assets/icons/simple-coderwall-active.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            transition: all 0.3s ease-in-out;
        }

        .coder-wall-btn:hover,
        .coder-wall-btn-active {
            background-color: #1570EF;
            background-image: url('../../assets/icons/simple-coderwall.svg');
        }

        // Text of buttons
        .mobile-inbox-gallery-wall {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.01em;
            color: #17173A;
        }


        .inbox-gallery-wall-active {
            color: #1570EF;
        }
    }

    .conversation-wall {
        z-index: 998;
        position: fixed;
        top: 2.5%;
        left: 40%;
        display: flex;
        gap: 10%;

        button {
            border: none;
            background: transparent;
            outline: none;
            font-size: 0.85rem;
            font-weight: 500;
            line-height: 0.85rem;
            border-bottom: 2px solid white;
            cursor: pointer;
            padding: 3.8% 3%;
        }

        .inbox-button-active {
            font-weight: 600;
            border-bottom: 2px solid #1570EF;
            transition: all 0.2s ease-in-out;
        }

        @media (max-width: 769px) {
            display: none !important;
        }
    }

    // old version
    // .conversation-wall {
    //     position: fixed;
    //     gap: 10px;
    //     top: 9%;
    //     right: 15px;

    //     @media (max-width: 769px) {
    //         display: none !important;
    //     }
    // }

    .feed-row-lg-container {
        display: flex;
        width: 100%;
        height: 100%;
        // gap: 5px;
    }

    .conversation-left-side {
        flex-shrink: 1;
        min-width: 190px;
        max-width: 190px;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        border-right: 0.7px solid #C7CDD6;

        // &:hover {
        //     &::-webkit-scrollbar-thumb {
        //         background: #AAB2C0 !important;
        //         // height: 10px !important;
        //         border-radius: 10px;
        //         cursor: pointer;
        //         min-height: 30px;
        //         /* Set your desired fixed height */
        //         max-height: 30px;
        //         /* Ensure it doesn't exceed the desired fixed height */
        //         background-clip: padding-box;
        //         /* Adjusts the background to stay within the thumb bounds */
        //     }

        // }

        // &::-webkit-scrollbar {
        //     width: 6px;
        // }

        // &::-webkit-scrollbar-track {
        //     box-shadow: inset 0 0 5px transparent;
        //     background: transparent !important;
        // }
    }

    .feeds-sections {
        flex-shrink: 1;
        width: 310px;
        height: 100%;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        // padding-left: 5px;
        position: relative;

        .unified-btn {
            width: 300px;
            margin: 7.5px 0;
            padding-right: 10px;

            @media (max-width: 769px) {
                padding-right: 1%;
            }

            .active {
                background: #EEF4FF;
                /* Button / Active Button Outline */

                border: 1px solid #175CD3;
                border-radius: 13px;
            }
        }

        .unified-btn button {
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 5px;
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            background: #D8E3FF;
            border-radius: 15px;
            outline: none;
            letter-spacing: 0.01em;

            color: #000000;
            transition: all 0.3s ease-in-out;
            border: 1px solid #D3E0FF;

            &:hover {
                border: 1px solid #175CD3;
            }



            // @media (max-width: 1600px) {
            //     // padding: 0 3.5%;
            //     font-size: 0.65rem;

            //     @media (max-width: 1400px) {
            //         padding: 0 3%;
            //         height: 3.5vh;
            //         // height: 2.8vh;
            //         // height: 18px;

            //         @media (max-width: 769px) {
            //             // height: 20px;
            //             padding: 0 3%;
            //             font-size: 0.6875rem;
            //             // line-height: 1.6%;

            //         }
            //     }
            // }
        }

        .feeds-section-hover {
            &:hover {
                ::-webkit-scrollbar-thumb {
                    // -webkit-transition: background-color 0.5s ease-in-out !important;
                    // -moz-transition: background-color 0.5s ease-in-out !important;
                    // -o-transition: background-color 0.5s ease-in-out !important;
                    // transition: background-color 0.5s ease-in-out !important;
                    background: #ddd !important;
                }
            }

            ::-webkit-scrollbar {
                // display: none;
                // -webkit-transition: background-color 1s ease-in-out !important;
                // transition: background-color 1s ease-in-out !important;
                width: 5px;
                cursor: pointer;
            }

            ::-webkit-scrollbar-track {
                height: 5px !important;
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb {
                background-color: transparent !important;
                border-radius: 10px !important;
            }
        }


        .search-bar-container {
            position: sticky;
            top: 0;
            z-index: 99;
            background: #FFFFFF;
            margin-right: 5px;
            // width: 100%;
        }




        .empty-feeds-section {
            width: 100%;

            .empty-section {
                position: fixed;
                top: 35%;
                left: 360px;
            }

            h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 15px;
                text-align: center;
                text-transform: capitalize;
                color: #2F407C;
                padding: 16px 0;
            }
        }

        .facebook-feeds {
            overflow-y: auto;
            height: calc(100vh - 200px);
            padding: 0 2px;

            .facebook-feeds-wrapper {
                box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
            }
        }

    }

    .wall-sections,
    .conversation-sections {
        // width: 33%;
        // width: 100%;
        // flex-grow: 1;
        height: 100%;
        // background: #F4F3FF;
        background: #F5F8FF;
        display: flex;
        flex-direction: column;

        padding: 0;
        position: relative;
        // margin-top: 0.945%;
        // margin-top: 0.75rem;

        @media(max-width: 769px) {
            height: 88vh;
            margin-top: 0;
        }

        .wall-search-section {
            // height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .embed-wall {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;

                @media(max-width: 1025px) {
                    flex-direction: column;
                    gap: 10px;
                }

                .lg-search-container {
                    position: relative;
                    z-index: 99;
                    // width: 500px;
                    width: 50%;

                    @media(max-width: 1025px) {
                        width: 90%;

                        @media(max-width: 769px) {
                            display: none;
                        }
                    }

                }

                .action-buttons {
                    width: 100%;
                    display: flex;

                    @media(max-width: 576px) {
                        justify-content: space-between;
                    }

                    // flex-direction: row-reverse;
                }

                // .sm-search-container {
                //     width: 350px;

                //     // .search-icon {
                //     //     position: absolute;
                //     //     left: 10px;
                //     //     top: 15px;
                //     //     height: auto;
                //     //     width: 15px;
                //     //     cursor: pointer;

                //     //     // @media(max-width: 1600px) {
                //     //     //     left: 30.5%;

                //     //     //     @media(max-width: 1400px) {
                //     //     //         left: 31%;
                //     //     //     }
                //     //     // }
                //     // }

                //     // input {
                //     //     width: 100% !important;
                //     //     height: 42px;
                //     //     background: #FFFFFF;
                //     //     border: 1.3px solid #D3E0FF;
                //     //     border-radius: 5px;
                //     //     box-sizing: border-box;
                //     //     padding: 0 25px;
                //     //     outline: none;
                //     //     font-style: normal;
                //     //     font-weight: 400;
                //     //     font-size: 0.875rem;
                //     //     line-height: 0.875rem;
                //     //     /* identical to box height, or 100% */

                //     //     letter-spacing: 0.01em;

                //     //     color: #98A2B3;
                //     // }

                //     // input::placeholder {
                //     //     font-style: normal;
                //     //     font-weight: 400;
                //     //     color: #98A2B3;
                //     //     font-size: 0.875rem;
                //     //     line-height: 0.875rem;
                //     //     letter-spacing: 0.02em;
                //     // }
                // }
            }


            // .secondary {
            //     margin-left: 10px;
            //     height: 35px;
            // }
        }

        .empty-wall-section {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-direction: column;
            transform: translate(-50%, -50%);
            position: absolute;
            left: 50%;
            top: 50%;
            height: 185px;

            @media (max-width: 769px) {
                display: none;
            }

            h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 15px;
                text-transform: capitalize;
                color: #2F407C;
                padding: 16px 0;
            }

            span {
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 15px;
                text-transform: capitalize;
                color: #6476B5;
            }
        }
    }

    .conversation-sections {
        flex-grow: 1;
        border-left: 0.7px solid #C7CDD6;
        border-right: 0.7px solid #C7CDD6;
    }

    .wall-sections {
        margin-top: 10px;
        flex-shrink: 1;
        width: 75%;
        border-radius: 5px 5px 0px 0px;
    }

    .conversation-details-right-side {
        flex-shrink: 1;
        width: 350px;
        height: 100%;
        // margin-top: 0.945%;
        margin-top: 0;
        padding: 0;

        .bot-details-container,
        .whats-app-details-container {
            overflow: auto;
            width: 350px;
            height: 100%;
            background: #F5F8FF;
            border-radius: 5px 5px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            // text-transform: capitalize;
        }

    }

    .embed-modal {
        display: flex;
        justify-content: space-between;

        input {
            width: 80%;
        }
    }
}
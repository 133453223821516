.invite-form-container {
    width: 580px !important;


    .invite-form {
        width: 100%;
        padding: 30px 40px;

        h4 {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin-bottom: 20px;
        }

        .ant-form-item {
            margin: 0 0 20px;
        }

        .input-field {
            width: 100%;
            padding: 0 11px;
            // width: 396px;
            height: 42px;
            border-radius: 4px;
            opacity: 1;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            font-size: 14px;
            outline: none;

            &::placeholder {
                color: #B9BFCB;
            }

        }

        .select-field-item {
            width: 60%;

            @media(max-width: 769px) {
                width: 100%;
            }

            .ant-select-selector {
                width: 100% !important;
                height: 42px !important;
                border-radius: 6px !important;
                display: flex;
                align-items: center;
                border: 0.6px solid #C7CDD6 !important;
                outline: none !important;
            }

            .ant-select-selection-item {
                line-height: 42px !important; // Aligns text vertically
            }
        }

        .action-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-btn {
                outline: none;
                border: none;
                box-shadow: none;
                padding: 0;
                height: fit-content;
                width: fit-content;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #0F43BD;
                }
            }
        }
    }
}

.user-management-settings-container {
    min-height: 100%;
    width: 100%;
    // // width: 850px;

    // @media(max-width: 1400px) {
    //     width: 750px;

    //     @media(max-width: 769px) {
    //         @media (max-width: 576px) {}
    //     }
    // }

    .search-input {
        width: 65%;
        height: 8.5%;
        // padding-left: 2%;
        border-radius: 3px;
        border: 1px solid var(--border-big-button, #A4BCFD);
        background: var(--bg-white, #FFF);
        color: var(--text-placeholder, #98A2B3);

        .search-icon {
            margin-right: 5px;
        }

        input {
            font-size: 0.9rem;
            font-weight: 400;

            @media(max-width: 1400px) {
                font-size: 0.75rem;

                @media(max-width: 769px) {
                    // width: 100% !important;
                    font-size: 0.9rem;
                }
            }
        }

    }

    .extra {
        height: 30px;
    }


    .settings-header-title {
        height: 46px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #272C35;
        }
    }

    .settings-form-item {
        min-height: 102px;
        padding: 30px 0px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        .title {
            flex-grow: 1;
            width: 50%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            position: relative;

            .tik {
                z-index: 999;
                position: absolute;
                right: 115px;
                cursor: pointer;
            }

            input,
            .disable-input,
            .logo-container {
                resize: none;
                width: 320px;
                height: 42px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

            }

            .disable-input {
                background: #F0F2F4;
            }

            .logo-container {
                padding: 0 15px 0 0;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                }

                .action-container {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    div,
                    label {
                        cursor: pointer;
                    }
                }
            }
        }

    }
}
.unavailable-view-container {
    height: 100%;
    width: 100%;
    padding: 0 185px;
    display: flex;
    justify-content: center;
    align-items: center;

    .unavailable-preview {
        background: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        p {
            color: #4E5869;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }

        img {
            height: 64px;
            width: 64px;
        }
    }
}

.image-view-container {
    position: relative;
    max-height: calc(100vh - 56px);
    max-width: calc(100vw - 350px);
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .zoom-controls {
        position: sticky;
        top: 100px;
        right: 100px;
        z-index: 999;
        display: flex;
        gap: 15px;

        button {
            background: #fff;
            border: 1px solid #000;
            border-radius: 50%;
            padding: 10px;
            font-size: 16px;
            cursor: pointer;
        }
    }


    img {
        margin: auto;
        height: auto;
        width: auto;
    }
}

.video-view-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        height: auto;
        width: auto;
        max-width: 100%;
    }
}

.pdf-view-container {
    height: 100%;
    width: 100%;
    // padding: 0 128px;
    display: flex;
    justify-content: center;
    align-items: center;


    .pdf-view {
        height: 100%;
        width: 100%;
        // width: 994px;
        max-width: 100%;

        @media(max-width: 1400px) {
            @media(max-width: 1200px) {
                padding: 0;
                width: 100%;
            }
        }
    }
}
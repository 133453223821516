.count-down-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .countdown {
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: #272C35;
    }
}
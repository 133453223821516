// .user-management-table {
//     width: 100%;
//     margin: 20px auto;



//     // .ant-table-header .ant-table-thead tr th {
//     //     background: transparent !important;
//     //     color: #272C35 !important;
//     //     font-weight: 500 !important;
//     //     font-size: 14px !important;
//     // }


//     // .ant-table-header .ant-table-thead {
//     //     background: #fff;
//     //     color: #272C35;
//     // }

//     // .ant-table-row {
//     //     // display: block;
//     //     width: 100% !important;
//     //     height: 64px !important;
//     //     margin: 0 0 !important;
//     //     color: #272C35 !important;

//     //     .ant-table-cell {
//     //         padding: 0 !important;
//     //         // border-top: 1px solid #C7CDD6 !important;
//     //         border-bottom: 0.5px solid #C7CDD6 !important;
//     //     }
//     // }

//     // .ant-table-thead {
//     //     // display: block;
//     //     width: 100% !important;
//     //     height: 44px !important;

//     //     .ant-table-cell {
//     //         padding: 0 !important;
//     //         // height: 44px !important;

//     //         &::before {
//     //             content: none !important;
//     //         }
//     //     }
//     // }


//     // .ant-table table {
//     //     // .ant-table-body {
//     //     //     height: 50vh;
//     //     //     overflow-y: auto;
//     //     // }

//     //     font-size: 14px;

//     //     @media(max-width: 1200px) {
//     //         // font-size: 0.75rem;

//     //         @media(max-width: 769px) {
//     //             font-size: 0.9rem;
//     //         }
//     //     }

//     //     margin: 0;

//     //     .ant-table-measure-row {
//     //         height: 0 !important;
//     //         overflow: hidden !important;

//     //         .ant-table-tbody>tr>td {
//     //             padding: 0 0 0 0 !important;
//     //         }
//     //     }
//     // }

//     // .rowClassName1 td {
//     //     border-left: none !important;
//     //     border-top-left-radius: 0;
//     //     border-bottom-left-radius: 0;
//     // }

//     // .rowClassName1 td {
//     //     border-right: none !important;
//     //     border-top-right-radius: 0;
//     //     border-bottom-right-radius: 0;
//     // }

//     // th {
//     //     // padding: 0 0 5px 0;
//     //     border: none;
//     //     vertical-align: middle;

//     //     div {
//     //         margin: 0;
//     //         width: 100%;
//     //         // border-right: 2px solid #00000011;

//     //         .check-btn {
//     //             // margin-top: 2px;
//     //             cursor: pointer;
//     //         }
//     //     }

//     //     .last-header-title {
//     //         border-right: none !important;
//     //     }
//     // }

//     // .table-header-title {
//     //     font-size: 15px;
//     //     font-weight: 600;
//     //     color: #000;
//     //     // margin-bottom: 10px;
//     // }

//     // .table-body-title {
//     //     font-size: 14px;
//     //     font-weight: 400;
//     //     color: #000;
//     //     // margin-bottom: 10px;
//     // }

//     // td {
//     //     // padding: 0 0 5px 0;
//     //     vertical-align: middle;

//     //     div {
//     //         margin: 0 !important;
//     //         width: 100%;
//     //         // border-right: 1px solid #fff;

//     //         .check-btn {
//     //             margin-top: 0 !important;
//     //             cursor: pointer;
//     //         }
//     //     }

//     //     .last-body-title {
//     //         border-right: none !important;
//     //     }
//     // }

//     .super-admin {
//         text-align: center;
//         border-radius: 3px;
//         border: 1px solid #AAB2C0 !important;
//         padding: 4% 2%;
//         color: #17173A;
//         font-weight: 400;
//         font-size: 14px;

//         @media(max-width: 1400px) {
//             font-size: 14px;

//             @media(max-width: 769px) {
//                 // font-size: 0.8rem;
//             }
//         }
//     }

// }

.user-management-table-container {
    width: 100%;
    display: block;
    overflow-x: hidden; // Adds horizontal scrolling if the table overflows
    margin: 20px auto;
}

.user-management-table {
    width: 100%;
    border-collapse: collapse; // Ensures no unnecessary gaps between table cells

    thead {
        th {
            padding: 12px 15px;
            text-align: left;
            color: #04112F;
            letter-spacing: 0.14px;
            font-size: 14px;
            font-weight: 500;

            &.text-center {
                text-align: center;
            }

            &:nth-child(2) {
                @media(max-width: 1024px) {
                    display: none;
                }
            }
        }

        border-bottom: 0.6px solid #C7CDD6;
    }

    tbody {
        tr {
            border-bottom: 0.6px solid #C7CDD6;
        }

        td {
            padding: 12px 15px;
            color: #04112F;
            letter-spacing: 0.14px;
            font-size: 14px;
            vertical-align: middle;

            &.text-center {
                text-align: center;
            }

            &:nth-child(2) {
                @media(max-width: 1024px) {
                    display: none;
                }
            }
        }

        .super-admin {
            text-align: center;
            border: 0.6px solid #D6DAE1;
            border-radius: 5px;
            padding: 8px 12px;
            color: #17173a;
            font-weight: 400;
            font-size: 14px;
            background: #fff;
        }
    }

    img {
        cursor: pointer;
        transition: transform 0.2s;

        &:hover {
            transform: scale(1.1);
        }
    }

    .switch {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow-icon {
        width: 10px;
    }

    @media (max-width: 1024px) {

        tbody,
        thead {

            th,
            td {
                padding: 10px;
                font-size: 13px;
            }
        }
    }

    // @media (max-width: 768px) {
    //     .user-management-table {

    //         th,
    //         td {
    //             padding: 8px 5px;
    //             font-size: 12px;
    //         }

    //         .super-admin {
    //             font-size: 12px;
    //             padding: 8px 5px;
    //         }
    //     }
    // }
}

// Responsive Design
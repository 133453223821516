.account-settings-container {
    min-height: 100%;
    width: 100%;
    // width: 850px;

    // @media(max-width: 1400px) {
    //     width: 750px;

    //     @media(max-width: 1024px) {
    //         @media (max-width: 576px) {}
    //     }
    // }

    .extra {
        height: 30px;
    }

    .settings-header-title {
        height: 46px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #272C35;
        }
    }

    // .settings-form-item {
    //     min-height: 102px;
    //     padding: 30px 0px;
    //     width: 100%;
    //     /* Smart layout */
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     box-sizing: border-box;
    //     border-bottom: 0.6px solid #C7CDD6;

    //     .title {
    //         flex-grow: 1;
    //         width: 50%;
    //         font-size: 14px;
    //         font-weight: 400;
    //         line-height: 20px;
    //         letter-spacing: 0.01em;
    //         color: #272C35;
    //     }

    //     .action-body {
    //         min-height: 100%;
    //         // border-left: 1px solid red;
    //         flex-grow: 1;
    //         width: 50%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: flex-start;

    //         position: relative;

    //         // .tik {
    //         //     z-index: 999;
    //         //     position: absolute;
    //         //     right: 115px;
    //         //     cursor: pointer;
    //         // }

    //         .input-container {
    //             min-width: 320px;

    //             @media(max-width: 1200px) {
    //                 min-width: 100%;

    //                 @media(max-width: 1024px) {
    //                     width: 320px;
    //                 }
    //             }

    //             height: fit-content;
    //             position: relative;

    //             .tik {
    //                 z-index: 999;
    //                 position: absolute;
    //                 cursor: pointer;
    //                 right: 15px;
    //                 top: 13px;
    //             }
    //         }

    //         input,
    //         .disable-input,
    //         .logo-container {
    //             resize: none;
    //             width: 320px;
    //             height: 42px;
    //             border-radius: 6px;
    //             padding: 11px 15px;
    //             box-sizing: border-box;
    //             border: 0.6px solid #C7CDD6;
    //             outline: none;
    //             font-size: 14px;
    //             font-weight: normal;
    //             line-height: 20px;
    //             letter-spacing: 0.01em;

    //             color: #272C35;

    //             &::placeholder {
    //                 color: #B9BFCB;
    //             }

    //         }

    //         .disable-input {
    //             background: #F0F2F4;
    //         }

    //         .logo-container {
    //             padding: 0 15px 0 0;
    //             border: none;
    //             display: flex;
    //             justify-content: space-between;
    //             align-items: center;

    //             .logo {
    //                 width: 42px;
    //                 height: 42px;
    //                 border-radius: 50%;
    //             }

    //             .action-container {
    //                 flex-grow: 1;
    //                 display: flex;
    //                 justify-content: flex-end;
    //                 gap: 10px;

    //                 div,
    //                 label {
    //                     cursor: pointer;
    //                 }
    //             }
    //         }
    //     }

    // }

    .settings-form-item {
        min-height: 102px;
        padding: 30px 0;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        // @media(max-width: 1200px) {
        //     align-items: flex-start;
        //     flex-direction: column;
        //     gap: 20px;
        // }

        .title {
            flex-grow: 1;
            min-width: 50%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            min-width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            position: relative;


            .input-container {
                min-width: 320px;

                // @media(max-width: 1200px) {
                //     min-width: 100%;

                //     @media(max-width: 1024px) {
                //         width: 320px;
                //     }
                // }

                height: fit-content;
                position: relative;

                .tik {
                    z-index: 999;
                    position: absolute;
                    cursor: pointer;
                    right: 15px;
                    top: 13px;
                }
            }

            input,
            .disable-input,
            .logo-container {
                resize: none;
                width: 100%;
                height: 42px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

            }

            .disable-input {
                background: #F0F2F4;
            }

            .logo-container {
                padding: 0 15px 0 0;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                }

                .action-container {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    div,
                    label {
                        cursor: pointer;
                    }
                }
            }
        }

    }

    .settings-form-textarea-item {
        min-height: 102px;
        padding: 30px 0px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        // @media(max-width: 1200px) {
        //     align-items: flex-start;
        //     flex-direction: column;
        //     gap: 20px;
        // }

        .title {
            flex-grow: 1;
            width: 50%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .input-container {
                min-width: 320px;

                // @media(max-width: 1200px) {
                //     min-width: 100%;

                //     @media(max-width: 1024px) {
                //         width: 320px;
                //     }
                // }

                height: fit-content;
                position: relative;

                .tik {
                    z-index: 999;
                    position: absolute;
                    cursor: pointer;
                    right: 15px;
                    top: 13px;
                }
            }

            textarea,
            .disable-input,
            .logo-container {
                resize: none;
                width: 320px;
                height: 96px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

            }

            .disable-input {
                background: #F0F2F4;
            }

            .logo-container {
                padding: 0 15px 0 0;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                }

                .action-container {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    div,
                    label {
                        cursor: pointer;
                    }
                }
            }


        }

    }

    .settings-form-template-item {
        min-height: 102px;
        padding: 30px 0px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        // @media(max-width: 1200px){
        //     align-items: flex-start;
        //     flex-direction: column;
        //     gap: 20px;
        // }

        .title {
            flex-grow: 1;
            width: 50%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .input-container {
                min-width: 320px;

                // @media(max-width: 1200px) {
                //     min-width: 100%;

                //     @media(max-width: 1024px) {
                //         width: 320px;
                //     }
                // }

                height: fit-content;
                position: relative;

                .tik {
                    z-index: 999;
                    position: absolute;
                    cursor: pointer;
                    right: 15px;
                    top: 13px;
                }
            }

            .disable-input,
            .logo-container {
                resize: none;
                width: 320px;
                height: 200px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

            }

            .disable-input {
                background: #F0F2F4;
            }

            .logo-container {
                padding: 0 15px 0 0;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .template-logo-container {
                    border-radius: 10px;
                    opacity: 1;
                    box-sizing: border-box;
                    border: 0.6px solid #007AFF;
                    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.05);

                    display: flex;
                    flex-direction: column;
                    overflow: hidden;

                    .logo {
                        width: 178px;
                        height: 120px;
                    }

                    .title-container {
                        padding: 15px;
                    }
                }

                .action-container {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;

                    div,
                    label {
                        cursor: pointer;
                    }
                }
            }
        }

    }
}
.create-folder-form {
    width: 480px;
    text-align: left;
    padding: 30px 40px;

    h3 {
        color: var(--Title, #04112F);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 8px;
        border-bottom: 0.6px solid var(--Normal, #D6DAE1);
        margin-bottom: 20px;
    }

    .radio-action-container {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
        padding-bottom: 15px;

        .radio-action {
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0em;
            color: #272C35;
            // border-bottom: 0.5px solid #C7CDD6;

        }

    }

    .requirement-description {
        color: var(--Primary, #272C35);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 20px 0;
    }

    .form-item {
        margin-bottom: 20px;

        input {
            width: 100%;
            padding: 10px 15px;
            // width: 396px;
            height: 48px;
            border-radius: 4px;
            opacity: 1;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            color: var(--Title, #04112F);
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            &::placeholder {
                color: #B9BFCB;
            }
        }

    }

    .action-buttons {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .text-button {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            color: var(--Title, #04112F);
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                // color: #F79009;
                color: var(--Active, #0F43BD);
            }
        }
    }

}

.view-container {
    text-align: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    height: 100vh;
    width: 100%;
    overflow: hidden; // Prevent unexpected scrollbars

    .content-view-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .content-view-header {
            z-index: 9;
            background: #ffffff;
            border-bottom: 0.6px solid #d6dae1;
            flex-shrink: 1;
            min-height: 56px;
            width: 100%;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .file-name {
                color: #17173a;
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
            }

            .action-button-container {
                display: flex;
                gap: 20px;

                button {
                    outline: none;
                    border: none;
                    background: transparent;
                }

                .action-text-btn {
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 20px;
                    border: 0.4px solid var(--Border-Normal-Secondary, #aab2c0);
                    border-radius: 5px;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        border-color: var(--Border-Normal-Secondary, #125fe4);
                    }

                    color: var(--Content-Secondary, #272c35);
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .content-view-body {
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .view-body {
                flex-grow: 1;
                height: 100%;
                background: #f5f8ff;

                // padding: 0 10px; // Add padding for better content spacing
                .unavailable-viewer-container {
                    height: 100%;
                    width: 100%;
                    padding: 0 185px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .unavailable-preview {
                        background: #FFFFFF;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 30px;

                        p {
                            color: #4E5869;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px;
                        }

                        img {
                            height: 64px;
                            width: 64px;
                        }
                    }
                }

                .image-viewer-container {
                    // max-height: 100vh;
                    max-height: calc(100vh - 0px);
                    max-width: calc(100vw - 350px);
                    height: 100%;
                    width: 100%;
                    overflow: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        margin: auto;
                        height: auto;
                        width: auto;
                    }
                }

                .video-viewer-container {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    video {
                        height: auto;
                        width: auto;
                        max-width: 100%;
                    }
                }

                .pdf-viewer-container {
                    height: 100%;
                    width: 100%;
                    // padding: 0 128px;
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    .pdf-view {
                        height: 100%;
                        width: 100%;
                        // width: 994px;
                        max-width: 100%;

                        @media(max-width: 1400px) {
                            @media(max-width: 1200px) {
                                padding: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .view-details-container {
                background: var(--Color-Gray-50, #f9fafb);
                border-left: 0.6px solid var(--Border-Normal, #c7cdd6);
                width: 350px; // Fixed width for details container
                min-width: 350px; // Fixed width for details container
                height: 100%;
                flex-shrink: 1;

                .view-details {
                    width: 100%;
                    max-height: calc(100vh - 56px); // Adjust to fit within viewport
                    overflow-y: auto;
                }
            }
        }
    }

    .loading-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 128px;
    }
}

// Media query for responsiveness
// @media (max-width: 768px) {
//     .content-view-body {
//         flex-direction: column; // Stack content vertically for small screens

//         .view-details-container {
//             width: 100%; // Full width on smaller screens
//             border-left: none; // Remove border
//         }
//     }
// }


.folder-properties-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    .header-title-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(---White, #FFFFFF);
        border-bottom: 0.5px solid #AAB2C0;
        padding: 0 20px 0 15px;
        height: 52px;

        .title {
            color: #000000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
        }

        img {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 0.5;
            }
        }

    }

    .properties-form {
        flex-grow: 1;
        background: #F9FAFB;
        width: 100%;
        padding: 15px 30px;
        // padding-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            input,
            textarea {
                outline: none;
                color: #272C35;
                background: #FFFFFF;
                border: 0.6px solid #AAB2C0;
                border-radius: 5px;
                padding: 17px 20px;
                height: 46px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.14px;

                &::placeholder {
                    color: var(--Placeholder, #98A2B3);
                }
            }

            textarea {
                background: transparent;
                resize: none;
                height: 114px;
                line-height: 22px;
                letter-spacing: 0.14px;
            }

            .keyword-textarea {
                height: 78px;
            }

            .disabled-input {
                background: #F0F2F4;
            }
        }

        .action-buttons {
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            button {
                background: transparent;
                outline: none;
                border: none;
                color: #272C35;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.14px;
                transition: all 0.3s ease-in-out;

                &:hover {
                    opacity: 0.7;
                }
            }

        }
    }

}

.content-section-container {
    text-align: left;
    height: 100%;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 0;
    padding-left: 10px;

    .content-section-management {
        flex-grow: 1;
        background: #F5F8FF;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;

        @media(max-width: 769px) {
            margin-top: 0;
        }

        .search-section,
        .bread-crumb-section,
        .recent-contents-section,
        .actionable-contents-section {
            width: 1100px;
            margin: 0 auto;

            @media(max-width: 1400px) {
                width: 900px;

                @media(max-width: 1200px) {
                    width: 750px;

                    @media(max-width: 992px) {
                        // padding: 2%;
                        width: 650px;

                        @media(max-width: 769px) {
                            width: 526px;

                            @media(max-width: 576px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        // search and modal actions
        .search-section {
            z-index: 99;
            background: #F5F8FF;
            padding-top: 15px;
            padding-bottom: 10px;
            position: sticky;
            top: 0;
            display: flex;
            flex-direction: row;
            // justify-content: space-around;
            justify-content: flex-end;
            align-items: center;
            gap: 150px;

            // @media(max-width: 769px) {
            //     flex-direction: column;
            // }

            .search-wrap {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                // gap: 5%;
                // width: 500px;
                // width: 50%;

                .search-container {
                    width: 553px;
                    height: 42px;
                    position: relative;
                    z-index: 1;

                    @media(max-width: 1400px) {
                        // width: 470px;

                        @media(max-width: 1200px) {
                            // height: 36px;
                            width: 354px;

                            @media(max-width: 1024px) {
                                // height: 42px;
                                width: 300px;

                                @media(max-width: 992px) {
                                    // height: 42px;
                                    // width: 354px;
                                }
                            }
                        }
                    }

                    .search-icon {
                        position: absolute;
                        left: 10px;
                        top: 27%;
                        height: auto;
                        // width: 10px;
                        width: 19px;
                        height: auto;
                        cursor: pointer;
                    }

                    input {
                        width: 100%;
                        // height: 5vh;
                        // height: 44px;
                        height: 100%;
                        background: #FFFFFF;
                        border: none;
                        // border: 1.3px solid #D3E0FF;
                        border-radius: 5px;
                        box-sizing: border-box;
                        // padding: 0 0 0 4.5%;
                        padding: 0 0 0 35px;
                        outline: none;


                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: 0.01em;

                        // @media(max-width: 1400px) {
                        //     font-size: 0.75rem;
                        //     line-height: 0.75rem;

                        //     @media(max-width: 1200px) {}
                        // }

                        color: #98A2B3;
                    }

                    input::placeholder {
                        color: #98A2B3;
                    }
                }


            }

            .content-buttons {
                // width: fit-content;
                min-width: 126px;

                @media(max-width: 576px) {
                    // margin-top: 5%;
                    width: 100%;
                    // display: flex;
                    // flex-direction: row;
                    // justify-content: space-around;
                    // align-items: center;
                }

                .content-button-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 15px;
                }
            }

            .bulk-delete-icon {
                height: auto;
                width: auto;
                cursor: pointer;

                @media(max-width: 769px) {
                    height: auto;
                }

            }

            .content-btn {
                cursor: pointer;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 0.875rem;
                padding: 10px 25px;

                // @media(max-width: 1600px) {
                //     font-size: 0.75rem;
                //     line-height: 0.75rem;
                // }

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5%;

                // letter-spacing: 0.01em;
                background: #D8E3FF;
                white-space: nowrap;
                color: #17173A;
                border: 1px solid transparent;
                transition: all 0.5s ease-in-out;

                p {
                    margin-right: 5px;
                }

                &:hover,
                &:focus,
                &:active {
                    background: #EEF4FF;
                    /* Button / Active Button Outline */

                    border: 1px solid #175CD3;
                    // border-radius: 13px;
                }

                @media (max-width: 769px) {
                    // height: 5vh;
                    // padding: 2% 5%;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 0.650rem;
                    line-height: 0.650rem;
                    letter-spacing: 0.01em;

                    color: #000000;
                }
            }

            // .secondary,
            .create-folder-btn {
                // height: 100%;
                cursor: pointer;
                padding: 0 25px;
                // font-size: 0.875rem;
                width: fit-content;
                height: 42px;

                @media(max-width: 1400px) {
                    @media(max-width: 1200px) {
                        height: 36px;

                        @media(max-width: 1024px) {
                            height: 42px;

                            @media(max-width: 769px) {
                                padding: 0 30px;
                            }
                        }
                    }
                }

                // @media (max-width: 1025px) {
                //     width: fit-content;
                //     color: #000000;
                // }
            }

            // .create-folder-btn {
            //     padding: 2% 20%;

            //     @media(max-width: 769px) {
            //         padding: 2% 5%;
            //     }
            // }
        }

        // bread-crumb action
        .bread-crumb-section {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            gap: 10px;

            .bread-crumb-title {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                color: var(--Title, #04112F);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
            }
        }

        // recent contents
        .recent-contents-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .recent-title {
                color: var(--Secondary, #4E5869);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
            }

            .recent-body {
                width: 100%;
                display: grid;
                gap: 24px; // Space between items
                grid-auto-flow: column; // Makes grid flow horizontally
                grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));


                overflow-x: auto; // Enable horizontal scrolling
                // padding-bottom: 10px; // Padding to avoid scroll bar clipping
                scroll-behavior: smooth; // Smooth scrolling

                @media(max-width: 1400px) {
                    grid-template-columns: none;
                    grid-auto-columns: 160px; // Fixed column width for each item
                }

                .recent-content-container {
                    background: var(--Primary, #FFFFFF);
                    border: 0.5px solid #D6DAE1;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;

                    .image-container {
                        height: 95px;
                        width: 100%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 50px;
                            height: auto;
                        }
                    }

                    .recent-content-title {
                        width: 100%;
                        display: flex;
                        overflow: hidden;
                        padding: 10px;
                        color: #000000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                        letter-spacing: 0.12px;
                        white-space: nowrap;
                        /* Prevent line breaks */
                        text-overflow: ellipsis;
                        /* Optional: Adds "..." if text overflows */
                    }
                }
            }
        }

        // ==== all content sections parent & last-child ====
        .no-data-found {
            width: 100%;
            padding: 20px 15px;
            height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--Secondary, #4E5869);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }

        .actionable-contents-section {
            overflow-x: hidden;
            margin-top: 30px;

            .parent-content-table {
                width: 1100px;
                height: 100%;
                border-radius: 8px 8px 0 0;
                border: 0.6px solid #D6DAE1;
                border-radius: 5px 5px 0px 0px;
                overflow: hidden;

                @media(max-width: 576px) {
                    height: fit-content;
                    padding-bottom: 20px;
                }

                .table-responsive {
                    max-height: 100%;
                    overflow: hidden;
                    // overflow: auto;

                    @media(max-width: 576px) {
                        height: fit-content;
                        overflow: visible;
                    }

                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin: 0;

                        thead {
                            // animation: AnimationFadeIn 1.5s ease-in-out forwards;
                            position: sticky;
                            top: 0;
                            z-index: 1;

                            @media(max-width: 576px) {
                                position: relative;
                                display: none;
                            }

                            tr {
                                height: 56px;
                                border-bottom: 0.5px solid #C7CDD6; // Add a bottom border to each row

                                th {
                                    vertical-align: middle;
                                    background: #F9FAFB;
                                    text-align: center;
                                    font-size: 14px;
                                    line-height: 14px;
                                    font-weight: 500;
                                    letter-spacing: 0.01em;
                                    color: #17173A;

                                    // padding: 20px 0;
                                    @media(max-width: 340px) {
                                        font-size: 13px;
                                        line-height: 13px;
                                    }

                                    &:first-child {
                                        text-align: left;
                                        padding-left: 20px;

                                        @media(max-width: 340px) {
                                            padding-left: 5px;
                                        }
                                    }

                                    // &:nth-last-child(2) {
                                    //     @media(max-width: 576px) {
                                    //         display: none;
                                    //     }
                                    // }

                                    &:last-child {
                                        text-align: right;
                                        padding-right: 20px;

                                        @media(max-width: 340px) {
                                            padding-left: 5px;
                                        }
                                    }
                                }

                                .action-date-icon-container {
                                    text-align: right;
                                    padding-right: 20px;
                                }
                            }
                        }

                        tbody {
                            tr {
                                // animation: AnimationFadeIn 1.5s ease-in-out forwards;
                                height: 56px; // Set the height of each row
                                border-bottom: 0.5px solid #C7CDD6; // Add a bottom border to each row

                                td {

                                    cursor: pointer;
                                    padding: 0;
                                    vertical-align: middle; // Center align the content vertically
                                    text-align: center;
                                    font-size: 14px;
                                    line-height: 14px;
                                    font-weight: 400;
                                    letter-spacing: 0.01em;
                                    color: #272C35;

                                    @media(max-width: 340px) {
                                        font-size: 13px;
                                        line-height: 13px;
                                    }


                                    .title-icon-container {
                                        height: 100%;
                                        white-space: nowrap;
                                        /* Prevents the text from wrapping */
                                        overflow: hidden;
                                        /* Hides overflowing content */
                                        text-overflow: ellipsis;
                                        width: 300px;
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;

                                        img {
                                            width: 24px;
                                            height: auto;
                                        }
                                    }

                                    .action-date-container {
                                        height: 100%;
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                        gap: 10px;
                                    }


                                    &:first-child {
                                        // white-space: nowrap;
                                        /* Prevents the text from wrapping */
                                        /* Hides overflowing content */
                                        // text-overflow: ellipsis;
                                        // width: 450px;
                                        text-align: left;
                                        width: 330px;
                                        padding-left: 20px;
                                        padding-right: 0;

                                        @media(max-width: 340px) {
                                            padding-left: 5px;
                                        }

                                    }

                                    // &:nth-last-child(2) {
                                    //     @media(max-width: 576px) {
                                    //         display: none;
                                    //     }
                                    // }

                                    // border-right: 1px solid red;

                                    &:last-child {
                                        width: 100px;
                                        padding-right: 20px;
                                        text-align: right; // Right align the last column

                                        @media(max-width: 340px) {
                                            padding-left: 5px;
                                        }

                                        img {
                                            border-radius: 50%;
                                            padding: 0 5px 0 20px;
                                        }

                                    }

                                    .nothing-to-show {
                                        // border: 1px solid blue;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        height: 100%; // Adjust height as needed
                                        text-align: center;

                                        img {
                                            width: 260px;
                                            height: auto;
                                            margin-bottom: 15px;
                                        }

                                        p {
                                            color: #272C35;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 26px;
                                        }
                                    }
                                }

                                .action-date-icon-container {
                                    width: 300px;
                                    height: 100%;
                                    text-align: right;
                                }

                                .size {
                                    width: 150px;
                                }

                                // img {
                                //     cursor: pointer;
                                //     transition: transform 0.3s ease-in-out;

                                //     &:hover {
                                //         transform: scale(1.1); // Scale up the delete icon on hover
                                //     }
                                // }
                            }
                        }
                    }
                }
            }
        }


        // .mobile-search-container {
        //     display: none;

        //     @media(max-width: 576px) {
        //         width: 100%;
        //         display: block;
        //     }

        //     .file-back-path {
        //         margin-top: 10px;
        //         // font-family: 'Inter';
        //         // font-style: normal;
        //         // cursor: pointer;
        //         width: 100%;
        //         display: flex;
        //         justify-content: flex-start;
        //         align-items: center;
        //         // flex-direction: row;
        //         // gap: 5%;
        //         // gap: 5px;

        //         .content-path-back {
        //             height: 20px;
        //             width: auto;
        //         }

        //         .content-path-icon {
        //             font-size: 0.875rem;
        //             line-height: 0.875rem;
        //             height: 1.5vh;
        //             width: auto;
        //         }

        //         .content-path-text {

        //             font-weight: 400;
        //             font-size: 0.875rem;
        //             line-height: 0.875rem;

        //             @media(max-width: 1600px) {
        //                 font-size: 0.75rem;
        //                 line-height: 0.75rem;
        //             }

        //             letter-spacing: 0.01em;
        //             color: #000000;
        //             cursor: pointer;
        //         }
        //     }
        // }

        .full-file-name {
            text-align: start;
            margin: 10px 0;
        }
    }

}

.move-copy-folder-form {
    width: 560px;
    text-align: left;
    padding: 30px 0;

    h3 {
        margin: 0 40px;
        color: var(--Title, #04112F);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 8px;
        border-bottom: 0.6px solid var(--Normal, #D6DAE1);
        margin-bottom: 20px;
    }


    .form-item {
        margin: 0 40px;
        margin-bottom: 20px;

        input {
            width: 100%;
            padding: 10px 15px;
            // width: 396px;
            height: 48px;
            border-radius: 4px;
            opacity: 1;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            color: var(--Title, #04112F);
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            &::placeholder {
                color: #B9BFCB;
            }
        }

    }

    .bread-crumb-container {
        margin: 0 40px;
        padding-bottom: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;

        .folder-title {
            display: flex;
            align-items: center;
            gap: 15px;
        }

    }

    .move-copy-folder-table {
        padding: 0 14px 0 40px;
        margin-right: 20px;
        min-height: 173px;
        max-height: 175px;
        overflow-y: auto;
        margin-bottom: 10px;

        .table-responsive {
            table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    display: none;
                }

                tbody {
                    tr {
                        border-top: 0.5px solid #C7CDD6; // Add a bottom border to each row

                        &:last-child {
                            border-bottom: 0.5px solid #C7CDD6;
                        }

                        td {
                            min-height: 57px; // Set the height of each row
                            padding: 0;
                            vertical-align: middle; // Center align the content vertically
                            text-align: left;
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 14px;
                            letter-spacing: 0.01em;

                            color: #272C35;

                            &:first-child {
                                text-align: left;
                                // width: 40%;
                                align-items: center;
                                display: flex;
                                gap: 10px;

                                img {
                                    height: 18px;
                                    width: 18px;
                                }
                            }

                            // &:nth-last-child(2) {
                            //     width: 50%;
                            // }

                            // border-right: 1px solid red;

                            &:last-child {

                                text-align: right; // Right align the last column (delete icon)

                                .image-icon {
                                    padding: 0;
                                    border-radius: 0;
                                    cursor: pointer;
                                    height: auto;
                                    width: auto;
                                }
                            }
                        }

                    }
                }
            }
        }


        &::-webkit-scrollbar-thumb {
            background: #A3A3A3 !important;
            // height: 10px !important;
            border-radius: 10px;
            cursor: pointer;
            min-height: 30px;
            /* Set your desired fixed height */
            max-height: 30px;
            /* Ensure it doesn't exceed the desired fixed height */
            background-clip: padding-box;
            /* Adjusts the background to stay within the thumb bounds */
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #fff;
            background-color: #fff !important;
            margin: 0 !important;
            // background-color: transparent ;
            // border-radius: 10px;
        }
    }

    .action-buttons {
        margin: 0 40px;
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .text-button {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            color: var(--Title, #04112F);
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #F79009;
                // color: var(--Active, #0F43BD);
            }
        }
    }

}

// ===== Share Modal Form ====
.share-content-table-form {
    width: 560px;
    padding: 30px 0;

    h3 {
        padding: 0 40px;
        color: var(--Title, #04112F);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .share-radio-actions {
        padding: 0 40px;
        display: flex;
        align-items: center;
        gap: 15px;
        padding-bottom: 15px;

        .radio-action {
            cursor: pointer;
            display: flex;
            gap: 10px;
            align-items: center;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0em;
            color: #272C35;
            // border-bottom: 0.5px solid #C7CDD6;

        }

    }

    .form-item {
        margin-bottom: 20px;
        padding: 0 40px;

        .label {
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;

            color: #4E5869;
            margin-bottom: 10px;
        }

        input {
            width: 100%;
            padding: 0 11px;
            // width: 396px;
            height: 42px;
            border-radius: 4px;
            opacity: 1;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            font-size: 14px;
            outline: none;

            &::placeholder {
                color: #B9BFCB;
            }
        }

    }

    .share-tabs {
        padding: 0 40px;
        display: flex;
        align-items: center;
        gap: 25px;
        padding-bottom: 15px;

        .share-tab-container,
        .share-tab-container-active {
            cursor: pointer;
            display: flex;
            gap: 15px;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #0F43BD;
            }

            // border-bottom: 0.5px solid #C7CDD6;
        }

        .share-tab-container-active {
            color: #0F43BD;

        }

    }

    .action-buttons {
        margin-top: 20px;
        padding: 0 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .text-button {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #F79009;
            }
        }
    }

    .people-teams-table {
        padding: 0 14px 0 40px;
        margin-right: 20px;
        min-height: 175px;
        max-height: 175px;
        overflow-y: auto;
        margin-bottom: 10px;

        .table-responsive {
            table {
                width: 100%;
                border-collapse: collapse;

                thead {
                    display: none;
                }

                tbody {
                    tr {
                        border-top: 0.5px solid #C7CDD6; // Add a bottom border to each row

                        &:last-child {
                            border-bottom: 0.5px solid #C7CDD6;
                        }

                        td {
                            min-height: 57px; // Set the height of each row
                            padding: 0;
                            vertical-align: middle; // Center align the content vertically
                            text-align: left;
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 14px;
                            letter-spacing: 0.01em;

                            color: #272C35;
                            padding-left: 0;

                            &:first-child {
                                text-align: left;
                                // width: 55%;
                                display: flex;
                                gap: 10px;
                                align-items: center;

                                img {
                                    border-radius: 50%;
                                    height: 18px;
                                    width: 18px;
                                }
                            }

                            &:nth-last-child(2) {
                                width: 20%;
                                overflow: hidden;
                            }

                            // border-right: 1px solid red;

                            &:last-child {
                                padding: 0;
                                text-align: right; // Right align the last column (delete icon)

                                img {
                                    cursor: pointer;
                                    // transition: transform 0.3s ease-in-out;

                                    // &:hover {
                                    //     transform: scale(1.1); // Scale up the delete icon on hover
                                    // }
                                }
                            }
                        }

                    }
                }
            }
        }


        &::-webkit-scrollbar-thumb {
            background: #A3A3A3 !important;
            // height: 10px !important;
            border-radius: 10px;
            cursor: pointer;
            min-height: 30px;
            /* Set your desired fixed height */
            max-height: 30px;
            /* Ensure it doesn't exceed the desired fixed height */
            background-clip: padding-box;
            /* Adjusts the background to stay within the thumb bounds */
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #fff;
            background-color: #fff !important;
            margin: 0 !important;
            // background-color: transparent ;
            // border-radius: 10px;
        }
    }

}


// .share-modal-form-wrap {
//     width: 560px !important;

//     @media(max-width: 576px) {
//         width: 95% !important;
//     }

// }

// .loader-container {
//     margin: 2.5% auto;

//     .loader-title {
//         font-family: 'Inter';
//         font-style: normal;
//         font-weight: 600;
//         font-size: 1.2rem !important;
//         // line-height: 1.5rem;
//         letter-spacing: 0.01em;
//         color: #000000;

//         @media(max-width: 769px) {
//             font-size: 1.25rem !important;
//             line-height: 1.25rem;
//         }
//     }

//     .loader-body {
//         background: #D8E3FF;
//         padding: 6% 2% 4% 2%;
//         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//         margin: 5% auto 6% auto;
//         border-left: 5px solid #1570EF;
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
// }

// .content-button-dropdown {
//     background: #FFFFFF;
//     box-shadow: 0.6px 0.6px 12px 4px rgba(23, 23, 58, 0.1);
//     border-radius: 5px;
//     text-align: start;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 10px;
//     width: 160px;
//     z-index: 999;

//     p,
//     a,
//     label {
//         padding: 4%;
//         margin: 0;
//         width: 100%;
//         text-decoration: none;
//         color: #101828;
//         font-size: 0.75rem;
//         cursor: pointer;
//         transition: all 0.5s ease-in-out;

//         &:hover,
//         &:focus,
//         &:active {
//             text-decoration: none;
//             color: #2c3039;
//             background: #D8E3FF;
//         }
//     }
// }
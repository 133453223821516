@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes leftToRight {
    0% {
        opacity: 0;
        transform: translateX(0);
    }

    100% {
        opacity: 1;
        transform: translateX(1);
    }
}

.custom-settings {
    text-align: start;
    height: 100%;

    .custom-settings-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        position: relative;

        .settings-menu-bar,
        .setting-animation-menu-bar {
            height: 100%;
            min-width: 230px;
            max-width: 230px;
            padding: 15px;
            border-right: 0.5px solid #AAB2C0;

            // @media(max-width: 1200px) {
            //     min-width: 200px;

            //     @media(max-width: 1200px) {
            //         min-width: 180px;

            //         @media(max-width: 769px) {
            //             min-width: fit-content;

            //             @media (max-width: 576px) {
            //                 padding: 15px 5px;
            //                 // display: none;

            //                 @media (max-width: 350px) {
            //                     padding: 0;
            //                 }
            //             }
            //         }
            //     }
            // }
        }

        .setting-animation-menu-bar {
            animation: leftToRight 1s ease-in-out forwards;
        }

        .settings-body {
            height: 100%;
            width: 100%;
            flex-grow: 1;
            background: #F5F8FF;
            display: flex;
            justify-content: center;

            // @media (max-width: 576px) {
            //     justify-content: flex-start;

            // }

            .settings-body-container,
            .settings-mobile-body-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 15px;
                justify-content: flex-start;
                align-items: center;
                // padding: 15px 0;
                width: 950px;
                padding-top: 15px;

                @media(max-width: 1400px) {
                    // width: 850px;

                    // @media(max-width: 1200px) {
                    //     width: 750px;

                    //     @media(max-width: 1024px) {
                    //         align-items: flex-start;
                    //         width: 550px;

                    //         @media(max-width: 769px) {
                    //             width: 450px;

                    //             @media (max-width: 576px) {
                    //                 width: 100%;
                    //                 padding: 10px;

                    //                 @media (max-width: 420px) {
                    //                     padding: 10px 5px;

                    //                     @media (max-width: 350px) {
                    //                         padding: 5px;
                    //                     }
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }

                .button-container {
                    width: 100%;

                    // @media (max-width: 576px) {
                    //     padding: 20px 10px;
                    //     width: 85%;

                    //     @media (max-width: 420px) {
                    //         padding: 10px;

                    //         @media (max-width: 350px) {
                    //             padding: 5px;
                    //         }
                    //     }
                    // }

                    display: flex;
                    justify-content: flex-end;

                    // @media(max-width: 576px) {
                    //     justify-content: space-between;
                    // }

                    .mobile-setting-nav-bar {
                        display: none;

                        // @media(max-width: 576px) {
                        //     display: block;
                        // }
                    }


                    /* Start with invisible */
                    // opacity: 0;
                    // animation: fadeIn 1s ease-in-out forwards;
                    /* Fade in over 1s */

                    button {
                        height: 42px;
                        border-radius: 6px;
                        opacity: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        padding: 0 14px;
                        background: #1354EC;
                        border: 0.5px solid #0F43BD;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            opacity: 0.8;
                        }

                        // font
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 20px;
                        letter-spacing: 0.01em;
                        color: #FFFFFF;
                    }
                }

                .body-container {
                    flex-grow: 1;
                    height: 100%;
                    width: 100%;
                    overflow: scroll;
                    display: flex;
                    justify-content: center;
                    background: #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 8px 8px 0px 0px;
                    border: 0.5px solid #C7CDD6;
                    opacity: 0;
                    /* Start with invisible */
                    animation: fadeIn 1s ease-in-out forwards;
                    /* Fade in over 1s */
                    // padding: 30px 0;

                    padding: 30px 50px 30px 45px;

                    @media(max-width: 769px) {
                        padding: 30px;

                        @media (max-width: 576px) {
                            padding: 20px 10px;
                            // width: 85%;

                            @media (max-width: 420px) {
                                padding: 10px;

                                @media (max-width: 350px) {
                                    padding: 5px;
                                }
                            }
                        }
                    }

                }

            }

            .settings-mobile-body-container {
                @media (max-width: 576px) {
                    width: 86%;
                }
            }
        }
    }
}
// ==== Loader Integration Settings ======
.loader-integration-settings-container {
    width: 100%;
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 15px;

    @media(max-width: 1400px) {
        gap: 20px 8px;

        @media(max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);

            @media(max-width: 769px) {
                grid-template-columns: repeat(1, 1fr);

                @media (max-width: 576px) {}
            }
        }
    }

    .loader-integrations-container {
        cursor: pointer;
        width: 270px;
        min-height: 141px;
        padding: 22px 24px 40px 24px;

        @media(max-width: 1400px) {
            width: 252px;
            // padding: 20px 5px 20px 20px;
            // right: 75px;

            @media(max-width: 769px) {
                @media (max-width: 576px) {}
            }
        }

        opacity: 1;
        background: #FFFFFF;
        box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.05);
        border: 0.5px solid #C7CDD6;
        border-radius: 10px;
    }

}

.circle-loader-settings-container {
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;

    .loader-title {
        color: #000000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}

// ==== Integration Settings ======
.integration-setting-list-container {
    min-height: 100%;
    width: 100%;
    // width: 850px;

    // @media(max-width: 1400px) {
    //     width: 750px;

    //     @media(max-width: 769px) {
    //         @media (max-width: 576px) {}
    //     }
    // }

    .extra {
        height: 30px;
    }

    .settings-header-title {
        height: 46px;
        width: 100%;
        /* Smart layout */
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .back-path-container {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            h3 {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #272C35;
            }

            .back-arrow {
                height: 16px;
                width: auto;
            }

        }

    }

    .settings-header-tab {
        margin-top: 20px;
        height: 44px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        // padding: 15px 0 15px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        .tab,
        .active {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            transition: all 0.3s ease-in-out;

            &:hover {
                font-weight: 500;
                // color: #0F43BD;
            }
        }

        .tab {
            font-weight: 500;
            color: #272C35;
        }

        .active {
            color: #0F43BD;
        }
    }




}

// ===== Add Channel ====
.select-location-setting-table-container {
    width: 560px !important;

    .select-location-setting-table-form {
        width: 100%;
        padding: 30px 0;

        h3 {
            padding: 0 40px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;

            color: #272C35;
            margin-bottom: 10px;
            margin: 0 40px;
            padding-bottom: 15px;
        }

        .settings-form-item {
            margin-bottom: 20px;
            padding: 0 40px;

            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;

                color: #4E5869;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                padding: 0 11px;
                // width: 396px;
                height: 42px;
                border-radius: 4px;
                opacity: 1;
                box-sizing: border-box;
                border: 1px solid #C7CDD6;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #B9BFCB;
                }
            }

        }

        .action-buttons {
            margin-top: 20px;
            padding: 0 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;

            .text-button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;

                color: #272C35;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #0F43BD;
                }
            }
        }

        .location-table {
            padding: 0 14px 0 40px;
            margin-right: 20px;
            min-height: 0;
            max-height: 225px;
            overflow-y: auto;

            .table-responsive {
                table {
                    width: 100%;
                    border-collapse: collapse;

                    .table-head {
                        border-bottom: 0.6px solid #C7CDD6; // Add a bottom border to each row
                    }

                    thead {

                        tr {
                            th {
                                height: 42px; // Set the height of each row
                                padding: 0;
                                vertical-align: middle; // Center align the content vertically
                                text-align: center;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 14px;
                                letter-spacing: 0.01em;

                                color: #17173A;

                                &:first-child {
                                    text-align: left;
                                    // width: 60%;
                                }

                                &:nth-last-child(2) {
                                    width: 100%;
                                }

                                // border-right: 1px solid red;

                                &:last-child {
                                    padding-right: 6px;
                                    text-align: right; // Right align the last column (delete icon)
                                }
                            }

                            img {
                                cursor: pointer;
                                // transition: transform 0.3s ease-in-out;

                                // &:hover {
                                //     transform: scale(1.1); // Scale up the delete icon on hover
                                // }
                            }
                        }
                    }

                    tbody {

                        tr {
                            border-bottom: 0.6px solid #C7CDD6; // Add a bottom border to each row

                            td {
                                height: 42px; // Set the height of each row
                                padding: 0;
                                vertical-align: middle; // Center align the content vertically
                                text-align: center;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 14px;
                                letter-spacing: 0.01em;

                                color: #272C35;

                                &:first-child {
                                    text-align: left;
                                    // width: 60%;
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    img {
                                        height: 18px;
                                        width: 18px;
                                    }
                                }

                                // &:nth-last-child(2) {
                                //     width: 50%;
                                // }

                                // border-right: 1px solid red;

                                &:last-child {
                                    text-align: right; // Right align the last column (delete icon)
                                }
                            }

                            img {
                                cursor: pointer;
                                transition: transform 0.3s ease-in-out;

                                &:hover {
                                    transform: scale(1.1); // Scale up the delete icon on hover
                                }
                            }
                        }
                    }
                }
            }


            &::-webkit-scrollbar-thumb {
                background: #A3A3A3 !important;
                // height: 10px !important;
                border-radius: 10px;
                cursor: pointer;
                min-height: 30px;
                /* Set your desired fixed height */
                max-height: 30px;
                /* Ensure it doesn't exceed the desired fixed height */
                background-clip: padding-box;
                /* Adjusts the background to stay within the thumb bounds */
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px #fff;
                background-color: #fff !important;
                margin: 0 !important;
                // background-color: transparent ;
                // border-radius: 10px;
            }
        }

    }
}
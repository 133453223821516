// ===== Create Label ====
.color-picker-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // border: 1px dashed #d9d9d9;
    // padding: 12px;
    // border-radius: 6px;

    .color-picker-inputs {
        display: flex;
        gap: 20px;
        align-items: center;

        // .color-format-select {
        //     width: 80px;
        // }

        .ant-select-selector {
            flex-grow: 1;
            width: 90px !important;
            height: 42px !important;
            border-radius: 4px !important;
            display: flex;
            align-items: center;
            border: 0.6px solid #C7CDD6 !important;
            outline: none !important;
        }

        .ant-select-selection-item {
            line-height: 42px !important; // Aligns text vertically
        }

        &:hover,
        &:focus {
            .ant-select-selector {
                border: 0.6px solid #C7CDD6 !important;
                outline: none !important;
                box-shadow: none !important;
            }
        }

        .color-value-input {
            flex-grow: 1;
            height: 42px;
            width: 190px !important;
            text-align: center;
            border-radius: 4px;
            border: 0.6px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.01em;

            color: #272C35;

            &::placeholder {
                color: #B9BFCB;
            }
        }

        .transparency-input {
            flex-shrink: 1;
            height: 42px;
            width: 76px !important;
            line-height: 42px !important; // Aligns text vertically
            overflow: hidden;
            text-align: center !important;
            border-radius: 4px;
            border: 0.6px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.01em;

            color: #272C35;

            &::placeholder {
                color: #B9BFCB;
            }
        }
    }

    .color-palette-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .palette-label {
            margin-top: 20px;
            font-size: 13px;
            font-weight: normal;
            line-height: 13px;
            letter-spacing: 0em;

            color: #272C35;
        }

        .color-palette {
            display: grid;
            // grid-template-columns: repeat(auto-fit, minmax(28px, 1fr)); // Dynamically adjusts columns
            grid-template-columns: repeat(13, 1fr);
            gap: 8px;
            margin-top: 5px;

            // color_palette: rgb(240, 187, 64), rgb(240, 68, 56), rgb(185, 191, 203), rgb(66, 118, 240), rgb(254, 200, 75), rgb(2, 122, 72), rgb(246, 215, 142), rgb(74, 31, 184), rgb(0, 0, 0), rgb(117, 130, 153), rgb(180, 35, 24), hsl(222, 86%, 20%), hsl(222, 85%, 50%), hsl(222, 85%, 50%),

            .color-circle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                cursor: pointer;
                border: 1px solid transparent;
                transition: all 0.3s ease-in-out;

                &:hover {
                    border: 1px solid #646464;
                }
            }
        }
    }
}

// ====== Add Team ========
.add-team-color-picker-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // border: 1px dashed #d9d9d9;
    // padding: 12px;
    // border-radius: 6px;

    .color-picker-inputs {
        display: flex;
        gap: 20px;
        align-items: center;

        // .color-format-select {
        //     width: 80px;
        // }

        .ant-select-selector {
            flex-grow: 1;
            width: 90px !important;
            height: 42px !important;
            border-radius: 4px !important;
            display: flex;
            align-items: center;
            border: 0.6px solid #C7CDD6 !important;
            outline: none !important;
        }

        .ant-select-selection-item {
            line-height: 42px !important; // Aligns text vertically
        }

        &:hover,
        &:focus {
            .ant-select-selector {
                border: 0.6px solid #C7CDD6 !important;
                outline: none !important;
                box-shadow: none !important;
            }
        }

        .color-value-input {
            height: 42px;
            width: 190px !important;
            text-align: center;
            border-radius: 4px;
            border: 0.6px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.01em;

            color: #272C35;

            &::placeholder {
                color: #B9BFCB;
            }
        }

        .transparency-input {
            flex-grow: 1;
            height: 42px;
            width: 76px !important;
            line-height: 42px !important; // Aligns text vertically
            overflow: hidden;
            text-align: center;
            border-radius: 4px;
            border: 0.6px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.01em;

            color: #272C35;

            &::placeholder {
                color: #B9BFCB;
            }
        }
    }

    .color-palette-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .palette-label {
            margin-top: 20px;
            font-size: 13px;
            font-weight: normal;
            line-height: 13px;
            letter-spacing: 0em;

            color: #272C35;
        }

        .color-palette {
            display: grid;
            grid-template-columns: repeat(14, 1fr);
            gap: 8px;
            margin-top: 5px;

            // color_palette: rgb(240, 187, 64), rgb(240, 68, 56), rgb(185, 191, 203), rgb(66, 118, 240), rgb(254, 200, 75), rgb(2, 122, 72), rgb(246, 215, 142), rgb(74, 31, 184), rgb(0, 0, 0), rgb(117, 130, 153), rgb(180, 35, 24), hsl(222, 86%, 20%), hsl(222, 85%, 50%), hsl(222, 85%, 50%),

            .color-circle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                cursor: pointer;
                border: 1px solid transparent;
                transition: all 0.3s ease-in-out;

                &:hover {
                    border: 1px solid #646464;
                }
            }
        }
    }
}

// ======= team > edit ==> settings ======
.settings-team-color-picker-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    // border: 1px dashed #d9d9d9;
    // padding: 12px;
    // border-radius: 6px;

    .color-picker-inputs {
        display: flex;
        gap: 8px;
        align-items: center;

        // .color-format-select {
        //     width: 80px;
        // }

        .ant-select-selector {
            width: 80px !important;
            height: 42px !important;
            border-radius: 6px !important;
            display: flex;
            align-items: center;
            border: 0.6px solid #C7CDD6 !important;
            outline: none !important;
        }

        .ant-select-selection-item {
            line-height: 42px !important; // Aligns text vertically
        }

        &:hover,
        &:focus {
            .ant-select-selector {
                border: 0.6px solid #C7CDD6 !important;
                outline: none !important;
                box-shadow: none !important;
            }
        }

        .color-value-input {
            height: 42px !important;
            width: 152px !important;

            @media(max-width: 769px) {

                @media (max-width: 576px) {
                    width: 132px !important;

                    @media (max-width: 420px) {
                        width: 100px !important;
                    }
                }
            }

            text-align: center;
            border-radius: 6px;
            border: 0.6px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.01em;

            color: #272C35;

            &::placeholder {
                color: #B9BFCB;
            }
        }

        .transparency-input {
            width: 75px !important;
            line-height: 42px !important; // Aligns text vertically
            overflow: hidden;
            height: 42px;
            text-align: center;
            border-radius: 6px;
            border: 0.6px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.01em;

            color: #272C35;

            &::placeholder {
                color: #B9BFCB;
            }
        }
    }

    .color-palette-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .palette-label {
            margin-top: 20px;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0.01em;
        }

        .color-palette {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: 8px;
            margin-top: 5px;

            // color_palette: rgb(240, 187, 64), rgb(240, 68, 56), rgb(185, 191, 203), rgb(66, 118, 240), rgb(254, 200, 75), rgb(2, 122, 72), rgb(246, 215, 142), rgb(74, 31, 184), rgb(0, 0, 0), rgb(117, 130, 153), rgb(180, 35, 24), hsl(222, 86%, 20%), hsl(222, 85%, 50%), hsl(222, 85%, 50%),
            @media(max-width: 769px) {

                @media (max-width: 576px) {
                    gap: 5px;
                }
            }

            .color-circle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                cursor: pointer;
                border: 1px solid transparent;
                transition: all 0.3s ease-in-out;

                &:hover {
                    border: 1px solid #646464;
                }
            }
        }
    }
}
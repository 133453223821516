.settings-nav-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .setting-title {
    text-align: start;
    font-size: 16px;
    font-weight: 600;

    // @media(max-width: 769px) {
    //   font-size: 14px;
    //   font-weight: 500;

    //   @media (max-width: 576px) {
    //     font-size: 13px;

    //     @media (max-width: 420px) {
    //       display: none;
    //     }
    //   }
    // }

    line-height: 24px;
    letter-spacing: 0.01em;
    color: #272C35;
  }

  ul {
    list-style-type: none;
    /* Removes the bullet points */
    padding: 5px 0;

    &:nth-child(1) {
      padding: 0;
    }

    margin: 0;

    li {
      button {
        padding: 0 10px;

        // @media (max-width: 420px) {
        //   padding: 0 5px;
        // }

        margin-bottom: 10px;
        // width: 100%;
        width: 190px;


        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        // @media(max-width: 1200px) {
        //   width: 150px;

        //   @media(max-width: 769px) {
        //     width: 100%;
        //     justify-content: center;

        //     @media (max-width: 576px) {}
        //   }
        // }

        gap: 10px;
        text-align: center;
        background-color: transparent;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;

        &:hover,
        &.active {
          background: #E0EAFF;
        }


        img {
          height: auto;
          width: 18px;

          // @media(max-width: 1200px) {
          //   display: none;

          //   @media(max-width: 769px) {
          //     display: block;

          //     @media (max-width: 576px) {
          //       @media (max-width: 350px) {
          //         height: 14px;
          //       }
          //     }
          //   }
          // }
        }

        .title {
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: #272C35;

          // @media(max-width: 1200px) {
          //   font-size: 13px;

          //   @media(max-width: 769px) {
          //     display: none;

          //     @media (max-width: 576px) {}
          //   }
          // }
        }

      }

    }

    .li-loader {
      button {

        // @media (max-width: 420px) {
        //   padding: 0 5px;
        // }

        margin-bottom: 10px;
        width: 190px;

        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        text-align: center;
        background-color: transparent;
        border-radius: 6px;

      }

    }

  }
}
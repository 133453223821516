.header-form-container,
.body-form-container,
.footer-form-container,
.call-to-action-buttons-form-container,
.quick-reply-buttons-form-container,
.option-output-buttons-form-container {
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    .title-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.8rem;
        line-height: 0;

        .title {
            font-size: 0.9rem;
            font-weight: 500;
            color: #000000;
        }

        .optional {
            font-size: 0.9rem;
            font-weight: 500;
            color: #667085;
        }
    }

    .selector-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.8rem;

        img {
            cursor: pointer;
        }

        .input-select {
            text-align: center;
            width: 120px !important;
        }

        .promotion-input-select {
            text-align: center;
            width: 180px !important;
        }
    }


}

.header-form,
.body-form,
.call-to-action-form,
.quick-reply-form,
.footer-form,
.option-output-buttons-form {
    width: 100%;
    min-height: 200px;
    // overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;

    .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.4rem;

        .form-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.2rem;

            input {
                width: 100%;
                height: 40px;
                border-radius: 5px;
                font-size: 0.75rem;
            }

            .url-slug-input {
                width: 100px !important;
                text-align: center;
            }

            .remove-item {
                cursor: pointer;
                font-size: 0.75rem;
                font-weight: 500;
                color: #667085;
            }

        }

        label {
            font-size: 0.75rem;
            font-weight: 500;
            color: #667085;
        }

    }
}

.header-form {
    min-height: 80px;
}

.quick-reply-form {
    min-height: 150px;
}

.call-to-action-form,
.option-output-buttons-form {
    min-height: 150px;

    .parent-form {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.2rem;

        .form {
            .form-item {
                gap: 0.5rem;
            }

            &:first-child {
                width: 50%;

                @media(max-width: 768px) {
                    width: 100%;
                }
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        @media(max-width: 768px) {
            flex-direction: column;
        }

        .url-container {
            display: flex;
            gap: 0.8rem;

            .static-dynamic-select {
                display: flex;
                gap: 0.5rem;

                img {
                    cursor: pointer;
                }
            }
        }
    }
}

.footer-form {
    min-height: fit-content;
}

.option-output-buttons-form {
    min-height: fit-content;

    .form {
        input {
            text-align: center;
            background: #D4DBE7;
        }
    }
}
.conversation-header {
    width: 100%;
    flex-shrink: 1;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 0.6px solid #C7CDD6;

    .user-info {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 25px;
        position: relative;

        .user-profile-1 {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .user-profile-2 {
            position: absolute;
            left: 18px;
            top: 15px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .user-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // gap: 5px;

            .username-tag-stars {
                display: flex;
                align-items: center;
                gap: 11px;

                .username-1 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.01em;
                    /* Text / Title */
                    color: #17173A;
                }

                .tag {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 14px;
                    letter-spacing: 0.01em;
                    /* Text / Title */
                    color: #17173A;
                }

                .rating-stars {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .stars {
                        display: flex;
                        gap: 3px;

                        img {
                            height: 11px;
                            width: 11px;
                        }
                    }

                    .rating {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 12px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }
                }
            }

            .username-2 {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.01em;
                /* Text / Title */
                color: #17173A;
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .action-btn {
            height: 22px;
            /* Smart layout */
            display: flex;
            flex-direction: row;
            padding: 5px 10px;
            gap: 10px;
            z-index: 3;
            border-radius: 4px;
            background: none;
            border: none;
            outline: none;
            font-size: 12px;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: 0.01em;
            color: #272C35;
            cursor: pointer;
            opacity: 1;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 0.7;
            }
        }

        .provider-logo {
            width: 22px;
            height: 22px;
        }
    }
}

.conversation-data {
    flex-grow: 1;
    width: 100%;
    padding-right: 4px;
    height: 68vh;

    @media(max-width: 1600px) {
        height: 57vh;
    }

    .conversation-container {
        text-align: left;
        margin-top: 10px;
        padding: 0 10px 10px 20px;
        width: 100%;
        // height: 64vh;
        height: 100%;
        padding-bottom: 46px;
        // height: 500px;
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        // justify-content: flex-start;
        // flex-direction: column-reverse;
        gap: 20px;

        &:hover {
            &::-webkit-scrollbar-thumb {
                background: #AAB2C0 !important;
                // height: 10px !important;
                border-radius: 10px;
                cursor: pointer;
                min-height: 30px;
                /* Set your desired fixed height */
                max-height: 30px;
                /* Ensure it doesn't exceed the desired fixed height */
                background-clip: padding-box;
                /* Adjusts the background to stay within the thumb bounds */
            }

        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px transparent;
            background: transparent !important;
        }

        .fb-conversation-title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: #4E5869;
        }

        .conversation-body {
            background: #FFFFFF;
            border-radius: 10px;

            .header-affiliate-info-container {
                padding: 15px 20px;
                width: 100%;
                border-bottom: 0.6px solid #C7CDD6;

                .header-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 8px;

                    .user-info {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .profile-pic {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }

                        .user-details {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            gap: 6px;

                            .username {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.01em;
                                /* Text / Title */
                                color: #17173A;
                            }

                            .tag {
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 14px;
                                letter-spacing: 0.01em;
                                font-variation-settings: "opsz" auto;
                                color: #4E5869;
                            }

                            .rating-stars {
                                display: flex;
                                align-items: center;
                                gap: 5px;

                                .stars {
                                    display: flex;
                                    gap: 3px;

                                    img {
                                        height: 11px;
                                        width: 11px;
                                    }
                                }

                                .rating {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 14px;
                                    letter-spacing: 0.01em;
                                    color: #17173A;
                                }
                            }
                        }

                    }

                    .date-time {
                        font-size: 12px;
                        font-weight: normal;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.01em;
                        color: #4E5869;
                    }
                }

                .conversation-affiliate-info {
                    padding-left: 50px;
                    font-size: 13px;
                    font-weight: normal;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    color: #17173A;
                }
            }

            .conversation-message {
                padding: 20px 15px;
                // white-space: ;
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                letter-spacing: 0.01em;
                /* Text / Body */
                color: #101828;
                /* Add this line */
                word-break: break-all;
            }

            .reply-edit-comment-like-container {
                padding: 0 15px 20px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .review-reply,
                .review-edit,
                .comment-container,
                .like-container {
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    color: #4E5869;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }

                .like-comment-container {
                    display: flex;
                    gap: 25px;
                    justify-content: flex-end;
                    align-items: center;
                }

            }
        }
    }
}

.conversation-replying-container {
    flex-shrink: 1;
    // height: 170px;
    height: 220px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3px;
    padding: 10px 15px;

    .replying-header {
        display: flex;
        padding: 0 5px 0 0;
        justify-content: flex-end;
        align-items: center;

        .replying-info {
            font-size: 14px;
            font-weight: 500;
            color: #4b5563;
        }

        .countdown {
            font-size: 12px;
            color: #9ca3af;
        }
    }

    .replying-body-footer,
    .disable-footer {
        overflow: hidden;
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #C7CDD6;

        textarea {
            width: 100%;
            // height: 82px;
            height: 98px;
            padding: 15px;
            outline: none;
            border: none;
            resize: none;
            font-size: 14px;
            color: #374151;
            background: transparent;
            font-weight: normal;
            line-height: 14px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            &::placeholder {
                color: #AAB2C0;
            }
        }

        .attachment-footer {
            height: 38px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .attachment-items-container {
                height: 28px;
                display: flex;
                align-items: center;
                gap: 10px;

                .attachment-item {
                    min-width: 220px;
                    max-width: 256px;
                    height: 28px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 4px;
                    padding: 4px 8px;

                    background: #EEF4FF;

                    .attachment-key {
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.01em;

                        font-feature-settings: "kern" on;
                        color: #3D3D3D;
                    }

                    img {
                        cursor: pointer;
                    }
                }
            }

            .attachment-dropdown {
                height: 28px;

                img {
                    cursor: pointer;
                }
            }
        }

        .replying-footer {
            border-top: 0.6px solid #C7CDD6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 20px;

            .quick-reply-advance {
                display: flex;
                gap: 10px;

                button {
                    background: none;
                    border: none;
                    font-size: 14px;
                    color: #374151;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            .reply-action-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 15px;

                img {
                    height: 14px;
                    width: auto;
                    cursor: pointer;
                }

                .reply-button {
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 25px;
                    gap: 10px;
                    border-radius: 5px;
                    opacity: 1;
                    background: #1354EC;
                    transition: all 0.3s ease-in-out;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    .nonactive-footer {
        background: #F0F2F4;
    }

    .disable-footer {
        background: #F0F2F4;
        pointer-events: none;
        cursor: not-allowed;
    }


}
// CircleLoader.scss
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.circle-loader {
    --loader-size: 50px; // Default size, can be overridden
    --loader-border-width: 6px; // Default border width, can be overridden

    width: var(--loader-size);
    height: var(--loader-size);
    border: var(--loader-border-width) solid #D6DAE1; // Light gray background
    border-top: var(--loader-border-width) solid #0F43BD; // Blue accent
    border-radius: 50%;
    animation: rotate 1.5s linear infinite;
}
// .shadow {
//     // background: rgba(0, 0, 0, 0.034) !important;
//     background: transparent !important;
// }

.dialog-overlay {
    font-family: 'Inter';
    font-style: normal;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.144);

    @media(max-width: 769px) {
        background: rgba(0, 0, 0, 0.142);
    }

    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1010 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-in-out;

    .dialog-container,
    .dialog-container-content,
    .dialog-container-link-form,
    .dialog-container-preview-location {
        background: #FFFFFF;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0.6000000238418579px 0.6000000238418579px 10px 5px rgba(23, 23, 58, 0.05);
        width: 32%;

        @media(max-width: 769px) {
            width: 95%;
        }

        min-height: 20%;
        padding: 0.5%;
        display: flex;
        flex-direction: column;

        .close {
            position: absolute;
            right: 15px;
            top: 15px;
            // margin-left: auto;
            border-radius: 50%;
            padding: 0% 1%;
            border: 2px solid #5a5959;
            cursor: pointer;
            background: #cbcaca;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: #3131313e;
                border: 2px solid #5a5959;
            }

            img {
                width: 0.7vw;
                height: auto;
            }
        }

        .close-icon {
            position: absolute;
            right: 15px;
            top: 15px;
            border-radius: 50%;
            cursor: pointer;
            // width: 0.7vw;
            // height: auto;
            transition: all 0.3s ease-in-out;

            &:hover {
                opacity: 0.8;
            }
        }

        .dialog-header {
            h3 {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.2rem;
                letter-spacing: 0.01em;
                color: #17173A;
            }
        }

        .dialog-wrapper {
            padding: 3%;

            h3 {
                font-weight: 600;
                font-size: 20px;
                line-height: 15px;
                text-align: center;
                padding: 0 0 10px;
                text-transform: capitalize;
                color: #000000;
            }

            h4 {
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                padding: 0 0 20px;
                text-align: center;
                text-transform: lowercase;
                color: #555555;
            }

            h5 {
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
                padding: 0 0 20px;
                text-align: center;
                color: #555555;
            }

            .location-link {
                cursor: pointer;
                color: #2F407C;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                padding: 4px 0;
            }

            form {
                display: flex;
                flex-direction: column;

                .form-col {
                    position: relative;
                    margin: 0 0 14px;

                    span {
                        position: absolute;
                        right: 10px;
                        top: 4px;
                    }

                    >input[type="text"],
                    textarea {
                        width: 100%;
                        background: #F8FAFF;
                        border: 1px solid #E1E7ED;
                        box-sizing: border-box;
                        border-radius: 3px;
                        outline: none;
                        padding: 10px 30px 10px 10px;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 15px;
                        text-transform: capitalize;
                        color: #B0B0B0;
                    }

                    button {
                        width: 100%;
                    }

                    input[type="file"] {
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        padding: 0;
                        height: 100%;
                        cursor: pointer;
                    }
                }
            }

            .warning-dialog-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                .warning-dialog-actions {
                    display: flex;
                    flex-direction: row;
                    width: 40%;
                    align-items: center;
                    justify-content: space-evenly;
                }
            }
        }

        .warning-dialog-content {
            margin: 5% 0 7% 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: 5px solid #FA703F;
            background: #FFE9D9;
            padding: 3% 5%;
            text-align: justify;


            .header-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2%;
                margin: 0 0 2.5% 0;

                p {
                    margin-top: 0.5%;
                    font-weight: 500;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                    letter-spacing: 0.00875rem;

                    @media(max-width: 1400px) {
                        font-size: 0.875rem;
                        line-height: 0.875rem;

                        @media(max-width: 769px) {
                            font-size: 1.2rem;
                            line-height: 1.2rem;
                        }
                    }

                    color: #771505;
                }

                img {
                    // width: auto;
                    width: 1.2vw;

                    @media(max-width: 769px) {
                        width: 3.8vw;
                    }

                    height: auto;
                }
            }

            .content-body {
                font-size: 0.9rem;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }

                color: #BC4C2E;
            }
        }

        .dialog-footer {
            width: 100%;
            display: flex;
            justify-content: space-between;

            button {
                padding: 2.2% 3.5%;
                font-weight: 500;
                font-size: 0.9rem;

                @media(max-width: 1400px) {
                    font-size: 0.75rem;

                    @media(max-width: 769px) {
                        font-size: 0.9rem;
                    }
                }

                border-radius: 4px;
                background: var(--bg-active-text-bg, #D8E3FF);
                border: none;
                outline: none;
                transition: all 0.3s ease-in-out;
                color: #17173A;

                border: 1px solid transparent;

                &:hover {
                    border: 1px solid #175CD3;
                }


            }

            .left-btn-hover {
                border: 1px solid transparent;

                &:hover {
                    border: 1px solid #175CD3;
                }
            }

            .right-btn-hover {
                color: #e47c55;

                &:hover {
                    border: 1px solid #FA703F;
                }
            }
        }
    }

    .dialog-container-content,
    .dialog-container-link-form,
    .dialog-container-preview-location,
    .dialog-container-commerce-form {
        box-shadow: 1.6px 1.6px 10px 5px rgba(23, 23, 58, 0.05);
        padding: 2% 2.5%;

        @media(max-width: 769px) {
            padding: 4% 2.5%;
        }
    }

    .dialog-container-link-form,
    .dialog-container-commerce-form {
        position: relative;
        width: 50%;
    }

    .dialog-container-commerce-form {
        padding: 0 2.5% 2% 2.5%;

        @media(max-width: 769px) {
            width: 90%;
            padding: 0 2.5% 2.5% 2.5%;
        }
    }

    .dialog-container-preview-location {
        position: relative;
        width: 40%;
    }

}
.integration-settings-container {
    width: 100%;
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 15px;

    @media(max-width: 1400px) {
        gap: 20px 8px;

        @media(max-width: 1200px) {
            grid-template-columns: repeat(2, 1fr);

            @media(max-width: 769px) {
                grid-template-columns: repeat(1, 1fr);

                @media (max-width: 576px) {}
            }
        }
    }

    .integrations-container,
    .connected-integration-container {
        cursor: pointer;
        width: 270px;
        height: 141px;
        padding: 24px 5px 24px 24px;

        @media(max-width: 1400px) {
            width: 252px;
            // padding: 20px 5px 20px 20px;
            // right: 75px;

            @media(max-width: 769px) {
                @media (max-width: 576px) {}
            }
        }

        border-radius: 10px;
        opacity: 1;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 0.5px solid #C7CDD6;
        box-shadow: 0px 0px 25px 5px #0000000d;

        display: flex;
        flex-direction: column;
        gap: 8px;

        .header-container {
            display: flex;
            align-items: center;
            gap: 20px;

            img {
                height: 30px;
                width: auto;
            }

            .title {
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                letter-spacing: 0.01em;

                color: #272C35;
            }
        }

        .description {
            font-size: 14px;

            @media(max-width: 1400px) {
                font-size: 13px;

                @media(max-width: 769px) {
                    @media (max-width: 576px) {}
                }
            }

            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.01em;

            color: #272C35;
        }
    }

    .connected-integration-container {
        background: #F0F2F4;
        cursor: not-allowed;
    }
}

// ==== connected accounts ====
.connected-account-list-table {
    .table-responsive {
        width: 100%;
        overflow-x: auto;
    }

    .table {
        width: 100%;
        border-collapse: separate;
        // margin-top: 10px;
        border-spacing: 0 20px; // Adds spacing between rows (acts like `margin-bottom`)

        thead {
            th {
                padding: 0;
            }

            // display: none;
            .table-header-row-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                height: 36px;
                border-bottom: 0.6px solid #C7CDD6;

                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.01em;

                color: #4E5869;

                .connected-account-header-title-container {
                    min-width: 40%;
                    max-width: 40%;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0;
                    border: none; // Remove any default borders from cells
                    cursor: pointer;

                    .table-row-content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 30px;
                        border: 0.6px solid #C7CDD6;
                        border-radius: 6px;
                        height: 86px;

                        .connected-account-title-container {
                            min-width: 45%;
                            max-width: 45%;
                            display: flex;
                            gap: 12px;
                            align-items: center;

                            .connected-account-icon {
                                // border-radius: 50%;
                                height: 30px;
                                width: 30px;
                                // border: 0.2px solid #AAB2C0;
                                display: flex;
                                justify-content: center;
                                overflow: hidden;
                                border-radius: 50%;

                                img {
                                    height: auto;
                                    width: 100%;
                                }
                            }

                            .connected-account-title {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                .account-provider {
                                    font-size: 12px;
                                    color: #4E5869;
                                }
                            }
                        }

                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }
                }
            }
        }
    }

    .delete-icon {
        width: 16px;
        height: auto;
    }
}
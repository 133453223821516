.un-permission-section {
    // height: 90%;
    min-height: 100%;
    width: 96.9%;
    background: #F4F3FF;
    border-radius: 5px 5px 0px 0px;
    // min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    margin-top: 0.945%;
    padding-bottom: 5%;
    pointer-events: none;

    @media(max-width: 769px) {
        width: 100%;
        margin-top: 0;
        padding-top: 5%;
        padding-bottom: 0;
    }

    .un-permission-icon {
        width: 100px;
        height: auto;
    }

    .un-permission-description {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        margin-top: 2%;
    }

}
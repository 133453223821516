.hostname-publishing-form {
    width: 480px;
    text-align: left;
    padding: 30px 40px;

    h3 {
        color: var(--Title, #04112F);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 8px;
        border-bottom: 0.6px solid var(--Normal, #D6DAE1);
        margin-bottom: 20px;
    }

    // .radio-action-container {
    //     width: 100%;
    //     display: flex;
    //     align-items: center;
    //     gap: 15px;

    //     .radio-action {
    //         cursor: pointer;
    //         display: flex;
    //         gap: 10px;
    //         align-items: center;
    //         font-size: 14px;
    //         font-weight: normal;
    //         line-height: 20px;
    //         letter-spacing: 0em;
    //         color: #272C35;
    //         // border-bottom: 0.5px solid #C7CDD6;

    //     }

    //     padding-bottom: 15px;
    // }

    .requirement-description {
        color: var(--Primary, #272C35);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 20px 0;
    }

    .form-item {
        margin-bottom: 20px;

        input {
            width: 100%;
            padding: 10px 15px;
            // width: 396px;
            height: 48px;
            border-radius: 4px;
            opacity: 1;
            box-sizing: border-box;
            border: 1px solid #C7CDD6;
            outline: none;
            font-size: 14px;
            color: var(--Title, #04112F);
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            &::placeholder {
                color: #B9BFCB;
            }
        }

    }

    .action-buttons {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        .text-button {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            color: var(--Title, #04112F);
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                // color: #F79009;
                color: var(--Active, #0F43BD);
            }
        }
    }

}

.general-settings-container {
    min-height: 100%;
    width: 100%;
    // width: 850px;

    // @media(max-width: 1400px) {
    //     width: 750px;

    //     @media(max-width: 769px) {
    //         @media (max-width: 576px) {}
    //     }
    // }

    .extra {
        height: 30px;
    }


    .settings-header-title {
        height: 46px;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #272C35;
        }
    }

    .settings-form-item {
        min-height: 102px;
        padding: 30px 0;
        width: 100%;
        /* Smart layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;

        // @media(max-width: 1200px) {
        //     align-items: flex-start;
        //     flex-direction: column;
        //     gap: 20px;
        // }

        .title {
            flex-grow: 1;
            min-width: 50%;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #272C35;
        }

        .action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            min-width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            position: relative;


            .input-container {
                min-width: 320px;

                // @media(max-width: 1200px) {
                //     min-width: 100%;

                //     @media(max-width: 1024px) {
                //         width: 320px;
                //     }
                // }

                height: fit-content;
                position: relative;

                .tik {
                    z-index: 999;
                    position: absolute;
                    cursor: pointer;
                    right: 15px;
                    top: 13px;
                }
            }

            input,
            .disable-input,
            .logo-container {
                resize: none;
                width: 100%;
                height: 42px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

            }

            .disable-input {
                background: #F0F2F4;
            }

            .logo-container {
                padding: 0 15px 0 0;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                }

                .action-container {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    div,
                    label {
                        cursor: pointer;
                    }
                }
            }
        }

    }

    .notification-settings-form-item {
        min-height: 94px;
        padding: 30px 0;
        width: 100%;
        /* Smart layout */
        box-sizing: border-box;
        border-bottom: 0.6px solid #C7CDD6;
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        // @media(max-width: 1200px) {
        //     flex-direction: column;
        //     gap: 0;
        //     align-items: flex-start;

        //     @media(max-width: 769px) {
        //         @media (max-width: 576px) {}
        //     }
        // }

        .title-description-container {
            flex-grow: 1;
            width: 360px;
            // border-left: 1px solid red;
            display: flex;
            flex-direction: column;
            gap: 9px;

            // padding-right: 80px;
            @media(max-width: 769px) {
                width: 100%;

                @media (max-width: 576px) {}
            }

            .title {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #272C35;
            }

            .description {
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #4E5869;

                min-width: 323px;

                @media(max-width: 769px) {
                    @media (max-width: 576px) {
                        min-width: 100%;
                    }
                }

                max-width: 357px;
            }
        }


        .action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            width: 360px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;

            .switch-icon {
                width: 24px;
                height: auto;
                cursor: pointer;
            }

            @media(max-width: 1200px) {
                margin-top: 15px;

                @media(max-width: 769px) {
                    width: 100%;

                    @media (max-width: 576px) {}
                }
            }

            // .tik {
            //     z-index: 999;
            //     position: absolute;
            //     right: 115px;
            //     cursor: pointer;
            // }

            .label-input-container {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 5px;

                @media(max-width: 769px) {
                    @media (max-width: 576px) {
                        width: 100%;
                    }
                }

                label {
                    font-size: 14px;
                    font-weight: 400;
                    color: #758299;
                }

                input {
                    resize: none;
                    width: 320px;

                    @media(max-width: 769px) {
                        @media (max-width: 576px) {
                            width: 100%;
                        }
                    }

                    height: 42px;
                    border-radius: 6px;
                    padding: 11px 15px;
                    box-sizing: border-box;
                    border: 0.6px solid #C7CDD6;
                    outline: none;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 20px;
                    letter-spacing: 0.01em;

                    color: #272C35;

                    &::placeholder {
                        color: #B9BFCB;
                    }

                }

                .non-label-tik {
                    z-index: 999;
                    position: absolute;
                    right: 15px;
                    top: 13px;
                    cursor: pointer;
                }

                .tik {
                    z-index: 999;
                    position: absolute;
                    right: 15px;
                    top: 38px;
                    cursor: pointer;
                }
            }

            .custom-select {
                .ant-select-selector {
                    width: 320px !important;

                    @media(max-width: 769px) {

                        @media (max-width: 576px) {
                            width: 100% !important;

                        }
                    }

                    height: 42px !important;
                    border-radius: 6px !important;
                    display: flex;
                    align-items: center;
                    border: 0.6px solid #C7CDD6 !important;
                    outline: none !important;
                }

                .ant-select-selection-item {
                    line-height: 42px !important; // Aligns text vertically
                }

                &:hover,
                &:focus {
                    .ant-select-selector {
                        border: 0.6px solid #C7CDD6 !important;
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }
            }

            input,
            .disable-input,
            .logo-container {
                resize: none;
                width: 320px;
                height: 42px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

            }

            .disable-input {
                background: #F0F2F4;
            }

            .logo-container {
                resize: none;
                width: 320px;
                height: 42px;
                border-radius: 6px;
                padding: 11px 15px;
                box-sizing: border-box;
                border: 0.6px solid #C7CDD6;
                outline: none;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                letter-spacing: 0.01em;

                color: #272C35;

                &::placeholder {
                    color: #B9BFCB;
                }

                padding: 0 15px 0 0;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                }

                .action-container {
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;

                    div,
                    label {
                        cursor: pointer;
                    }
                }
            }
        }

        .color-picker-action-body {
            min-height: 100%;
            // border-left: 1px solid red;
            flex-grow: 1;
            // width: 50%;
            width: 360px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media(max-width: 1400px) {
                margin-top: 20px;

                @media(max-width: 769px) {
                    width: 100%;

                    @media (max-width: 576px) {}
                }
            }

            .color-picker-input-container {
                min-height: 94px;
                width: 320px;

                @media(max-width: 769px) {
                    width: 100%;

                    @media (max-width: 576px) {}
                }
            }

        }

    }
}
.mobile-calendar {
    display: none;

    @media(max-width: 769px) {
        display: block;
    }
}

.calendar-lg {
    display: block;

    @media(max-width: 769px) {
        display: none;
    }
}

.search-wrapper {
    font-style: normal;
    position: sticky;
    top: 0;
    background: #FFFFFF;
    z-index: 10;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1.3px solid #D3E0FF;
    padding: 0 10px;

    @media(max-width: 769px) {
        height: 60px;
        width: 100%;
        box-sizing: border-box;
    }

    .bot-search-text {
        z-index: 99;
        position: absolute;
        right: 60px;
        top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;

        @media (max-width: 769px) {
            right: 115px;
            top: 10px;
        }

        max-width: 170px;
        // padding: 0 15px;
        text-align: left;

        i {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
            color: #8999AB;
            font-weight: lighter;
            font-size: 10px;
        }

        p {
            width: 100%;
            font-size: 10px;
            padding: 4px 5px;
        }
    }

    .search-text {
        position: absolute;
        right: 60px;
        top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;

        @media (max-width: 769px) {
            right: 115px;
            top: 18px;
        }

        max-width: 170px;
        // padding: 0 15px;
        text-align: left;

        i {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
            color: #8999AB;
            font-weight: lighter;
            font-size: 10px;
        }

        p {
            font-size: 10px;
            padding: 4px 5px;
        }
    }

    .mobile-calender-filter {
        @media(max-width: 769px) {
            display: none;
        }
    }

    .search-icon,
    .mobile-nav-bar {
        // position: absolute;
        // left: 12px;
        // top: 10px;
        height: 14px;
        width: 14px;
        cursor: pointer;
    }

    .mobile-nav-bar {
        display: none;

        @media(max-width: 576px) {
            display: block;
            // top: 20px;
        }
    }

    @media(max-width: 576px) {
        .search-icon {
            display: none;
        }
    }


    .filter {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 10px;
        /* identical to box height, or 100% */

        letter-spacing: 0.01em;
    }

    input {
        width: 100%;
        height: 42px;
        background: #FFFFFF;
        // border: 1.3px solid #D3E0FF;
        border: none;
        border-radius: 5px;
        box-sizing: border-box;
        // padding: 2px 0 0 0;

        @media(max-width: 769px) {
            width: 100% !important;
            height: 56px;
        }

        outline: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 100% */

        letter-spacing: 0.01em;

        /* Text/Placeholder */

        color: #98A2B3;
    }

    input::placeholder {
        font-weight: 400;
        color: #667085;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0.02em;
    }

    .refresh-icon {
        // position: absolute;
        // right: 5px;
        // top: 0;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 10px;
        // margin-top: 5px;

        @media(max-width: 769px) {
            margin-top: 0;

            // right: 12%;
            img {
                margin-top: 0;
            }
        }
    }

    .add-options {
        display: none;

        @media(max-width: 576px) {
            display: block;
        }
    }

    .ws-logo {
        // position: absolute;
        // right: -18px;
        // top: 4px;
        // width: 22px;
        // height: 22px;
        border-radius: 50%;
        cursor: pointer;
        display: none;

        @media(max-width: 576px) {
            display: block;
        }
    }
}
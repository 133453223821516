// Black Background
// // SkeletonLoader.scss
// .skeleton-loader-container {
//     padding: 20px 20px;
//     // box-shadow: 1px 1px 8px linear-gradient(90deg,
//     //         #e0e0e0 25%,
//     //         #f5f5f5 50%,
//     //         #e0e0e0 75%);
//     background: #1c1c1e; // Dark background
//     border-radius: 8px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     align-items: center;
//     gap: 14px;

//     .multiple-skeleton-loader {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         gap: 14px;

//         .skeleton-avatar {
//             width: 80px;
//             height: 80px;
//             background-color: #2c2c2e;
//             border-radius: 50%;
//             animation: shimmer 1.5s infinite;
//         }

//         .text-skeleton-container {
//             display: flex;
//             justify-content: space-between;
//             gap: 14px;
//             flex-grow: 1;

//             .left-body {
//                 width: 100%;
//                 display: flex;
//                 flex-direction: column;
//                 gap: 20px;

//                 .skeleton-text {
//                     width: 60%;
//                     height: 20px;
//                     background-color: #2c2c2e;
//                     border-radius: 10px;
//                     animation: shimmer 1.5s infinite;
//                 }

//                 .skeleton-subtext {
//                     width: 30%;
//                     height: 20px;
//                     background-color: #2c2c2e;
//                     border-radius: 10px;
//                     animation: shimmer 1.5s infinite;
//                 }
//             }

//             .skeleton-subtext {
//                 width: 30%;
//                 height: 20px;
//                 background-color: #2c2c2e;
//                 border-radius: 10px;
//                 animation: shimmer 1.5s infinite;
//             }
//         }
//     }
// }

// // Keyframes for shimmer effect
// @keyframes shimmer {
//     0% {
//         background-position: -200px 0;
//     }

//     100% {
//         background-position: 200px 0;
//     }
// }

// .skeleton-avatar,
// .skeleton-text,
// .skeleton-subtext {
//     background: linear-gradient(90deg,
//             #2c2c2e 25%,
//             #3c3c3e 50%,
//             #2c2c2e 75%);
//     background-size: 400% 100%;
//     animation: shimmer 1.5s infinite;
// }


// white background
// SkeletonLoader.scss
.skeleton-loader-container {
    padding: 20px;
    background: #464646; // Light background for contrast
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 14px;

    .multiple-skeleton-loader {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 14px;

        .skeleton-avatar {
            width: 80px;
            height: 80px;
            background: linear-gradient(90deg,
                    #e0e0e0 25%,
                    #f5f5f5 50%,
                    #e0e0e0 75%);
            border-radius: 50%;
            animation: shimmer 2.5s infinite;
        }

        .text-skeleton-container {
            display: flex;
            justify-content: space-between;
            gap: 14px;
            flex-grow: 1;

            .left-body {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .skeleton-text {
                    width: 60%;
                    height: 20px;
                    background: linear-gradient(90deg,
                            #e0e0e0 25%,
                            #f5f5f5 50%,
                            #e0e0e0 75%);
                    border-radius: 10px;
                    animation: shimmer 1.5s infinite;
                }

                .skeleton-subtext {
                    width: 30%;
                    height: 20px;
                    background: linear-gradient(90deg,
                            #e0e0e0 25%,
                            #f5f5f5 50%,
                            #e0e0e0 75%);
                    border-radius: 10px;
                    animation: shimmer 1.5s infinite;
                }
            }

            .skeleton-subtext {
                width: 30%;
                height: 20px;
                background: linear-gradient(90deg,
                        #e0e0e0 25%,
                        #f5f5f5 50%,
                        #e0e0e0 75%);
                border-radius: 10px;
                animation: shimmer 1.5s infinite;
            }
        }
    }
}

// Keyframes for shimmer effect
@keyframes shimmer {
    0% {
        background-position: -200px 0;
    }

    100% {
        background-position: 200px 0;
    }
}

.skeleton-avatar,
.skeleton-text,
.skeleton-subtext {
    background: linear-gradient(90deg,
            #e0e0e0 25%,
            #f5f5f5 50%,
            #e0e0e0 75%);
    animation: shimmer 1.5s infinite;
}


// Single skeleton
.single-skeleton-loader {
    background: linear-gradient(90deg,
            #e0e0e0 25%,
            #f5f5f5 50%,
            #e0e0e0 75%);
    background-size: 200% 100%;
    animation: single_shimmer 1.5s infinite;
    display: inline-block;
}

@keyframes single_shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}
/* Modal slide-in and fade-in animation */
@keyframes AnimationBottomToTop {
    from {
        opacity: 0;
        transform: translateY(150px); // Move from above
    }

    to {
        opacity: 1;
        transform: translateY(0); // To its original position
    }
}

.clap-up-dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    position: relative;

    .dashboard-container {
        flex-grow: 1;
        height: 100%;
        width: 1290px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        @media(max-width: 1400px) {
            width: 1100px;

            @media(max-width: 1200px) {
                width: 665px;

                @media(max-width: 744px) {
                    width: 590px;
                    // width: 450px;
                    padding: 0 20px;

                    @media (max-width: 576px) {
                        width: 100%;

                    }
                }
            }
        }

        .dashboard-header {
            position: sticky;
            height: 76px;

            @media(max-width: 1200px) {

                @media(max-width: 744px) {
                    height: 66px;

                    @media (max-width: 576px) {
                        height: 46px;
                        position: relative;
                        display: none;

                    }
                }
            }

            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            h3 {
                height: 36px;
                font-size: 22px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0.01em;
                color: #272C35;
            }
        }

        .dashboard-body {
            animation: AnimationBottomToTop 1s ease-in-out forwards;
            flex-grow: 1;
            height: 100%;
            overflow: auto;
            width: 100%;
            padding: 20px 0 0 0;

            @media(max-width: 1200px) {
                padding: 0;

                @media(max-width: 744px) {
                    padding: 0;

                    @media (max-width: 576px) {}
                }
            }

            display: flex;
            flex-direction: column;


            h4 {
                font-size: 22px;
                font-weight: 600;
                line-height: 46px;
                letter-spacing: 0.01em;
                color: #272C35;
                display: none;

                @media(max-width: 1200px) {
                    @media(max-width: 744px) {
                        @media (max-width: 576px) {
                            display: block;
                        }
                    }
                }
            }

            .body-title {
                width: 100%;
                font-size: 22px;
                font-weight: 500;
                line-height: 34px;
                letter-spacing: 0.01em;
                color: #272C35;

            }

            .dashboard-body-container {
                width: 100%;
                flex-grow: 1;
                height: fit-content;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                gap: 20px;

                @media(max-width: 1400px) {
                    @media(max-width: 1200px) {
                        flex-direction: column;
                        // align-items: center;
                        gap: 15px;

                        @media(max-width: 744px) {

                            @media (max-width: 576px) {}
                        }
                    }
                }

                .dashboard-card-container {
                    flex-grow: 1;
                    width: 780px;
                    // min-height: 100%;
                    padding-bottom: 25px;

                    @media(max-width: 1400px) {
                        width: 660px;

                        @media(max-width: 1200px) {
                            width: 660px;

                            @media(max-width: 744px) {
                                width: 100%;

                                @media (max-width: 576px) {}
                            }
                        }
                    }

                    .setup-card-container {
                        z-index: 999;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 20px;

                        @media(max-width: 1400px) {
                            gap: 15px;

                            @media(max-width: 1200px) {
                                gap: 10px;

                                @media(max-width: 744px) {
                                    grid-template-columns: repeat(1, 1fr);
                                    gap: 20px;

                                    @media (max-width: 576px) {}
                                }
                            }
                        }


                        .custom-card {
                            width: 380px;
                            min-height: 192px;
                            // max-height: 215px;
                            padding: 25px;
                            border-radius: 10px;
                            background: #FFFFFF;
                            box-sizing: border-box;
                            border: 0.4px solid #AAB2C0;
                            box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.05);

                            @media(max-width: 1400px) {
                                width: 320px;
                                padding: 23px;
                                // height: 215px;

                                @media(max-width: 1200px) {
                                    @media(max-width: 744px) {
                                        width: 100%;

                                        @media (max-width: 576px) {
                                            @media (max-width: 380px) {}
                                        }
                                    }
                                }
                            }

                            .title-description {
                                display: flex;
                                flex-direction: column;
                                gap: 15px;

                                .title {
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.01em;
                                    color: #000000;
                                }


                                .special-description,
                                .description {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    letter-spacing: 0.01em;
                                    color: #4E5869;
                                }

                                .special-description {
                                    display: block;

                                    @media(max-width: 1400px) {
                                        display: none;

                                        @media(max-width: 744px) {
                                            display: block;
                                        }
                                    }
                                }

                                .description {
                                    display: none;

                                    @media(max-width: 1400px) {
                                        display: block;
                                        font-size: 13px;

                                        @media(max-width: 769px) {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .button-container {
                                margin-top: 25px;
                                display: flex;
                                justify-content: flex-end;

                                button {
                                    height: 34px;
                                    border-radius: 20px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0 20px;
                                    background: #1354EC;

                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;
                                    letter-spacing: 0.01em;

                                    color: #FFFFFF;
                                }
                            }
                        }
                    }

                    .participate-card-container {
                        margin: 40px 0;

                        .title {
                            margin-bottom: 20px;
                            font-size: 22px;
                            font-weight: 500;
                            line-height: 34px;
                            letter-spacing: 0.01em;
                            color: #272C35;
                        }

                        .card-container {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 20px;

                            @media(max-width: 1400px) {
                                gap: 15px;

                                @media(max-width: 1200px) {
                                    gap: 10px;

                                    @media(max-width: 744px) {
                                        grid-template-columns: repeat(1, 1fr);
                                        gap: 20px;

                                        @media (max-width: 576px) {}
                                    }
                                }
                            }

                            .custom-card {
                                width: 380px;
                                min-height: 190px;
                                overflow: hidden;
                                border-radius: 10px;
                                background: #FFFFFF;
                                box-sizing: border-box;
                                border: 0.4px solid #C7CDD6;
                                display: flex;

                                @media(max-width: 1400px) {
                                    width: 320px;

                                    @media(max-width: 1200px) {
                                        // height: inherit;
                                        // min-height: 190px;

                                        @media(max-width: 744px) {
                                            width: 100%;

                                            @media (max-width: 576px) {
                                                @media (max-width: 380px) {}
                                            }
                                        }
                                    }
                                }

                                .card-image {
                                    flex-grow: 1;
                                    width: 131px;
                                    height: 100%;
                                    object-fit: cover;
                                    // @media(max-width: 744px) {
                                    //     width: fit-content;

                                    //     img {
                                    //         height: 100%;
                                    //         width: auto;
                                    //     }

                                    //     @media (max-width: 576px) {
                                    //         @media (max-width: 380px) {}
                                    //     }
                                    // }

                                }

                                .card-body {
                                    flex-grow: 1;
                                    width: 100%;
                                    height: 100%;
                                    padding: 20px 14px;

                                    @media(max-width: 1400px) {
                                        padding: 24px 14px;

                                        @media(max-width: 1200px) {
                                            padding: 20px 14px;

                                            @media(max-width: 744px) {
                                                padding: 15px 10px;
                                            }
                                        }
                                    }

                                    .title-description {
                                        display: flex;
                                        flex-direction: column;
                                        gap: 10px;

                                        .header-title {
                                            font-size: 16px;
                                            font-weight: 600;
                                            line-height: 24px;
                                            letter-spacing: 0.01em;
                                            color: #000000;
                                        }

                                        .special-description,
                                        .description {
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 22px;
                                            letter-spacing: 0.01em;
                                            color: #4E5869;
                                        }

                                        .special-description {
                                            display: block;

                                            @media(max-width: 1400px) {
                                                display: none;

                                                @media(max-width: 744px) {
                                                    display: block;
                                                    font-size: 13px;
                                                }
                                            }
                                        }

                                        .description {
                                            display: none;

                                            @media(max-width: 1400px) {
                                                display: block;
                                                font-size: 13px;

                                                @media(max-width: 769px) {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }

                                    .button-container {
                                        margin-top: 20px;
                                        display: flex;
                                        justify-content: flex-end;

                                        button {
                                            height: 34px;
                                            border-radius: 20px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            background: #1354EC;
                                            padding: 0 20px;
                                            font-size: 14px;


                                            font-weight: 400;
                                            line-height: 14px;
                                            letter-spacing: 0.01em;

                                            color: #FFFFFF;

                                            @media(max-width: 1400px) {

                                                @media(max-width: 744px) {
                                                    // width: 80%;
                                                    padding: 0 12px;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .team-card-container {
                        margin: 40px 0 0 0;

                        .title {
                            margin-bottom: 20px;
                            font-size: 22px;
                            font-weight: 500;
                            line-height: 34px;
                            letter-spacing: 0.01em;
                            color: #272C35;
                        }

                        .card-container {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 20px;

                            @media(max-width: 1400px) {
                                gap: 15px;

                                @media(max-width: 1200px) {
                                    gap: 10px;

                                    @media(max-width: 744px) {
                                        grid-template-columns: repeat(1, 1fr);
                                        width: 100%;
                                        gap: 20px;

                                        @media (max-width: 576px) {}
                                    }
                                }
                            }

                            .custom-card {
                                width: 380px;
                                min-height: 101px;
                                border-radius: 10px;
                                background: #FFFFFF;
                                box-sizing: border-box;
                                border: 0.4px solid #AAB2C0;

                                box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.05);

                                padding: 20px 15px;
                                display: flex;
                                flex-direction: column;
                                gap: 15px;

                                @media(max-width: 1400px) {
                                    width: 320px;
                                    // height: 215px;

                                    @media(max-width: 1200px) {
                                        @media(max-width: 744px) {
                                            width: 100%;

                                            @media (max-width: 576px) {
                                                @media (max-width: 380px) {}
                                            }
                                        }
                                    }
                                }

                                .header-title {
                                    height: 24px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.01em;
                                    color: #000000;
                                    display: flex;
                                    align-items: center;
                                    gap: 13px;

                                    .title-icon {
                                        border-radius: 50%;
                                        width: 16px;
                                        height: 16px;
                                    }
                                }

                                .description {
                                    height: 22px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    letter-spacing: 0.01em;

                                    color: #000000;

                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                }
                            }
                        }
                    }

                }

                .dashboard-feature-container {
                    // flex-grow: 1;
                    // min-height: 100%;
                    max-height: 1250px;
                    overflow: auto;
                    width: 490px;
                    background: #F5F8FF;
                    box-sizing: border-box;
                    border-radius: 10px 10px 0 0;
                    border: 0.3px 0 0 solid #AAB2C0;
                    padding: 25px;

                    @media(max-width: 1400px) {
                        width: 418px;
                        padding: 24px;

                        @media(max-width: 1200px) {
                            max-height: fit-content;

                            @media(max-width: 744px) {
                                width: 100%;

                                @media (max-width: 576px) {
                                    padding-bottom: 140px;
                                }
                            }
                        }
                    }

                    .title {
                        margin-bottom: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;
                        letter-spacing: 0.01em;
                        color: #272C35;
                    }

                    .feature-container {
                        display: flex;
                        flex-direction: column;
                        gap: 30px;

                        .feature-body {
                            // width: 440px;
                            width: 100%;
                            min-height: 133px;
                            border-radius: 10px;
                            /* Smart layout */
                            display: flex;
                            flex-direction: column;
                            padding: 25px;
                            gap: 15px;
                            background: #FFFFFF;

                            box-sizing: border-box;
                            border: 0.4px solid #C7CDD6;

                            .feature-title {
                                // display: flex;
                                // align-items: center;
                                // height: 24px;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                                letter-spacing: 0.01em;
                                color: #000000;
                            }

                            .feature-description {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 22px;
                                letter-spacing: 0.01em;

                                color: #4E5869;
                            }

                        }
                    }
                }
            }
        }
    }
}

.loader-dashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    position: relative;

    .loader-dashboard-container {
        flex-grow: 1;
        height: 100%;
        width: 1290px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        @media(max-width: 1400px) {
            width: 1100px;

            @media(max-width: 1200px) {
                width: 665px;

                @media(max-width: 744px) {
                    width: 590px;
                    // width: 450px;
                    padding: 0 20px;

                    @media (max-width: 576px) {
                        width: 100%;

                    }
                }
            }
        }

        .loader-dashboard-header {
            position: sticky;
            height: 76px;

            @media(max-width: 1200px) {

                @media(max-width: 744px) {
                    height: 66px;

                    @media (max-width: 576px) {
                        height: 46px;
                        position: relative;
                        display: none;

                    }
                }
            }

            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .loader-dashboard-body {
            flex-grow: 1;
            height: 100%;
            overflow: auto;
            width: 100%;
            padding: 20px 0 0 0;

            @media(max-width: 1200px) {
                padding: 0;

                @media(max-width: 744px) {
                    padding: 0;

                    @media (max-width: 576px) {}
                }
            }

            display: flex;
            flex-direction: column;


            .loader-body-title {
                width: 100%;
            }

            .loader-dashboard-body-container {
                width: 100%;
                flex-grow: 1;
                height: fit-content;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                gap: 20px;

                @media(max-width: 1400px) {
                    @media(max-width: 1200px) {
                        flex-direction: column;
                        // align-items: center;
                        gap: 15px;

                        @media(max-width: 744px) {

                            @media (max-width: 576px) {}
                        }
                    }
                }

                .loader-dashboard-card-container {
                    flex-grow: 1;
                    width: 780px;
                    // min-height: 100%;
                    padding-bottom: 25px;

                    @media(max-width: 1400px) {
                        width: 660px;

                        @media(max-width: 1200px) {
                            width: 660px;

                            @media(max-width: 744px) {
                                width: 100%;

                                @media (max-width: 576px) {}
                            }
                        }
                    }

                    .loader-card-container {
                        z-index: 999;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 20px;

                        @media(max-width: 1400px) {
                            gap: 15px;

                            @media(max-width: 1200px) {
                                gap: 10px;

                                @media(max-width: 744px) {
                                    grid-template-columns: repeat(1, 1fr);
                                    gap: 20px;

                                    @media (max-width: 576px) {}
                                }
                            }
                        }


                        .custom-card {
                            width: 380px;
                            min-height: 192px;
                            // max-height: 215px;
                            padding: 25px;
                            border-radius: 10px;
                            background: #FFFFFF;
                            box-sizing: border-box;
                            border: 0.4px solid #AAB2C0;
                            box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.05);

                            @media(max-width: 1400px) {
                                width: 320px;
                                padding: 23px;
                                // height: 215px;

                                @media(max-width: 1200px) {
                                    @media(max-width: 744px) {
                                        width: 100%;

                                        @media (max-width: 576px) {
                                            @media (max-width: 380px) {}
                                        }
                                    }
                                }
                            }

                            .title-description {
                                display: flex;
                                flex-direction: column;
                                gap: 15px;

                                .title {
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    letter-spacing: 0.01em;
                                    color: #000000;
                                }


                                .special-description,
                                .description {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    letter-spacing: 0.01em;
                                    color: #4E5869;
                                }

                                .special-description {
                                    display: block;

                                    @media(max-width: 1400px) {
                                        display: none;

                                        @media(max-width: 744px) {
                                            display: block;
                                        }
                                    }
                                }

                                .description {
                                    display: none;

                                    @media(max-width: 1400px) {
                                        display: block;
                                        font-size: 13px;

                                        @media(max-width: 769px) {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .button-container {
                                margin-top: 25px;
                                display: flex;
                                justify-content: flex-end;

                                button {
                                    height: 34px;
                                    border-radius: 20px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0 20px;
                                    background: #1354EC;

                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;
                                    letter-spacing: 0.01em;

                                    color: #FFFFFF;
                                }
                            }
                        }
                    }

                }

                .loader-dashboard-feature-container {
                    // flex-grow: 1;
                    // min-height: 100%;
                    max-height: 1250px;
                    overflow: auto;
                    width: 490px;
                    background: #F5F8FF;
                    box-sizing: border-box;
                    border-radius: 10px 10px 0 0;
                    border: 0.3px 0 0 solid #AAB2C0;
                    padding: 25px;

                    @media(max-width: 1400px) {
                        width: 418px;
                        padding: 24px;

                        @media(max-width: 1200px) {
                            max-height: fit-content;

                            @media(max-width: 744px) {
                                width: 100%;

                                @media (max-width: 576px) {
                                    padding-bottom: 140px;
                                }
                            }
                        }
                    }

                    .title {
                        margin-bottom: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;
                        letter-spacing: 0.01em;
                        color: #272C35;
                    }

                    .loader-feature-container {
                        display: flex;
                        flex-direction: column;
                        gap: 30px;
                    }
                }
            }
        }
    }
}
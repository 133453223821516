@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.signup-login-container-mobile {
    display: flex;

    @media(min-width: 764px) {
        display: none !important;
    }

    font-family: 'Inter';
    font-style: normal;
    height: 100vh;
    background-image: url('../assets/img/signup-login-mobile-bg.svg');
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 22vh;
    padding: 1.5em 1.5em;

    .signup-login-wrapper {
        overflow: hidden;
        height: 70vh;
        width: 100%;
        margin: auto;
        background: #FFFFFF;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 0 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .signup-login-container {
            height: fit-content;
            margin: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .logo {
            // height: 6vh;
            height: auto;
            width: auto;
            margin: 0 auto;
        }

        .signup-login-btn-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            button {
                width: 38%;
                height: 5vh;
                background: #d8d6d6;
                mix-blend-mode: normal;
                border-radius: 100px;
                font-weight: 500;
                font-size: 0.75rem;
                border: none;
                outline: none;
                margin: 0 -4%;
                transition: all 0.5s ease-in-out;

                &:hover {
                    background: #175CD3;
                    color: #FFFFFF;
                    z-index: 10;
                }
            }

            .signup-login-btn-active {
                background: #175CD3;
                color: #FFFFFF;
                z-index: 10;
            }
        }

        .login-flex {
            // display: flex;
            // align-items: center;
            // justify-content: center;

            height: 100%;
            overflow: hidden;
            padding: 1% 4% 0 4%;
            width: 100%;

            .login-wrapper {
                width: 100%;
                // text-align: center;

                .custom_button {
                    &:hover {
                        background: #2582F5 0% 0% no-repeat padding-box;
                    }
                }

                .button-wrapper,
                .input-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 9vh;
                    overflow: hidden;

                    .btn-content {
                        margin: 0 2%;
                        font-size: 1rem;

                        @media(max-width: 769px) {
                            font-size: 0.6875rem;
                        }

                        font-weight: 500;
                    }



                    .social-btn,
                    .sm-input,
                    .user-input-signup-login {
                        background-color: #ffffff;
                        width: 100% !important;

                        // height: 6vh;
                        overflow: hidden;
                        mix-blend-mode: normal;
                        // opacity: 0.1;
                        border: 1px solid #1A1A1A;
                        // border: 0.6px solid #999;
                        transition: all 0.5s ease-in-out;
                        outline: none;

                        &:focus,
                        &:hover,
                        &:active {
                            border: 1px solid #1354EC;
                        }

                        border-radius: 100px;
                        padding: 5% 5%;
                        margin: 2% auto !important;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 2%;

                        img {
                            width: 3vw;
                            width: auto;
                            height: auto;
                        }
                    }

                    .sm-input {
                        padding: 5% 8%;

                        @media(max-width: 769px) {
                            font-size: 0.6875rem;
                        }
                    }

                    .user-input-signup-login {
                        // border-radius: 4px;
                        // border: none;
                        background: #1354EC;
                        text-align: center !important;
                        display: flex;
                        justify-content: center;
                        color: #fff;

                    }
                }

                .login-footer {
                    .signup-login-type {
                        font-weight: 500;
                        font-size: 0.765rem;
                        line-height: 0.987rem;
                        text-align: center;

                        color: #17173A;
                    }
                }

                .terms-conditions {
                    font-family: 'Inter';
                    font-style: normal;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 100%;
                    text-align: center;
                    font-size: 0.765rem;
                }
            }
        }

    }
}

.login-container-lg {
    display: block;
    font-family: 'Inter';
    font-style: normal;

    @media(max-width: 764px) {
        display: none;
    }


    .no-gutter {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .signup-img {
        width: 65%;
        height: auto;
    }

    .welcome {
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 0;
        color: #17173A;
        margin-bottom: 16%;
    }

    .log-in-des {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.8rem;
        /* or 132% */


        color: #17173A;
    }

    .side-wrapper {
        background: transparent linear-gradient(180deg, #DFECF17F 0%, #F6F8F9 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        // background: linear-gradient(218.16deg, #FFCA00 1.1%, #E3A00D 74.7%);
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        // padding: 0 1%;

        .login-wrapper {
            // width: 55%;
            width: 405px;
            margin: auto;
            // border: 2px solid red;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .custom_button {
                &:hover {
                    background: #2582F5 0% 0% no-repeat padding-box;
                }
            }

            .clapup-logo {
                // width: 3vw;
                width: auto;
                height: auto;
            }

            .button-wrapper,
            .input-wrapper {
                // width: 100%;
                width: 320px;
                // font-family: 'Roboto', sans-serif;
                // font-style: normal;
                display: flex;
                flex-direction: column;


                .social-btn,
                input,
                .user-input-signup-login {
                    background-color: white;
                    color: rgb(58, 58, 78);
                    border: 0.6px solid #9999;
                    transition: all 0.5s ease-in-out;
                    outline: none;

                    &:focus,
                    &:hover,
                    &:active {
                        border: 0.6px solid #1354EC;
                    }

                    border-radius: 4px;
                    min-height: 44px;
                    max-height: 44px;
                    overflow: hidden;
                    width: 100%;
                    margin: 10px auto;
                    // padding: 5% 8%;
                    padding: 13.5px 15px 13.5px 15px;
                    // padding: 5% 3% 5% 8%;
                    // cursor: pointer;
                    // opacity: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;

                    img {
                        flex-grow: 1;
                        width: 17px;
                        // width: auto;
                        height: auto;
                    }

                    .btn-text {
                        text-align: start;
                        flex-grow: 1;
                        width: 100%;
                        font-size: 14px;
                        color: #17173A;

                        @media(max-width: 1200px) {
                            // font-size: 1.15rem;
                        }
                    }

                }

                .user-input-signup-login {
                    background: #1354EC;
                    text-align: center !important;
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        background: #0F43BD 0% 0% no-repeat padding-box;
                    }
                }
            }

            .login-footer {
                width: 100%;
                margin: 10px auto;
                color: #101828;
                font-size: 14px;
                line-height: 22px;

                p {

                    @media(max-width: 1200px) {
                        // font-size: 0.8765rem;

                    }
                }

                .terms-conditions {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                }

                @media(max-width: 1200px) {
                    // font-size: 0.8765rem;

                }
            }
        }
    }
}
.menu-left-side {
    flex-shrink: 1;
    min-width: 190px;
    max-width: 190px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    border-right: 0.7px solid #C7CDD6;

    .main-title {
        position: sticky;
        top: 0;
        background: #FFFFFF;
        width: 190px;
        text-align: left;
        padding-top: 5px;
        padding-bottom: 15px;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.01em;

        color: #272C35;
    }

    .tab-container {
        text-align: left;
        margin-top: 10px;

        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-right: 10px;

        .tab-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.01em;

            color: #272C35;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                cursor: pointer;
            }
        }

        .tab-body {
            width: 100%;
            display: flex;
            flex-direction: column;

            // gap: 2px;

            .body-container,
            .body-container-active {
                cursor: pointer;
                overflow: hidden;
                padding: 10px;
                border-radius: 3px;
                height: 36px;
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;

                .bg-image {
                    height: auto;
                    width: auto;
                }

                img {
                    height: 16px;
                    width: 16px;
                }

                .title {
                    white-space: nowrap;
                    /* Prevent line breaks */
                    text-overflow: ellipsis;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.01em;

                    color: #272C35;
                }

                &:hover {
                    background: #E0EAFF;
                }

                transition: all 0.3s ease-in-out;
            }


            .body-container-active {
                background: #E0EAFF;
            }
        }
    }

    // &:hover {
    //     &::-webkit-scrollbar-thumb {
    //         background: #AAB2C0 !important;
    //         // height: 10px !important;
    //         border-radius: 10px;
    //         cursor: pointer;
    //         min-height: 30px;
    //         /* Set your desired fixed height */
    //         max-height: 30px;
    //         /* Ensure it doesn't exceed the desired fixed height */
    //         background-clip: padding-box;
    //         /* Adjusts the background to stay within the thumb bounds */
    //     }

    // }

    // &::-webkit-scrollbar {
    //     width: 6px;
    // }

    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 5px transparent;
    //     background: transparent !important;
    // }
}


// .tab-container-inbox-message-email {
//     margin-top: 0;

//     // .tab-body {

//     //     // gap: 5px;
//     //     .body-container {
//     //         height: 36px;
//     //         transition: all 0.3s ease-in-out;

//     //         &:hover {
//     //             background: #E0EAFF;
//     //         }
//     //     }
//     // }
// }
body {
  font-family: 'Inter';
  /* font-family: 'Inter'; */

}

.scroll-container {
  &::-webkit-scrollbar-thumb {
    background: #A3A3A3 !important;
    // height: 10px !important;
    border-radius: 10px;
    cursor: pointer;
    min-height: 30px;
    /* Set your desired fixed height */
    max-height: 30px;
    /* Ensure it doesn't exceed the desired fixed height */
    background-clip: padding-box;
    /* Adjusts the background to stay within the thumb bounds */
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    background-color: #fff !important;
    margin: 0 !important;
    // background-color: transparent ;
    // border-radius: 10px;
  }
}

.dashboard-loader-section {
  width: 100%;

  background: #FFFFFF;
  box-shadow: 0px 0px 25px 2px rgba(0, 0, 0, 0.05);
  border: 0.4px solid #AAB2C0;
  border-radius: 10px;
  // margin: 6px 0;
  padding: 22px 25px;
  overflow: hidden;
  text-align: left;

  .dashboard-loader-container {
    width: 100%;

    .dashboard-footer-loader {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.loader-section {
  width: 288px;
  background-color: #fff;
  border: .5px solid #d3e0ff;
  border-radius: 5px;
  margin: 6px 0;
  padding: 10px;
  overflow: hidden;
  text-align: left;

  .loaders-container {
    width: 100%;
    display: flex;
    gap: 10px;

    .loaders-body {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .line-loaders {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

.dropdown-container {
  position: relative;
  width: fit-content;
  margin-top: 5px;
  border-radius: 5px;
  background: var(--White, #FFFFFF);
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.10);
  border: 0.3px solid var(--Normal, #D6DAE1);
  border-radius: 10px;
  padding: 10px 0;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      text-align: justify;
      color: #101828;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-weight: normal;
      font-size: 14px;

      @media(max-width: 1400px) {

        @media(max-width: 769px) {}
      }

      &:hover {
        background: var(--Tertiary, #EEF4FF);
      }
    }
  }

  .dropdown-item,
  .child-dropdown-item,
  label {
    width: 195px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    text-align: justify;
    color: #101828;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: normal;
    font-size: 14px;

    @media(max-width: 1400px) {

      @media(max-width: 769px) {}
    }

    &:hover,
    &:focus {
      background: var(--Tertiary, #EEF4FF);
    }
  }

  .dropdown-item,
  .child-dropdown-item {
    img {
      width: 16px;
      height: auto;
    }

    .chevron-icon {
      width: auto;
      height: auto;
    }
  }

  .dropdown-item-active {
    background: var(--Tertiary, #EEF4FF);
  }

  .child-dropdown-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;

    .title-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.child-dropdown-container {
  position: absolute;
  top: 0;
  left: -210px;
  width: fit-content;
  border-radius: 5px;
  background: var(--White, #FFFFFF);
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.10);
  border: 0.3px solid var(--Normal, #D6DAE1);
  border-radius: 10px;
  padding: 10px 0;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      text-align: justify;
      color: #101828;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-weight: normal;
      font-size: 14px;

      @media(max-width: 1400px) {

        @media(max-width: 769px) {}
      }

      &:hover {
        background: var(--Tertiary, #EEF4FF);
      }
    }
  }

  .dropdown-item,
  .child-dropdown-item,
  label {
    width: 195px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    text-align: justify;
    color: #101828;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: normal;
    font-size: 14px;

    @media(max-width: 1400px) {

      @media(max-width: 769px) {}
    }

    &:hover,
    &:focus {
      background: var(--Tertiary, #EEF4FF);
    }
  }

  .dropdown-item-active {
    background: var(--Tertiary, #EEF4FF);
  }

  .child-dropdown-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;

    .title-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.dropdown-add-button {
  height: 42px;
  padding: 0 20px;
  // width: 156px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #FFFFFF;
  background: #1354EC;
  transition: all 0.3s ease-in-out;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
}

.attachment-menu-container {
  padding: 20px 10px 20px 20px;
  background: #FFFFFF;
  border-radius: 6px;
  box-sizing: border-box;
  border: 0.6px solid #D6DAE1;
  margin-bottom: 10px;

  .attachment-items-container {
    width: 256px;
    max-height: 140px;
    padding-right: 5px;
    margin: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .attachment-item {
      width: 100%;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      padding: 4px 8px;

      background: #EEF4FF;

      .attachment-key {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        font-feature-settings: "kern" on;
        color: #3D3D3D;
      }

      img {
        cursor: pointer;
      }
    }
  }
}

.message-bot-menu-container {
  width: 210px;
  border-radius: 5px;
  background: #FFFFFF;
  border: 0.3px solid #AAB2C0;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0em;
  color: #272C35;

  .search-container {
    width: 100%;
    // padding: 10px 0;
    margin-bottom: 10px;
    position: relative;

    input {
      height: 32px;
      align-items: center;
      padding: 6px 15px;
      border-radius: 4px;
      border: 1px solid #C7CDD6;
      outline: none;
    }

    // img {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    // }
  }


  .menu-items {
    max-height: 140px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .item {
      margin-right: 6px;
      cursor: pointer;
      height: 32px;
      border-radius: 4px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #E0EAFF;
      }

      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
  height: 5px;
  border: none;
  outline: none;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  height: 0;
}

.App {
  text-align: center;
}

.scroll-content {
  /* padding: 17px 0 17px 17px; */
  /* width: 300px;
  height: 200px; */
  overflow-y: scroll;
  /* mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px); */
  mask-size: 100% 20000px;
  mask-position: left bottom;
  /* -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px); */
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s;


  &::-webkit-scrollbar-thumb {
    height: 100px !important;
    transition: all 0.5s ease-in;
  }


  &:hover {
    &::-webkit-scrollbar-thumb {
      // -webkit-transition: background-color 0.5s ease-in-out !important;
      // -moz-transition: background-color 0.5s ease-in-out !important;
      // -o-transition: background-color 0.5s ease-in-out !important;
      // transition: background-color 0.5s ease-in-out !important;
      border-radius: 5px;
      opacity: 0.6;
      background: var(--icon-general, #ABB9CC);
    }

  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    cursor: pointer;
    // height: 5px;
    box-shadow: inset 0 0 5px #fff;
    background-color: #fff;
    margin: 0;
  }
}
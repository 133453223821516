@keyframes moveRightToLeft {
    from {
        transform: translateX(100%); // Start outside the screen (right)
        // opacity: 0; // Initially hidden
    }

    to {
        transform: translateX(0); // Fully visible
        // opacity: 1;
    }
}

@keyframes moveLeftToRight {
    from {
        transform: translateX(0); // Start at visible
        // opacity: 1;
    }

    to {
        transform: translateX(100%); // Slide out to the right
        // opacity: 0;
    }
}

.closing-time-drawer,
.drawer-wrap {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-end;

    // Main drawer container
    .drawer-container {
        background: #FFFFFF;
        height: 100%;
        min-width: 380px;
        transform: translateX(100%); // Initially off-screen
        animation: moveRightToLeft 0.5s ease-in-out forwards;
    }
}

// When closing the drawer
.closing-time-drawer {
    .drawer-container {
        animation: moveLeftToRight 0.5s ease-in-out forwards;
    }
}
.feeds-card {
    border: 0.5px solid #D3E0FF;
    border-radius: 5px;
    background-color: #fff;
    width: 288px;
    overflow: hidden;
    margin: 6px 0;
    text-align: left;

    .feed-card-header {
        display: flex;
        // align-items: center;
        padding: 0 10px;
        padding-top: 10px;
        position: relative;

        .profile-pic {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            margin-right: 10px;
        }

        .user-details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 7px;

            .username-stars-rating-container {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .username {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0.01em;

                    color: #17173A;
                }

                .stars-rating-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .stars {
                        display: flex;
                        gap: 3px;

                        img {
                            height: 11px;
                            width: 11px;
                        }
                    }

                    .rating {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 12px;
                        letter-spacing: 0.01em;

                        color: #272C35;
                    }
                }

            }

            .tag-text,
            .tag-date {
                font-style: italic;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0.01em;
                color: #667085;

                .recommend-title {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }

            .tag-date {
                font-style: normal;
            }

        }

        .provider-logo {
            height: 16px;
            width: auto;
        }

    }

    .feed-card-body {
        padding: 0 10px;
        margin: 12px 0 15px 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0.01em;

        color: #4E5869;
    }

    .feed-card-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        /* Padding for the footer */
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.01em;

        color: #4E5869;
        // border-top: 0.5px solid #AAB2C0;
        border-top: 0.5px solid #D3E0FF;
        text-transform: capitalize;

        .like-container {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .date {
            flex-grow: 1;
            text-align: right;
        }


        .prev-next-add-wall-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .like-container {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .add-to-wall-btn {
                padding: 5px 8px;
                background: #F5F8FF;
                border-radius: 13px;
                font-weight: 500;
                letter-spacing: 0.01em;
                color: #17173A;
            }
        }

        .add-to-wall-btn-hover {
            &:hover {
                background: #F5F8FF;
                // border: 1px solid #18e4e4;
                border-radius: 13px;
            }
        }

        .add-to-wall-btn {
            padding: 5px 8px;
            // width: 5.8vw;

            // height: 2.7vh;
            background: #f8fbff;
            border-radius: 13px;
            font-weight: 500;
            font-style: normal;
            color: #17173A;
            transition: all 0.3s ease-in-out;
            border: 1px solid #1570ef;

            // &:hover {
            //     background: #EEF4FF;
            //     /* Button / Active Button Outline */

            //     border: 1px solid #175CD3;
            //     border-radius: 13px;
            // }
        }

        .footer-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-body-emoji {
                display: flex;
                align-items: center;
                gap: 12px;
                font-size: 12px;
                font-weight: normal;
                line-height: 12px;
                letter-spacing: 0.01em;

                color: #272C35;
            }

            .right-side-content {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .treads {
        border-top: 0.5px solid #D3E0FF;

        .tread-container {
            cursor: pointer;
            border-bottom: 0.5px solid #D3E0FF;
        }
    }
}
.form-dialog-container {
    width: 40% !important;

    @media(max-width: 1025px) {
        width: 60% !important;

        @media(max-width: 769px) {
            width: 90% !important;
        }
    }

}

.form-container {
    font-family: 'Inter';
    font-style: normal;

    .form-header {
        font-weight: 400;
        color: #17173A;

        h2 {
            font-size: 1.2rem;
            line-height: 1.2rem;
            letter-spacing: 0.01em;
        }
    }

    .form-body {
        background: #D8E3FF;
        padding: 5% 2%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        margin: 5% auto;
        border-left: 5px solid #1570EF;
        width: 100%;
        display: flex;
        flex-direction: column;

        .form-group {
            width: 100%;

            &:last-child {
                margin-top: 5%;
            }

            input {
                width: 100% !important;
                padding-bottom: 1%;
                border: none;
                border-radius: 0;
                outline: none;
                background: #D8E3FF;
                transition: all 0.5s ease-in-out;
                font-size: 0.875rem;
                line-height: 0.875rem;
                letter-spacing: 0.01em;

                &:focus {
                    border-bottom: 0.1px solid #98A2B3;
                }

                &::placeholder {
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    letter-spacing: 0.01em;
                    color: #98A2B3;
                    background: transparent;
                }

            }
        }

    }

    .form-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        button {
            margin: 2% 0;
            padding: 3% 3.5%;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 0.875rem;
            letter-spacing: 0.01em;
            background: #E4E7EC;
            border-radius: 4px;
            border: none;
            outline: none;

            &:first-child {
                color: #17173A;
            }

            &:last-child {
                color: #BC4C2E !important;
            }
        }
    }
}